import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stepCount: [{ step: 1 }, { step: 2 }, { step: 3 }, { step: 4 }],
  activeStep: 1,
  timer: true,
  expired: false,
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    updateActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    incActiveStep: (state, action) => {
      state.activeStep = state.activeStep + 1;
    },
    decActiveStep: (state) => {
      state.activeStep = state.activeStep - 1;
    },
    toggleTimer: (state, action) => {
      state.timer = action.payload;
    },
    toggleExpiry: (state, action) => {
      state.expired = action.payload;
    },
  },
});

export default stepperSlice.reducer;
export const {
  incActiveStep,
  decActiveStep,
  updateActiveStep,
  toggleTimer,
  toggleExpiry,
} = stepperSlice.actions;
