import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ViewCardTestPreview from "../../../../API/ViewCardTestPreview";
import { GetQuestionsPreviewbyId } from "../../../../API/GetQuestionsPreviewbyId";
import {
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { BorderColorSharp, Delete, SwapHorizSharp } from "@material-ui/icons";
import UpdatebyQuestionId from "../../../../API/UpdatebyQuestionId";
import { toast } from "react-toastify";
import DeleteQuestionByID from "../../../../API/DeleteQuestionByID";

const TestPreviewModal = ({
  TestPreviewActive,
  handleTestPreviewModal,
  SelectedCardData,
  SelectedQsnData,
}) => {
  const [PreviewQsn, setPreviewQsn] = useState([]);
  const [CurrQuestion, setCurrQuestion] = useState({
    count: 0,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [EditState, setEditState] = useState(null);
  const [toggleComplexity, setToggleComplexity] = useState(1);
  const [confirmDxb, setConfirmDxb] = useState(false);
  const [correctIndex, setCorrectIndex] = useState(0);
  const [questionError, setQuestionError] = useState(false);
  const [optionErrors, setOptionErrors] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [correctAnswerError, setCorrectAnswerError] = useState(false);

  const handleToggleComplexity = () => {
    let count = toggleComplexity + 1;
    if (count === 4) {
      setToggleComplexity(1);
      setEditState((prevState) => ({
        ...prevState,
        complexityType: "Easy",
      }));
    } else {
      setToggleComplexity(count);
      setEditState((prevState) => ({
        ...prevState,
        complexityType: count === 2 ? "Medium" : "Hard",
      }));
    }
  };

  let ConvertedOptions =
    PreviewQsn?.length > 0
      ? PreviewQsn[0].options.map((option) => {
          return {
            optionId: option.id,
            optionContent: option.optionContent,
          };
        })
      : [
          {
            optionId: 0,
            optionContent: "string",
          },
        ];
  useEffect(() => {
    if (TestPreviewActive.viewMode) {
      ViewCardTestPreview(SelectedCardData)
        .then((response) => setPreviewQsn(response.data))
        .catch((err) => console.log(err));
    }

    if (TestPreviewActive.editMode) {
      GetQuestionsPreviewbyId(SelectedQsnData?.id)
        .then((response) => setPreviewQsn([response.data]))
        .catch((err) => console.log(err));
    }
  }, [TestPreviewActive, SelectedCardData]); // eslint-disable-line

  useEffect(() => {
    if (isEditMode) {
      setToggleComplexity(PreviewQsn[0]?.complexityId);
      if (EditState === null) {
        setEditState({
          questionId: PreviewQsn[0].id,
          userEmail: "string",
          question: PreviewQsn[0].question,
          complexityType:
            PreviewQsn[0].complexityId === 1
              ? "Easy"
              : PreviewQsn[0].complexityId === 2
              ? "Medium"
              : PreviewQsn[0].complexityId === 3
              ? "Hard"
              : null,
          questionType: "",

          remark: "string",
          option: ConvertedOptions,
          correctAnswer: PreviewQsn[CurrQuestion?.count]?.correctAnswer,
        });
      }
    }
  }, [isEditMode]); // eslint-disable-line

  useEffect(() => {
    if (!isEditMode) {
      setToggleComplexity(PreviewQsn[0]?.complexityId);
    }
  }, [PreviewQsn]); // eslint-disable-line
  // PreviewQsn[0].questionTypeId === 1
  //               ? "MCQ"
  //               : PreviewQsn[0].questionTypeId === 2
  //               ? "Code"
  //               : PreviewQsn[0].questionTypeId === 3
  //               ? "Advance(MCQ&Code)"
  //               : PreviewQsn[0].questionTypeId === 4
  //               ? "MCQ"
  //               : PreviewQsn[0].questionTypeId === 5
  //               ? "Contextual"
  //               : PreviewQsn[0].questionTypeId === 6
  //               ? "Advance(MCQ&Contextual)"
  //               : null,

  const handleSaveQuestion = () => {
    // Perform validation checks
    if (!EditState?.question) {
      setQuestionError(true);
    }

    // Check for duplicate option content
    const optionContentSet = new Set();
    const newOptionErrors = EditState?.option.map((option) => {
      const trimmedContent = option?.optionContent.trim();
      if (trimmedContent === "") {
        return true; // Empty content is considered an error
      } else if (optionContentSet.has(trimmedContent)) {
        return true; // Duplicate content is considered an error
      } else {
        optionContentSet.add(trimmedContent);
        return false; // No error
      }
    });
    setOptionErrors(newOptionErrors);

    if (EditState?.correctAnswer === "" || EditState?.correctAnswer === null) {
      setCorrectAnswerError(true);
    }

    // Check if any errors exist
    if (
      questionError ||
      newOptionErrors.some((error) => error) ||
      correctAnswerError
    ) {
      // Show an error message or handle the validation error as needed
      console.error("Validation error: All fields must be filled");
      return;
    }
    UpdatebyQuestionId(EditState)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Question Modified Successfully");
        }
      })
      .catch((err) => {
        toast.error("Uh-Oh, Try Again!");
      });
  };

  const handleEditChange = (property, value) => {
    setEditState((prevState) => ({
      ...prevState,
      [property]: value,
    }));
    if (value === "") {
      setQuestionError(true);
    } else {
      setQuestionError(false);
    }
  };
  const handleCorrectAnswerChange = (index, value) => {
    setCorrectIndex(index);
    setEditState((prevState) => ({
      ...prevState,
      correctAnswer: value,
    }));
    if (value === "") {
      setCorrectAnswerError(true);
    } else {
      setCorrectAnswerError(false);
    }
  };
  const [usedOptions, setUsedOptions] = useState([]);

  // Function to generate a unique key for each option
  const generateOptionKey = (option) => {
    return option?.optionContent + option?.optionId;
  };

  // Function to handle changes in option content
  const handleOptionEditChange = (property, value, index) => {
    setEditState((prevState) => {
      const updatedOptions = [...prevState.option];
      updatedOptions[index] = {
        ...updatedOptions[index],
        [property]: value,
      };

      return {
        ...prevState,
        option: updatedOptions,
      };
    });

    if (correctIndex === index) {
      handleCorrectAnswerChange(index, value);
    }

    if (value === "") {
      setOptionErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = true;
        return newErrors;
      });
    } else {
      const optionKey = generateOptionKey({
        optionContent: value,
        optionId: index, // Assuming each option has a unique ID
      });

      if (!usedOptions.includes(optionKey)) {
        // If the option is not already used, update the state and clear the error
        setOptionErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[index] = false;
          return newErrors;
        });

        setUsedOptions((prevOptions) => [...prevOptions, optionKey]);
      } else {
        // If the option is already used, show an error
        setOptionErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[index] = true;
          return newErrors;
        });
      }
    }
  };

  const handleIsEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleNextQsn = () => {
    if (PreviewQsn?.length >= CurrQuestion.count) {
      setCurrQuestion({
        count: CurrQuestion.count + 1,
      });
    }
  };
  const handlePrevQsn = () => {
    if (PreviewQsn?.length - CurrQuestion.count !== 0) {
      setCurrQuestion({
        count: CurrQuestion.count - 1,
      });
    }
  };

  const handleDeletebyQsnId = () => {
    //  SelectedCardData
    DeleteQuestionByID(SelectedQsnData?.id)
      .then((response) => {
        setConfirmDxb(false);
        setEditState(null);
        setIsEditMode(false);
        setCurrQuestion({
          count: 0,
        });
        setToggleComplexity(1);
        setPreviewQsn([]);
        handleTestPreviewModal("closeMode");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Modal
        open={TestPreviewActive.viewMode || TestPreviewActive.editMode}
        onClose={() => {
          setEditState(null);
          setIsEditMode(false);
          setCurrQuestion({
            count: 0,
          });
          setToggleComplexity(1);
          setPreviewQsn([]);
          handleTestPreviewModal("closeMode");
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              bgcolor: "#F7F7F7",
              color: "#908DB8",
              width: "100%",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            ></Toolbar>
          </AppBar>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              bgcolor: "#EBECF1",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                height: "65%",
                width: "70%",
                alignSelf: "center",
                marginTop: "60px",
                fontSize: "10px",
                borderRadius: "15px",
                boxShadow: "5px 4px 10px 1px #00000040",
              }}
            >
              <CardContent
                sx={{
                  height: "100%",
                  width: "100%",
                  px: 8,
                  py: 3,
                }}
              >
                <Box
                  component={Stack}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, fontSize: "18px", pt: 2 }}
                  >
                    Question{" "}
                    {TestPreviewActive.viewMode
                      ? CurrQuestion.count + 1
                      : SelectedQsnData?.index}
                  </Typography>
                  {/* (
                <IconButton onClick={handleIsEditMode}>
                  <AddCircleOutlineOutlined />
                </IconButton>
                ) : */}
                  {TestPreviewActive.editMode && !isEditMode && (
                    <IconButton onClick={handleIsEditMode}>
                      <BorderColorSharp />
                    </IconButton>
                  )}
                </Box>
                <Stack direction="row" spacing={10} sx={{ marginTop: "1%" }}>
                  <Box sx={{ flex: "50%" }}>
                    {/* <Typography>{questions[currQuestion]?.question}</Typography> */}
                    {isEditMode ? (
                      <TextField
                        id="outlined-multiline-flexible"
                        variant="outlined"
                        multiline
                        fullWidth
                        minRows={4}
                        maxRows={10}
                        value={EditState?.question}
                        error={questionError}
                        helperText={questionError ? "Enter a question" : ""}
                        onChange={(e) =>
                          handleEditChange("question", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 300, fontSize: "16px", pt: 5 }}
                      >
                        {PreviewQsn[CurrQuestion?.count]?.question}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ flex: "50%" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 300,
                        textTransform: "uppercase",
                        fontSize: "16px",
                        pb: 1,
                      }}
                    >
                      Select only one
                    </Typography>
                    <Stack>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                      >
                        {isEditMode
                          ? EditState?.option?.map((option, index) => {
                              return (
                                <Box sx={{ mb: "6%", width: "100%" }}>
                                  <TextField
                                    size="small"
                                    sx={{
                                      mb: "16%",
                                      border: "2px solid lightgrey",
                                    }}
                                    fullWidth
                                    error={optionErrors[index]}
                                    onChange={(e) =>
                                      handleOptionEditChange(
                                        "optionContent",
                                        e.target.value,
                                        index
                                      )
                                    }
                                    value={option?.optionContent}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Radio
                                            color={
                                              optionErrors[index]
                                                ? "error"
                                                : "success"
                                            }
                                            checked={
                                              option?.optionContent === ""
                                                ? false
                                                : option?.optionContent ===
                                                  EditState.correctAnswer
                                            }
                                            onChange={(e) =>
                                              handleCorrectAnswerChange(
                                                index,
                                                option?.optionContent
                                              )
                                            }
                                            sx={{ ml: "-8.5px" }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="outlined"
                                  />
                                </Box>
                              );
                            })
                          : PreviewQsn[CurrQuestion?.count]?.options?.map(
                              (el) => {
                                return (
                                  <Box
                                    sx={{
                                      border: "2px solid lightgrey",
                                      width: "100%",
                                      mb: "6%",
                                    }}
                                  >
                                    <FormControlLabel
                                      value={el?.optionContent}
                                      control={
                                        <Radio
                                          color="success"
                                          checked={
                                            el?.optionContent ===
                                            PreviewQsn[CurrQuestion?.count]
                                              ?.correctAnswer
                                          }
                                        />
                                      }
                                      label={el?.optionContent}
                                      sx={{ ml: "1%" }}
                                    />
                                  </Box>
                                );
                              }
                            )}
                      </RadioGroup>
                      {/* {isEditMode &&
                      PreviewQsn[CurrQuestion]?.count?.options?.length < 4 && (
                        <Box>
                          <TextField
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Radio sx={{ ml: "-8.5px" }} />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                          />
                        </Box>
                      )} */}
                    </Stack>
                  </Box>
                </Stack>
                {isEditMode ? (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Typography
                      variant="body2"
                      paddingX={1}
                      paddingY={1}
                      sx={{
                        bgcolor: "#FDF2D6",
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: "11px",
                        minWidth: "45%",
                        mr: 2,
                        alignSelf: "center",
                        textTransform: "capitalize",
                        color: "#99834B",
                      }}
                    >
                      {" "}
                      Please choose the appropriate level of complexity for your
                      questions creation
                    </Typography>
                    <Typography
                      alignSelf={"center"}
                      sx={{ fontWeight: 600, color: "#212121" }}
                      variant="body2"
                    >
                      {" "}
                      {toggleComplexity === 1
                        ? "Easy"
                        : toggleComplexity === 2
                        ? "Medium"
                        : toggleComplexity === 3
                        ? "Hard"
                        : ""}
                    </Typography>
                    <IconButton
                      style={{ color: "#46A997" }}
                      onClick={handleToggleComplexity}
                    >
                      <SwapHorizSharp fontSize="large" />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    display={TestPreviewActive.viewMode ? "none" : "flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Typography
                      alignSelf={"center"}
                      sx={{ fontWeight: 600, color: "#212121" }}
                      variant="body2"
                    >
                      {" "}
                      {toggleComplexity === 1
                        ? "Easy"
                        : toggleComplexity === 2
                        ? "Medium"
                        : toggleComplexity === 3
                        ? "Hard"
                        : ""}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
            {TestPreviewActive.viewMode ? (
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  mt: "3%",
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "rgba(209, 0, 0, 1)",
                    border: "1px solid rgba(209, 0, 0, 1)",
                    "&:hover": { border: "1px solid rgba(209, 0, 0, 1)" },
                    textTransform: "capitalize",
                    width: "90px",
                    mr: 5,
                  }}
                  onClick={() => {
                    setCurrQuestion({
                      count: 0,
                    });
                    setToggleComplexity(1);
                    setPreviewQsn([]);
                    handleTestPreviewModal("closeMode");
                  }}
                >
                  Exit
                </Button>
                <Button
                  variant="contained"
                  // disabled={submitResult && submitScore}
                  sx={{
                    backgroundColor: "#908DB8",
                    "&:hover": { backgroundColor: "#908DB8" },
                    textTransform: "capitalize",
                    width: "90px",
                  }}
                  onClick={handlePrevQsn}
                  disabled={CurrQuestion.count === 0}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  // disabled={submitResult && submitScore}
                  sx={{
                    backgroundColor: "#908DB8",
                    "&:hover": { backgroundColor: "#908DB8" },
                    textTransform: "capitalize",
                    width: "90px",
                  }}
                  disabled={CurrQuestion.count + 1 === PreviewQsn.length}
                  onClick={handleNextQsn}
                >
                  Next
                </Button>
              </Box>
            ) : isEditMode ? (
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: "3%",
                  gap: 2,
                }}
              >
                <IconButton style={{ alignSelf: "center", color: "#F90F0F" }}>
                  <Delete />
                  <Typography color="#F90F0F" sx={{ fontWeight: 600 }}>
                    Delete
                  </Typography>
                </IconButton>
                <Button
                  variant="outlined"
                  sx={{
                    color: "rgba(209, 0, 0, 1)",
                    border: "1px solid rgba(209, 0, 0, 1)",
                    "&:hover": { border: "1px solid rgba(209, 0, 0, 1)" },
                    textTransform: "capitalize",
                    width: "90px",
                    mr: 5,
                    ml: "auto",
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    setEditState(null);
                    setIsEditMode(false);
                    setCurrQuestion({
                      count: 0,
                    });
                    setToggleComplexity(1);
                    setPreviewQsn([]);
                    handleTestPreviewModal("closeMode");
                  }}
                >
                  Exit
                </Button>
                <Button
                  variant="contained"
                  // disabled={submitResult && submitScore}
                  sx={{
                    backgroundColor: "#908DB8",
                    "&:hover": { backgroundColor: "#908DB8" },
                    textTransform: "capitalize",
                    width: "90px",
                    alignSelf: "center",
                  }}
                  onClick={handleSaveQuestion}
                  // disabled={CurrQuestion.count === 0}
                >
                  Save
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: "3%",
                  gap: 2,
                }}
              >
                <IconButton
                  onClick={() => setConfirmDxb(true)}
                  style={{ alignSelf: "center", color: "#F90F0F" }}
                >
                  <Delete />
                  <Typography color="#F90F0F" sx={{ fontWeight: 600 }}>
                    Delete
                  </Typography>
                </IconButton>
                <Button
                  variant="outlined"
                  sx={{
                    color: "rgba(209, 0, 0, 1)",
                    border: "1px solid rgba(209, 0, 0, 1)",
                    "&:hover": { border: "1px solid rgba(209, 0, 0, 1)" },
                    textTransform: "capitalize",
                    width: "90px",
                    mr: 5,
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    setEditState(null);
                    setIsEditMode(false);
                    setCurrQuestion({
                      count: 0,
                    });
                    setToggleComplexity(1);
                    setPreviewQsn([]);
                    handleTestPreviewModal("closeMode");
                  }}
                >
                  Exit
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={confirmDxb}
        onClose={() => setConfirmDxb(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete this question, do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDxb(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeletebyQsnId} variant="contained" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TestPreviewModal;
