import { axiosInstance } from "./Api";

const UploadNewTestLibrary = (NewTestLibraryForm) => {
  return axiosInstance.post(
    `questions/add-bulk-questions?skillName=${
      NewTestLibraryForm.testModeName
    }&skillCategoryName=${encodeURIComponent(
      NewTestLibraryForm.categoryName
    )}&complexityName=${
      NewTestLibraryForm.testTypeName
    }&topicSubCategoryName=${encodeURIComponent(
      NewTestLibraryForm.subCategoryName
    )}&questionTypeName=${NewTestLibraryForm.testModeTypeName}`,
    NewTestLibraryForm.uploadFiles,

    { headers: { "content-type": "multipart/form-data" } }
  );
};

export default UploadNewTestLibrary;
