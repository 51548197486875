import { Chip } from "@mui/joy";
import {
  Box,
  Button,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ViewTestLibrarybyTopicId from "../../../API/ViewTestLibrarybyTopicId";

const CardViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "#FFFFFF",
  borderRadius: "15px",
  // border: "2px solid #000",
  boxShadow: 24,
  minHeight: "60%",
  width: "60%",
  p: 4,
};
const CardViewTestLibrary = ({ SelectedCardData, handleTestPreviewModal }) => {
  const [ViewCardData, setViewCardData] = useState([]);
  useEffect(() => {
    ViewTestLibrarybyTopicId(SelectedCardData)
      .then((response) => setViewCardData(response.data[0]))
      .catch((err) => console.log(err));
  }, []); // eslint-disable-line

  return (
    <>
      <Box sx={CardViewStyle}>
        {ViewCardData ? (
          <Box component={Stack} direction={"column"}>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, fontSize: "16px", color: "#212121" }}
              >
                {ViewCardData?.Name}
              </Typography>
            </Box>
            <Box component={Stack} direction={"row"} sx={{ mt: 3 }}>
              <Box sx={{ flex: "70%" }}>
                <Box component={Stack} direction={"column"}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, fontSize: "15px", color: "#212121" }}
                  >
                    Description
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontWeight: 300,
                      fontSize: "14.5px",
                      color: "#000000DE",
                      mt: 2,
                    }}
                  >
                    {ViewCardData?.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#908DB8",
                      my: 3,
                    }}
                  >
                    {ViewCardData?.skillName} & {ViewCardData?.QuestionType}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: "14.5px",
                      color: "#000000DE",
                      mb: 1,
                    }}
                  >
                    Number of Questions
                  </Typography>
                  <Box
                    component={Stack}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Table
                      sx={{
                        borderCollapse: "collapse",
                        border: "1px solid #ddd",
                        width: "75%",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              border: "2px solid #ddd",
                              pt: 6,
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                fontWeight: 600,
                                fontSize: "14.5px",
                                color: "#000000DE",
                              }}
                            >
                              {ViewCardData?.easy?.Type}
                            </Typography>
                            <br />
                            {ViewCardData?.easy?.Count} Questions
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "2px solid #ddd",
                              pt: 6,
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                fontWeight: 600,
                                fontSize: "14.5px",
                                color: "#000000DE",
                              }}
                            >
                              {ViewCardData?.medium?.Type}
                            </Typography>{" "}
                            <br />
                            {ViewCardData?.medium?.Count} Questions
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "2px solid #ddd",
                              pt: 6,
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                fontWeight: 600,
                                fontSize: "14.5px",
                                color: "#000000DE",
                              }}
                            >
                              {ViewCardData?.hard?.Type}
                            </Typography>{" "}
                            <br />
                            {ViewCardData?.hard?.Count} Questions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                    <Button
                      variant="contained"
                      sx={{
                        alignSelf: "center",
                        bgcolor: "#908DB8",
                        textTransform: "capitalize",
                        fontWeight: 300,
                        mr: 2,
                      }}
                      onClick={() => handleTestPreviewModal("viewMode")}
                    >
                      Preview
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ flex: "30%" }}>
                <Box component={Stack} direction={"column"} pl={5}>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14.5px",
                        color: "#000000DE",
                      }}
                    >
                      Modified By
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 300,
                        fontSize: "14.5px",
                        color: "#000000DE",
                        letterSpacing: 0.7,
                        mt: 2,
                        textTransform: "capitalize",
                      }}
                    >
                      {ViewCardData?.modifiedBy === null
                        ? "N/A"
                        : ViewCardData?.modifiedBy}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14.5px",
                        color: "#000000DE",
                        mt: 2,
                      }}
                    >
                      Created Date
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 300,
                        fontSize: "14.5px",
                        color: "#000000DE",
                        letterSpacing: 0.7,
                        mt: 2,
                      }}
                    >
                      {ViewCardData?.createdDate !== null || undefined
                        ? ViewCardData?.createdDate
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14.5px",
                        color: "#000000DE",
                        mt: 2,
                      }}
                    >
                      Last Modification
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 300,
                        fontSize: "14.5px",
                        color: "#000000DE",
                        letterSpacing: 0.7,
                        mt: 2,
                      }}
                    >
                      {ViewCardData?.updatedDate !== null || undefined
                        ? ViewCardData?.updatedDate
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14.5px",
                        color: "#000000DE",
                        mt: 2,
                      }}
                    >
                      Categorized As
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {ViewCardData?.categorizedAs === null ? (
                        <Chip
                          variant="outlined"
                          size="sm"
                          sx={{
                            borderColor: "#818181",
                            color: "#818181",
                            letterSpacing: 1,
                          }}
                        >
                          N/A
                        </Chip>
                      ) : (
                        ViewCardData?.categorizedAs?.map((el) => {
                          return (
                            <Chip
                              variant="outlined"
                              size="sm"
                              sx={{
                                borderColor: "#818181",
                                color: "#818181",
                                letterSpacing: 1,
                              }}
                            >
                              {el}
                            </Chip>
                          );
                        })
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
          >
            {" "}
            Oops, No Data Found
          </Box>
        )}
      </Box>
    </>
  );
};

export default CardViewTestLibrary;
