import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Paper,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import TrashIcon from "../Assets/trash.png";
import { useSelector, useDispatch } from "react-redux";
import { removeProduct } from "../Redux/SecondStep";

const ThirdStepForm = ({ handleRemoveProduct }) => {
  const selectedProducts = useSelector((state) => state.secondStepForm.product);
  const activeStep = useSelector((state) => state.stepper.activeStep);

  const dispatch = useDispatch();

  // let FilterProduct = [...selectedProducts];

  useEffect(() => {
    selectedProducts.forEach((product) => {
      if (
        product?.count === 0 &&
        product?.count1 === 0 &&
        product?.count2 === 0
      ) {
        dispatch(removeProduct(product));
      }
    });
  }, [selectedProducts]); // eslint-disable-line

  return (
    <Slide
      direction={"left"}
      in={activeStep === 3 ? true : false}
      timeout={700}
      sx={{ width: "100%" }}
    >
      <Box
        component={Stack}
        sx={{ width: "100%" }}
        direction="column"
        alignItems="center"
      >
        <Box sx={{ flexGrow: 1, width: "100%" }}>
          <Typography
            paddingY={3}
            paddingX={2}
            variant="body2"
            sx={{
              bgcolor: "#FDF2D6",
              textAlign: "center",
              fontWeight: 500,
              fontSize: "11px",
              width: "60%",
              margin: "0 auto",
              // textTransform: "",
              color: "#99834B",
            }}
          >
            Please review the assessment thoroughly before sharing it with
            individuals or group members.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: "15px",
              fontWeight: 700,
              paddingY: 2,
              color: "#212121",
            }}
          >
            Review Assessment
          </Typography>
        </Box>
        <Box
          sx={{ width: "60%", borderRadius: "15px" }}
          paddingX={4}
          paddingY={1}
          component={Paper}
          elevation={2}
        >
          <TableContainer>
            <Table
              aria-label="collapsible table"
              sx={{ borderCollapse: "separate", borderSpacing: "0px 4px" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      textAlign: "center",
                      color: "#A3A3A3",
                      textTransform: "uppercase",
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      textAlign: "center",
                      color: "#A3A3A3",
                      textTransform: "uppercase",
                      fontWeight: 500,
                    }}
                  >
                    Duration
                  </TableCell>
                  {/* <TableCell
                  sx={{
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    textAlign: "center",
                    color: "#A3A3A3",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  Test Count
                </TableCell> */}
                  <TableCell
                    sx={{
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      textAlign: "center",
                      color: "#A3A3A3",
                      textTransform: "uppercase",
                      fontWeight: 500,
                    }}
                  >
                    Ques. Count
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      textAlign: "center",
                      color: "#A3A3A3",
                      textTransform: "uppercase",
                      fontWeight: 500,
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {selectedProducts.map((product, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "white",
                      borderBottom: "none",
                      "&:last-child": { marginBottom: 0 },
                    }}
                  >
                    <TableCell
                      sx={{ borderBottom: "none", textAlign: "center" }}
                    >
                      {product.Name}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "none", textAlign: "center" }}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        useFlexGap
                        sx={{ gap: 1 }}
                      >
                        <Icon
                          fontSize="15px"
                          sx={{ color: "#908DB8", alignSelf: "center" }}
                        >
                          <TimerIcon fontSize="inherit" />
                        </Icon>
                        <Typography variant="body2">
                          {product.timer / 60} Mins
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        textAlign: "center",
                        width: "21%",
                      }}
                    >
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-basic"
                            disabled
                            // placeholder="Test Count"
                            value={
                              product?.count + product?.count1 + product?.count2
                            }
                            sx={{
                              backgroundColor: "white",
                              width: "40%",
                              textAlign: "center",
                            }}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    {/* <TableCell sx={{ borderBottom: "none", textAlign: "center" }}>
                    <Typography variant="body2">Easy</Typography>
                  </TableCell> */}
                    <TableCell
                      sx={{ borderBottom: "none", textAlign: "center" }}
                    >
                      <IconButton
                        onClick={() => dispatch(removeProduct(product))}
                      >
                        <img
                          src={TrashIcon}
                          style={{ width: "16px" }}
                          alt="Trash can icon"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Slide>
  );
};

export default ThirdStepForm;
