import { axiosInstance } from "./Api";

const UpdateExistingTestLibrary = (existingTestLibraryForm) => {
  return axiosInstance.post(
    `questions/upload-existing-test-library?skillName=${encodeURIComponent(
      existingTestLibraryForm.testModeName
    )}&skillCategoryName=${encodeURIComponent(
      existingTestLibraryForm.categoryName
    )}&complexityName=${
      existingTestLibraryForm.testTypeName
    }&topicSubCategoryName=${
      existingTestLibraryForm.categoryName
    }&questionTypeName=${existingTestLibraryForm.testModeTypeName}`,
    existingTestLibraryForm.uploadFiles,

    { headers: { "content-type": "multipart/form-data" } }
  );
};

export default UpdateExistingTestLibrary;
