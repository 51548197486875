import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  TableContainer,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import React, { useCallback, useEffect, useState } from "react";
import CodeIcon from "@mui/icons-material/Code";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import RuleIcon from "@mui/icons-material/Rule";
import SecondStepFormCard from "./SecondStepFormCard";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilterDropdown,
  setFilterValue,
  setDropDownData,
  addProduct,
  removeProduct,
} from "../Redux/SecondStep";
import FindSubCategorybyTopicID from "../API/FindSubCategorybyTopicID";
import GetSkillCategory from "../API/GetSkillCategory";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SecondStepForm = ({
  categoryData,
  // selectedProducts,
  // onAdd,
  // onRemove,
  testCount,
  timer,
  testMode,
  activeStep,
  setTestCount,
  checkCustom,
  setTestMode,
  checkCode,
  checkType,
  overAlltime,
  selectedType,
  selectLevel,
  selectedMode,
  CountErrors,
  SetCountErrors,
}) => {
  const [alignment, setAlignment] = useState("mcq");
  const [searchValue, setSearchValue] = useState("");
  // const [filterValue, setFilterValue] = useState("");
  // const [filterDropDown, setFilterDropDown] = useState([]);
  // const [dropDownData, setDropDownData] = useState([]);
  useEffect(() => {
    return () => {
      dispatch(setDropDownData([]));
      dispatch(setFilterValue(""));
    };
  }, []); // eslint-disable-line
  const filterDropDown = useSelector(
    (state) => state.secondStepForm.filterDropdown
  );
  const filterValue = useSelector((state) => state.secondStepForm.filterValue);
  const dropDownData = useSelector(
    (state) => state.secondStepForm.dropDownData
  );
  const selectedProducts = useSelector((state) => state.secondStepForm.product);

  useEffect(() => {
    if (filterDropDown.length === 0) {
      fetchFilterData();
    }

    if (checkCode === "visible") {
      setAlignment("code");
    } else {
      setAlignment("mcq");
    }
  }, [filterDropDown.length, checkCode]); // eslint-disable-line

  const dispatch = useDispatch();

  const fetchFilterData = () => {
    GetSkillCategory()
      .then((res) => dispatch(setFilterDropdown(res.data)))
      .catch((err) => console.log(err));
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleFilterChange = useCallback((event) => {
    const selectedFilter = event.target.value;
    // setFilterValue(selectedFilter);
    dispatch(setFilterValue(event.target.value));

    if (selectedFilter) {
      fetchDropDownData(selectedFilter);
    } else {
      setDropDownData([]);
    }
  }, []); // eslint-disable-line

  // console.log(checkCustom,"custom")
  const fetchDropDownData = (filterId) => {
    FindSubCategorybyTopicID({
      categoryID: filterId,
      questionTypeId: selectLevel,
      skillId: selectedMode,
    }).then((response) => {
      if (selectedType === 1) {
        dispatch(
          setDropDownData(
            response?.data?.filter((item) => {
              // Check if there is at least one count value greater than 0
              return item?.ComplexityCounts["easy"] > 0;
            })
          )
        );
      } else if (selectedType === 2) {
        dispatch(
          setDropDownData(
            response?.data?.filter((item) => {
              // Check if there is at least one ComplexityCounts value greater than 0
              return item?.ComplexityCounts["medium"] > 0;
            })
          )
        );
      } else if (selectedType === 3) {
        dispatch(
          setDropDownData(
            response?.data?.filter((item) => {
              // Check if there is at least one ComplexityCounts value greater than 0
              return item?.ComplexityCounts["hard"] > 0;
            })
          )
        );
      } else {
        dispatch(
          setDropDownData(
            response.data.filter((item) => {
              // Check if there is at least one count value greater than 0
              return Object.values(item.ComplexityCounts).some(
                (count) => count > 0
              );
            })
          )
        );
      }
    });
  };
  const filteredCategoryData = categoryData.filter((product) => {
    const isMatched = product.Name?.toLowerCase().includes(
      searchValue.toLowerCase()
    );
    return isMatched;
  });

  const displayedCategoryData =
    filterValue === "" || searchValue.length > 0
      ? filteredCategoryData
      : dropDownData;

  // const updatedDisplayedCategoryData = displayedCategoryData.map((product) => {

  //   return {
  //     ...product,
  //     timer: 0,
  //   };
  // });
  const handleTimer = (product) => {
    const store = selectedProducts.find((prt) => prt.Name === product.Name);
    return store?.timer || 0;
  };

  const handleMode = (product) => {
    const store = selectedProducts.find((prt) => prt.Name === product.Name);
    // console.log(store, "store");
    return store?.testType || 1;
  };
  const handleCount = (product, type) => {
    if (type === 1) {
      const store = selectedProducts.find((prt) => prt.Name === product.Name);
      // console.log(store, "store");
      return store?.count || "";
    } else if (type === 2) {
      const store = selectedProducts.find((prt) => prt.Name === product.Name);
      // console.log(store, "store");
      return store?.count1 || "";
    } else if (type === 3) {
      const store = selectedProducts.find((prt) => prt.Name === product.Name);
      // console.log(store, "store");
      return store?.count2 || "";
    } else if (type === 4) {
      const store = selectedProducts.find((prt) => prt.Name === product.Name);
      // console.log(store, "store");
      return store?.count || "";
    }
  };

  return (
    <Slide
      direction={"left"}
      in={activeStep === 2 ? true : false}
      timeout={700}
      sx={{ width: "100%" }}
    >
      <Box
        sx={{ width: "100%" }}
        px={3}
        component={Stack}
        direction={"row"}
        flexWrap={"wrap"}
      >
        <Box sx={{ flexGrow: 1, width: "100%" }}>
          <Typography
            paddingY={3}
            paddingX={2}
            variant="body2"
            sx={{
              bgcolor: overAlltime ? "red" : "#FDF2D6",
              textAlign: "center",
              fontWeight: 500,
              fontSize: "11px",
              width: "60%",
              margin: "0 auto",
              letterSpacing: "0.5px",
              // textTransform: "capitalize",
              color: overAlltime ? "white" : "#99834B",
            }}
          >
            {" "}
            {overAlltime
              ? "Time limit has exceeded more than 60 minutes, It must be 60 mins or lesser"
              : "Include up to 6 libraries in the assessment by browsing the library. Choose the most relevant tests, utilizing various types for optimal results."}
          </Typography>
        </Box>
        <Box sx={{ width: "19%", height: "70vh" }}>
          <Paper
            elevation={0}
            component={Stack}
            direction={"column"}
            spacing={2}
            sx={{
              paddingX: 2,
              paddingY: 2,
              width: "90%",
              borderRadius: "15px",
              border: "1px solid #D7D6D6",
              height: "90%",
              overflowY: "auto",
              "::-webkit-scrollbar-track": {
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "10px",
                backgroundColor: "rgb(179, 177, 177)",
              },

              "::-webkit-scrollbar": {
                width: "5px",
                backgroundColor: "#FFFFFF",
              },

              "::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                backgroundColor: "rgb(136, 136, 136)",
              },
            }}
          >
            {selectedProducts?.length === 0 ? (
              <Box
                sx={{
                  backgroundColor: "lightgrey",
                  borderRadius: "5px",
                  padding: "8px 8px",
                  color: "white",
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                No Library Selected
              </Box>
            ) : (
              selectedProducts?.map((product) => (
                <Box
                  sx={{
                    backgroundColor: "#908DB8",
                    borderRadius: "5px",
                    padding: "8px 8px",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {product.Name}
                </Box>
              ))
            )}
          </Paper>
        </Box>
        <Stack sx={{ width: "80%" }} direction={"column"}>
          <Stack direction={"row"} justifyContent={"space-around"} spacing={4}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "15px",
                  paddingY: 2,
                  color: "#212121",
                  fontFamily: "Manrope",
                }}
              >
                Search
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Enter Library Name"
                size="small"
                sx={{
                  backgroundColor: "white",
                  fontFamily: "Manrope !important",
                  fontSize: "14px",

                  "& input::placeholder": {
                    fontFamily: "Manrope",
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-root": {
                    fontFamily: "Manrope",
                    fontSize: "14px",
                  },
                }}
                value={searchValue}
                onChange={handleSearchChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ minWidth: 210 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "15px",
                  paddingY: 2,
                  color: "#212121",
                  fontFamily: "Manrope",
                }}
              >
                Category
              </Typography>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={0}
                  sx={{
                    backgroundColor: "white",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                  }}
                  size="small"
                  placeholder="Select Type"
                  onChange={handleFilterChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    disabled
                    value={0}
                    sx={{ fontFamily: "Manrope", fontSize: "14px" }}
                  >
                    Select Category
                  </MenuItem>
                  {filterDropDown?.map((dropData) => {
                    return (
                      <MenuItem
                        key={dropData.id}
                        value={dropData.id}
                        sx={{ fontFamily: "Manrope", fontSize: "14px" }}
                      >
                        {dropData.skillCategoryName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ alignSelf: "flex-end" }}>
              {checkCustom === "visible" ? (
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#908DB8",
                      },
                      borderColor: "#908DB8",
                    }}
                    value="mcq"
                    aria-label="centered"
                  >
                    <RuleIcon
                      sx={{ color: alignment === "mcq" ? "white" : " black" }}
                    />
                    <Typography
                      sx={{ color: alignment === "mcq" ? "white" : " black" }}
                    >
                      MCQ
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#908DB8",
                      },
                      borderColor: "#908DB8",
                    }}
                    value="code"
                    aria-label="left aligned"
                  >
                    <CodeIcon
                      sx={{ color: alignment === "code" ? "white" : " black" }}
                    />
                    <Typography
                      sx={{ color: alignment === "code" ? "white" : " black" }}
                    >
                      code
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : null}
            </Box>
          </Stack>
          <Box
            sx={{
              maxHeight: "50vh",
              overflowY: "auto",
              overflowX: "hidden",
              "::-webkit-scrollbar-track": {
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "10px",
                backgroundColor: "rgb(179, 177, 177)",
              },

              "::-webkit-scrollbar": {
                width: "5px",
                backgroundColor: "#FFFFFF",
              },

              "::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                backgroundColor: "rgb(136, 136, 136)",
              },
              mt: 1,
              pr: 1,
            }}
          >
            {alignment === "mcq" ? (
              <Grid container spacing={1}>
                {displayedCategoryData?.length === 0 ? (
                  <Alert
                    severity="warning"
                    sx={{ width: "30vw", mt: 5, mx: "auto" }}
                  >
                    <AlertTitle>No Data Found</AlertTitle> Check Other
                    Categories!
                  </Alert>
                ) : (
                  displayedCategoryData?.map((product) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <SecondStepFormCard
                        cardData={product}
                        timer={handleTimer(product) || 0}
                        isSelected={selectedProducts?.some(
                          (item) => item.Name === product.Name
                        )}
                        onAdd={() => {
                          // onAdd(product)
                          if (selectedType === 4) {
                            dispatch(
                              addProduct({
                                ...product,
                                testType: 1,
                              })
                            );
                          } else {
                            dispatch(addProduct(product));
                          }
                        }}
                        onRemove={() => {
                          // onRemove(product)
                          dispatch(removeProduct(product));
                        }}
                        checkCustom={checkCustom}
                        testCountOnly={handleCount(product, 4)}
                        testCount={handleCount(product, 1)}
                        testCount1={handleCount(product, 2)}
                        testCount2={handleCount(product, 3)}
                        overAlltime={overAlltime}
                        checkType={checkType}
                        // testMode={selectedProducts ? selectedProducts.testType : 1}
                        testMode={handleMode(product)}
                        setTestCount={(event) => setTestCount(event, product)}
                        setTestMode={(event) => setTestMode(event, product)}
                        selectedType={
                          selectedType === 1
                            ? "easy"
                            : selectedType === 2
                            ? "medium"
                            : "hard"
                        }
                        CountErrors={CountErrors}
                        SetCountErrors={SetCountErrors}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            ) : alignment === "code" ? (
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{ borderCollapse: "separate", borderSpacing: "0px 6px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      >
                        Header 1
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      >
                        Header 2
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      >
                        Header 3
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      >
                        Header 4
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      >
                        Header 5
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      >
                        Header 6
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {[...Array(7)].map((_, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: "white",
                          borderBottom: "none",
                          "&:last-child": { marginBottom: 0 },
                        }}
                      >
                        <TableCell>Cell 1</TableCell>
                        <TableCell>Cell 2</TableCell>
                        <TableCell>Cell 3</TableCell>
                        <TableCell>Cell 4</TableCell>
                        <TableCell>Cell 5</TableCell>
                        <TableCell>Cell 6</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Box>
    </Slide>
  );
};

export default SecondStepForm;
