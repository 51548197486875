import { useEffect, useState } from "react";
import moment from "moment";

export const useTimer = (durationInMinutes) => {
  const [endTime, setEndTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState({
    minutes: 0,
    seconds: 0,
  });

  const startTimer = () => {
    // Get the current time using moment
    const startTime = moment();

    // Calculate the end time by adding the duration to the current time
    const calculatedEndTime = moment(startTime).add(
      durationInMinutes,
      "minutes"
    );

    // Set the end time in state
    setEndTime(calculatedEndTime);
  };

  useEffect(() => {
    let intervalId;

    // Start the countdown when endTime is set
    if (endTime) {
      intervalId = setInterval(() => {
        // Get the current time
        const currentTime = moment();

        // Calculate the remaining time in milliseconds
        const remainingTimeInMilliseconds = Math.max(endTime - currentTime, 0);

        // Convert remaining time to a moment duration
        const remainingTime = moment.duration(remainingTimeInMilliseconds);

        sessionStorage.setItem(
          "time",
          JSON.stringify({
            AssessmentTime: remainingTime?.milliseconds() / 60000,
          })
        );
        // Update the state with remaining minutes and seconds
        setRemainingTime({
          minutes: remainingTime.minutes(),
          seconds: remainingTime.seconds(),
        });
        // Display the remaining time (replace this with your logic)
        // console.log(
        //   `${remainingTime.minutes()} minutes and ${remainingTime.seconds()} seconds remaining`
        // );

        // Check if the countdown has reached zero
        if (remainingTimeInMilliseconds === 0) {
          clearInterval(intervalId);
          //   console.log("Countdown finished!");
        }
      }, 1000); // Update every second
    }

    // Cleanup the interval on component unmount or when endTime changes
    return () => clearInterval(intervalId);
  }, [endTime]);

  return { startTimer, remainingTime };
};
