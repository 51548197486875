import Configurations from "../../Components/Configuration/Configuration";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default function Configuration() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ pt: 4, pb: 3, bgcolor: "#EBECF1", height: "100vh" }}>
      {/* Back Button / Title  */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Item
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#ebecf1",
            boxShadow: "none",
            pl: 4,
          }}
        >
          <IconButton
            onClick={handleBackClick}
            disableRipple
            type="button"
            sx={{ alignSelf: "center", mr: 8 }}
            aria-label="arrow back"
          >
            <ArrowBackIcon
              fontSize="large"
              sx={{ color: "black", alignSelf: "center" }}
            />
          </IconButton>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 800,
              width: "80%",
              alignSelf: "center",
              color: "#212121",
              fontFamily: "Manrope",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            Configurations
          </Typography>
        </Item>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
        }}
      >
        <Configurations />
        {/* <Configurations /> */}
      </Box>
    </Box>
  );
}
