import {
  ArrowBackIosOutlined,
  CheckCircleOutlineOutlined,
  LockOutlined,
  PersonOutlineOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SendOTPbyMailId from "../../API/SendOTPbyMailId";
import { toast } from "react-toastify";
import VerifyOTPbyEmailId from "../../API/VerifyOTPbyEmailId";
import UpdatePasswordbyMailId from "../../API/UpdatePasswordbyMailId";

const ForgotPassword = ({ handleLoginType, currentStep, setCurrentStep }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [otpErrors, setOtpErrors] = useState([false, false, false, false]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [forgotUserDetails, setForgotUserDetails] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [forgotUserDetailsError, setForgotUserDetailsError] = useState({
    emailError: false,
    newPasswordError: false,
    confirmNewPasswordError: false,
  });
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [seconds, setSeconds] = useState(60);
  const [isComplete, setIsComplete] = useState(false);

  const [loading, setLoading] = useState({
    sendOTP: false,
    verifyOTP: false,
    updatePassword: false,
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    if (currentStep === 1) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(interval);
            setIsComplete(true); // Set the state to true when the countdown completes
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [currentStep, isComplete]);

  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const handleCurrentStep = () => {
    if (currentStep === 0) {
      handleLoginType();
      setLoading({
        sendOTP: false,
        verifyOTP: false,
        updatePassword: false,
      });
    } else {
      setCurrentStep(currentStep - 1);
      setSeconds(60);
      setIsComplete(false);
      setLoading({
        sendOTP: false,
        verifyOTP: false,
        updatePassword: false,
      });
    }
  };
  const handleForgotDetails = (property, value) => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setForgotUserDetails({
      ...forgotUserDetails,
      [property]: value,
    });
    setForgotUserDetailsError({
      ...forgotUserDetailsError,
      emailError: !emailRegex.test(value),
    });
    if (property === "newPassword") {
      setForgotUserDetailsError({
        ...forgotUserDetailsError,
        newPasswordError: !value,
      });
      // setForgotUserDetailsError({
      //   ...forgotUserDetailsError,
      //   confirmNewPasswordError: forgotUserDetails.confirmNewPassword !== value,
      // });
    }
    if (property === "confirmNewPassword") {
      setForgotUserDetailsError({
        ...forgotUserDetailsError,
        confirmNewPasswordError: forgotUserDetails.newPassword !== value,
      });
    }
  };

  const handleOtpDetails = (index, value) => {
    // Assuming your handle function looks something like this
    // Allow only numeric values

    if (/^[0-9]$/.test(value) || value === "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
      // Assuming you want to reset the error for the current input
      const newOtpErrors = [...otpErrors];
      newOtpErrors[index] = false;
      setOtpErrors(newOtpErrors);

      if (value && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      } else if (index < inputRefs.length - 1) {
        if (index !== 0) {
          inputRefs[index - 1].current.focus();
        }
      }
    }
  };

  const handleNext1 = (isResend) => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setForgotUserDetailsError({
      ...forgotUserDetailsError,
      emailError: !emailRegex.test(forgotUserDetails.email),
    });

    if (!forgotUserDetailsError?.emailError) {
      if (forgotUserDetails.email !== "") {
        try {
          setLoading({
            ...loading,
            sendOTP: true,
          });
          SendOTPbyMailId(forgotUserDetails?.email)
            .then((res) => {
              if (res.data.statusCodeValue === 401) {
                toast.error(res.data?.body?.status || "User not Found");
                setLoading({
                  ...loading,
                  sendOTP: false,
                });
              } else {
                if (!isResend) {
                  setCurrentStep(currentStep + 1);
                  setLoading({
                    ...loading,
                    sendOTP: false,
                  });
                }
              }
            })
            .catch((error) => {
              toast.error(
                error.response.data || "500 Internal Server Error,Try again!"
              );
              setLoading({
                ...loading,
                sendOTP: false,
              });
            });
        } catch (error) {
          toast.error(
            error.response.data || "500 Internal Server Error,Try again!"
          );
          setLoading({
            ...loading,
            sendOTP: false,
          });
        }
      }
    }
  };

  const handleNext2 = () => {
    const enteredOtp = otpValues.join("");

    // Check for missing values in entered OTP
    const missingValues = otpValues.some((value) => value === "");

    // Update errors for missing values
    if (missingValues) {
      const newOtpErrors = otpValues.map((value) => value === "");
      setOtpErrors(newOtpErrors);
    }
    if (!missingValues) {
      try {
        setLoading({
          ...loading,
          verifyOTP: true,
        });
        VerifyOTPbyEmailId({
          email: forgotUserDetails?.email,
          otp: enteredOtp,
        })
          .then((res) => {
            setCurrentStep(currentStep + 1);
            setLoading({
              ...loading,
              verifyOTP: false,
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error(
              error.response.data.Status ||
                "500 Internal Server Error,Try again!"
            );
            setIsComplete(true);
            setLoading({
              ...loading,
              verifyOTP: false,
            });
          });
      } catch (error) {
        console.log(error);
        toast.error(
          error.response.data.Status || "500 Internal Server Error,Try again!"
        );
        setIsComplete(true);
        setLoading({
          ...loading,
          verifyOTP: false,
        });
      }

      const newOtpErrors = otpErrors.map(() => false);
      setOtpErrors(newOtpErrors);
    }
  };

  const handleNext3 = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleNext4 = () => {
    // Validate password
    setForgotUserDetailsError((prevErrors) => ({
      ...prevErrors,
      newPasswordError: !forgotUserDetails.newPassword,
    }));

    // Validate confirm password
    setForgotUserDetailsError((prevErrors) => ({
      ...prevErrors,
      confirmNewPasswordError:
        forgotUserDetails.newPassword !== forgotUserDetails.confirmNewPassword,
    }));

    if (
      !forgotUserDetailsError?.newPasswordError &&
      !forgotUserDetailsError?.confirmNewPasswordError
    ) {
      if (
        forgotUserDetails?.newPassword !== "" ||
        forgotUserDetails?.confirmNewPassword !== ""
      )
        try {
          setLoading({
            ...loading,
            updatePassword: true,
          });
          UpdatePasswordbyMailId({
            email: forgotUserDetails?.email,
            newPassword: forgotUserDetails?.newPassword,
            confirmPassword: forgotUserDetails?.confirmNewPassword,
          })
            .then((res) => {
              setCurrentStep(currentStep + 1);
              setLoading({
                ...loading,
                updatePassword: false,
              });
            })
            .catch((err) => {
              toast.error(
                err.response.data || "500 Internal Server Error,Try again!"
              );
              setLoading({
                ...loading,
                updatePassword: false,
              });
            });
        } catch (error) {
          toast.error(
            error.response.data || "500 Internal Server Error,Try again!"
          );
          setLoading({
            ...loading,
            updatePassword: false,
          });
        }
    }
  };

  const handleNext5 = () => {
    setForgotUserDetails({
      email: "",
      otp: "",
      newPassword: "",
      confirmNewPassword: "",
    });

    setForgotUserDetailsError({
      emailError: false,
      newPasswordError: false,
      confirmNewPasswordError: false,
    });

    setSeconds(60);
    setIsComplete(false);
    setOtpErrors([false, false, false, false]);
    setOtpValues(["", "", "", ""]);
    setShowConfirmPassword(false);
    setShowPassword(false);
    setCurrentStep(0);
    handleLoginType();
  };

  const handleResendOTP = () => {
    handleNext1(true);
    setSeconds(60);
    setIsComplete(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        gap: 2,
        fontFamily: "manrope",
      }}
    >
      <Box>
        <Typography
          variant="p"
          sx={{
            fontSize: "24px",
            fontFamily: "manrope",
            fontWeight: 700,
            color: "#000000DE",
          }}
        >
          {" "}
          {currentStep === 0
            ? "Reset Password"
            : currentStep === 1
            ? "Reset Password"
            : "Reset Password"}
        </Typography>
      </Box>

      {currentStep === 0 ? (
        <Box
          sx={{
            borderRadius: "10px",
            width: "25vw",
            height: "55vh",
            bgcolor: "#FFFFFF",
            boxShadow: "0px 8px 53px 0px #908DB840",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            py: 5,
            // boxShadow: " 0px 4px 9px 0px #0000001A",
          }}
        >
          <Box sx={{ px: 1 }}>
            <Typography
              variant="p"
              sx={{
                fontSize: "14px",
                fontFamily: "manrope",
                fontWeight: 700,
                wordWrap: "wrap",
                color: "#000000DE",
                textAlign: "center",
              }}
            >
              {" "}
              Enter email to receive password reset code.
            </Typography>
          </Box>
          <Box>
            <TextField
              label="Email"
              sx={{ m: 1, width: "18vw" }}
              value={forgotUserDetails?.email}
              error={forgotUserDetailsError?.emailError}
              disabled={loading?.sendOTP}
              helperText={
                forgotUserDetailsError?.emailError ? "Enter Valid Email-Id" : ""
              }
              onChange={(e) => handleForgotDetails("email", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box>
            <Button
              sx={{
                width: "16vw",
                margin: "0px auto",
                bgcolor: "#908DB8",
                ":hover": {
                  bgcolor: "#908DB8",
                },
              }}
              variant="contained"
              onClick={() => handleNext1(false)}
              disabled={loading?.sendOTP}
            >
              {" "}
              {loading?.sendOTP ? (
                <CircularProgress
                  size={30}
                  color="info"
                  sx={{ alignSelf: "center" }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </Box>
          <Box>
            <Button
              sx={{
                width: "18vw",
                margin: "0px auto",
                textTransform: "capitalize",
                color: "#908DB8",
                fontFamily: "manrope",
                fontWeight: 700,
              }}
              onClick={handleLoginType}
            >
              {" "}
              Got Your Password? Login
            </Button>
          </Box>
          <Box>
            <IconButton
              sx={{
                color: "#908DB8",
                border: "2px solid #908DB8",
                borderRadius: "50%",
              }}
              onClick={handleCurrentStep}
            >
              <ArrowBackIosOutlined />
            </IconButton>
          </Box>
        </Box>
      ) : currentStep === 1 ? (
        <Box
          sx={{
            borderRadius: "10px",
            width: "25vw",
            height: "55vh",
            bgcolor: "#FFFFFF",
            boxShadow: "0px 8px 53px 0px #908DB840",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            py: 5,
            // boxShadow: " 0px 4px 9px 0px #0000001A",
          }}
        >
          <Box>
            <Typography
              variant="p"
              sx={{
                fontSize: "14px",
                fontFamily: "manrope",
                fontWeight: 700,
                px: 3,
                color: "#000000DE",
              }}
            >
              {" "}
              Enter Verification Code
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            {" "}
            {[1, 2, 3, 4].map((el, index) => {
              return (
                <Box key={index + 1}>
                  <TextField
                    key={index + 1}
                    inputRef={inputRefs[index]}
                    sx={{
                      m: 1,
                      width: "3vw",
                    }}
                    margin="normal"
                    size="small"
                    value={otpValues[index]}
                    error={otpErrors[index]}
                    // value={forgotUserDetails?.email}
                    // error={forgotUserDetailsError?.emailError}
                    // helperText={
                    //   forgotUserDetailsError?.emailError ? "Enter Valid Email-Id" : ""
                    // }

                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                    }}
                    onChange={(e) => handleOtpDetails(index, e.target.value)}
                  />
                </Box>
              );
            })}
          </Box>

          <Box>
            <Button
              sx={{
                width: "16vw",
                margin: "0px auto",
                bgcolor: "#908DB8",
                ":hover": {
                  bgcolor: "#908DB8",
                },
              }}
              variant="contained"
              onClick={handleNext2}
              disabled={loading?.verifyOTP}
            >
              {" "}
              {loading?.verifyOTP ? (
                <CircularProgress
                  size={30}
                  color="info"
                  sx={{ alignSelf: "center" }}
                />
              ) : (
                "Verify"
              )}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              ml: "auto",
              mr: 8.5,
            }}
          >
            {!isComplete ? (
              <p style={{ margin: 0 }}>
                {formatTime(minutes)}:{formatTime(remainingSeconds)}
              </p>
            ) : (
              <Button
                sx={{
                  margin: "0px auto",
                  textTransform: "capitalize",
                  color: "#908DB8",
                  fontFamily: "manrope",
                  fontWeight: 700,
                }}
                onClick={handleResendOTP}
              >
                {" "}
                Resend OTP
              </Button>
            )}
          </Box>
          <Box>
            <Button
              sx={{
                width: "18vw",
                margin: "0px auto",
                textTransform: "capitalize",
                color: "#908DB8",
                fontFamily: "manrope",
                fontWeight: 700,
              }}
              onClick={handleLoginType}
            >
              {" "}
              Got Your Password? Login
            </Button>
          </Box>
          <Box>
            <IconButton
              sx={{
                color: "#908DB8",
                border: "2px solid #908DB8",
                borderRadius: "50%",
              }}
              onClick={handleCurrentStep}
            >
              <ArrowBackIosOutlined />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "10px",
            width: "25vw",
            height: "55vh",
            bgcolor: "#FFFFFF",
            boxShadow: "0px 8px 53px 0px #908DB840",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 3,
            py: 5,
            // boxShadow: " 0px 4px 9px 0px #0000001A",
          }}
        >
          {currentStep === 2 ? (
            <>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: 700,
                    fontFamily: "manrope",
                  }}
                >
                  {" "}
                  Successfully Verified
                </Typography>
              </Box>
              <Box sx={{ fontSize: "140px", color: "#908DB8" }}>
                <CheckCircleOutlineOutlined fontSize="inherit" />
              </Box>
              <Button
                sx={{
                  width: "16vw",
                  margin: "0px auto",
                  bgcolor: "#908DB8",
                  ":hover": {
                    bgcolor: "#908DB8",
                  },
                }}
                variant="contained"
                onClick={handleNext3}
              >
                {" "}
                Next
              </Button>
            </>
          ) : currentStep === 3 ? (
            <>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: 700,
                    fontFamily: "manrope",
                  }}
                >
                  {" "}
                  Enter New Password
                </Typography>
              </Box>
              <Box>
                <TextField
                  label="Password"
                  sx={{ m: 1, width: "18vw" }}
                  type={showPassword ? "text" : "password"}
                  value={forgotUserDetails?.password}
                  error={forgotUserDetailsError?.newPasswordError}
                  helperText={
                    forgotUserDetailsError?.newPasswordError
                      ? "Enter Valid Password"
                      : ""
                  }
                  onChange={(e) =>
                    handleForgotDetails("newPassword", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <TextField
                  label="Confirm Password"
                  sx={{ m: 1, width: "18vw" }}
                  type={showConfirmPassword ? "text" : "password"}
                  value={forgotUserDetails?.confirmNewPassword}
                  error={forgotUserDetailsError?.confirmNewPasswordError}
                  helperText={
                    forgotUserDetailsError?.confirmNewPasswordError
                      ? "Enter Valid Password"
                      : ""
                  }
                  onChange={(e) =>
                    handleForgotDetails("confirmNewPassword", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button
                sx={{
                  width: "16vw",
                  margin: "0px auto",
                  bgcolor: "#908DB8",
                  ":hover": {
                    bgcolor: "#908DB8",
                  },
                }}
                variant="contained"
                onClick={handleNext4}
              >
                {" "}
                Next
              </Button>
            </>
          ) : (
            <>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: 700,
                    fontFamily: "manrope",
                  }}
                >
                  {" "}
                  Password Reset Successfully
                </Typography>
              </Box>
              <Box sx={{ fontSize: "140px", color: "#908DB8" }}>
                <CheckCircleOutlineOutlined fontSize="inherit" />
              </Box>
              <Button
                sx={{
                  width: "16vw",
                  margin: "0px auto",
                  bgcolor: "#908DB8",
                  ":hover": {
                    bgcolor: "#908DB8",
                  },
                }}
                variant="contained"
                onClick={handleNext5}
              >
                {" "}
                Next
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ForgotPassword;
