import {
  EmailOutlined,
  LockOutlined,
  PersonOutlineOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import UserSignUpApi from "../../API/UserSignUpApi";

const SignupInput = ({ handleLoginType, setSignupCompleted }) => {
  const [userDetails, setUserDetails] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [userDetailsError, setUserDetailsError] = useState({
    usernameError: false,
    emailError: false,
    passwordError: false,
    confirmPasswordError: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleLoginCreds = (property, value) => {
    if (property === "userName") {
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setUserDetails({
          ...userDetails,
          [property]: value,
        });
      }
    } else {
      setUserDetails({
        ...userDetails,
        [property]: value,
      });
    }
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleSignUp = () => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate username
    setUserDetailsError((prevErrors) => ({
      ...prevErrors,
      usernameError: userDetails.userName === "",
    }));

    // Validate email
    setUserDetailsError((prevErrors) => ({
      ...prevErrors,
      emailError: !emailRegex.test(userDetails.email),
    }));

    // Validate password
    setUserDetailsError((prevErrors) => ({
      ...prevErrors,
      passwordError: !userDetails.password,
    }));

    // Validate confirm password
    setUserDetailsError((prevErrors) => ({
      ...prevErrors,
      confirmPasswordError:
        userDetails.password !== userDetails.confirmPassword,
    }));

    // If there are no errors, proceed with sign up
    // callFunc();
  };

  useEffect(() => {
    // If there are no errors, proceed with sign up
    if (
      !userDetailsError.usernameError &&
      !userDetailsError.emailError &&
      !userDetailsError.passwordError &&
      !userDetailsError.confirmPasswordError
    ) {
      if (userDetails.userName !== "") callLoginFunc();
    }
  }, [userDetailsError]); // eslint-disable-line

  const callLoginFunc = () => {
    if (
      !userDetailsError.usernameError &&
      !userDetailsError.emailError &&
      !userDetailsError.passwordError &&
      !userDetailsError.confirmPasswordError
    ) {
      UserSignUpApi({
        username: userDetails.userName,
        email: userDetails.email,
        password: userDetails.password,
        confirmPassword: userDetails.confirmPassword,
      })
        .then((res) => {
          if (res.status === 200) {
            setSignupCompleted(true);
            toast.success("SignUp Success!");
            toast.success("Please Login Now");
            handleLoginType();
          }
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.status || "Error Occured, Try Again!"
          );
        });
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box>
        <Typography
          variant="p"
          sx={{ fontSize: "24px", fontWeight: 600, color: "#000000DE" }}
        >
          {" "}
          Sign Up
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: "10px",
          width: "25vw",
          minHeight: "64vh",
          bgcolor: "#FFFFFF",
          boxShadow: "0px 8px 53px 0px #908DB840",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          py: 1.5,
          // boxShadow: " 0px 4px 9px 0px #0000001A",
        }}
      >
        <Box>
          <TextField
            label="Full Name"
            sx={{ m: 1, width: "18vw" }}
            value={userDetails?.userName}
            onChange={(e) => handleLoginCreds("userName", e.target.value)}
            error={userDetailsError?.usernameError}
            helperText={
              userDetailsError?.usernameError ? "Enter Valid Name" : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            label="Email Address"
            sx={{ m: 1, width: "18vw" }}
            value={userDetails?.email}
            onChange={(e) => handleLoginCreds("email", e.target.value)}
            error={userDetailsError?.emailError}
            helperText={
              userDetailsError?.emailError ? "Enter Valid Email-Id" : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            label="Password"
            sx={{ m: 1, width: "18vw" }}
            type={showPassword ? "text" : "password"}
            value={userDetails?.password}
            error={userDetailsError?.passwordError}
            helperText={
              userDetailsError?.passwordError ? "Enter Valid Password" : ""
            }
            onChange={(e) => handleLoginCreds("password", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            label="Confirm Password"
            sx={{ m: 1, width: "18vw" }}
            type={showConfirmPassword ? "text" : "password"}
            value={userDetails?.confirmPassword}
            error={userDetailsError?.confirmPasswordError}
            helperText={
              userDetailsError?.confirmPasswordError
                ? "Enter Valid Password"
                : ""
            }
            onChange={(e) =>
              handleLoginCreds("confirmPassword", e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button
            sx={{
              width: "16vw",
              margin: "0px auto",
              bgcolor: "#908DB8",
              ":hover": {
                bgcolor: "#908DB8",
              },
            }}
            variant="contained"
            onClick={handleSignUp}
          >
            {" "}
            Signup
          </Button>
        </Box>
        <Box>
          <Button
            sx={{
              width: "18vw",
              margin: "0px auto",
              textTransform: "capitalize",
              color: "#908DB8",
            }}
            onClick={handleLoginType}
          >
            {" "}
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupInput;
