import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import RemoveIcon from "@mui/icons-material/Remove";
import person from "../Assets/person.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  complexity,
  questionType,
  setSelectedLevel,
  setSelectedMode,
  setSelectedType,
  skill,
} from "../Redux/FirstStep";
import {
  removeProduct,
  setDropDownData,
  setFilterDropdown,
  setFilterValue,
} from "../Redux/SecondStep";
import { axiosInstance } from "../API/Api";
import GenerateTestLink from "../API/GenerateTestLink";
import { toggleTimer } from "../Redux/Stepper";

// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import SaveIcon from "@mui/icons-material/Save";
// import SendMailToUser from "../API/SendMailToUser";
// import AddLinkforUser from "../API/AddLinkforUser";

const FourthStepForm = (props) => {
  const {
    registerUserId, // eslint-disable-line
    submitData,
    isRegister,
    selectedProducts,
    nameOfTheTest,
    testTypeId,
    navStateVal,
  } = props;
  const [sendEmail, setSendEmail] = useState("");
  const [sendName, setSendName] = useState("");
  const [groupCandidate, setGroupCandidate] = useState(false);
  const [candidateListArr, setCandidateListArr] = useState([]);
  const [candidateDetails, setCandidateDetails] = useState({
    name: null,
    email: null,
  });
  const [sendEmailLoading, setSendEmailLoading] = useState({
    data: null,
    res: "",
  });
  const [questionLink, setLink] = useState("");
  const [addLinkLoading, setAddLinkLoading] = useState({ data: null, res: "" }); // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const activeStep = useSelector((state) => state.stepper.activeStep);
  const directSaveLink = useSelector(
    (state) => state.firstStepForm.directSaveLink
  );
  const assessmentData = useSelector(
    (state) => state.firstStepForm.assessmentData
  );

  const saveAssessment = useSelector(
    (state) => state.firstStepForm.saveAssessment
  );

  const editQuestionLink = questionLink?.encryptedKey?.replace("//", "/ /");
  const testLink = directSaveLink
    ? assessmentData?.link
    : //`${window.location.origin}/UserTestLogin/${editQuestionLink}`;
      `${window.location.origin}/UserTestLogin/id=${editQuestionLink}`;
  const [assessmentTime, setAssessmentTime] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const timer = useSelector((state) => state.stepper.timer);
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  useEffect(() => {
    if (assessmentTime === 0) {
      const newAssessmentTime = selectedProducts
        .map((dt) => Number(dt.timer))
        .reduce((prev, currentScore) => prev + currentScore, 0);

      setAssessmentTime(newAssessmentTime);
    }

    if (totalCount === 0) {
      const count = selectedProducts
        .map((dt) => Number(dt.count) + Number(dt.count1) + Number(dt.count2))
        .reduce((prev, currentScore) => prev + currentScore, 0);

      setTotalCount(count);
    }
  }, [assessmentTime]); // eslint-disable-line
  const sendLinkToEmail = candidateListArr.map((data) => data.email).join(","); // eslint-disable-line

  const reqBody = {
    complexityId: testTypeId,
    userNames: groupCandidate
      ? candidateListArr?.map((dt) => dt.name)
      : [sendName],
    userEmails: groupCandidate
      ? candidateListArr?.map((dt) => dt.email)
      : [sendEmail],
    link: testLink,
    nameOfTheAssessment: nameOfTheTest,
    assessmentTime: directSaveLink
      ? assessmentData?.assessmentTime
      : timer
      ? (Number(assessmentTime) / 60).toFixed(1)
      : 0,
    questionCount: totalCount,
    savedAssessment: saveAssessment,
  };

  useEffect(() => {
    if (reqBody.questionCount !== 0) {
      if (questionLink === "") {
        GenerateTestLink(submitData, reqBody)
          .then((res) => setLink(res.data))
          .catch((err) => console.log(err));
      }
    }
  }, [reqBody.questionCount]); // eslint-disable-line

  const dispatch = useDispatch();
  const groupDispatch = () => {
    dispatch(complexity([]));
    dispatch(skill([]));
    dispatch(questionType([]));
    dispatch(setSelectedType(0));
    dispatch(setSelectedLevel(0));
    dispatch(setSelectedMode(0));
    dispatch(setFilterDropdown([]));
    dispatch(setFilterValue(""));
    dispatch(setDropDownData([]));
    dispatch(removeProduct([]));
    dispatch(toggleTimer(true));
  };
  useEffect(() => {
    return () => groupDispatch();
  }, []); // eslint-disable-line

  const [emailError, setEmailError] = useState({
    nameError: false,
    emailError: false,
  });

  const handleSendEmail = async () => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setEmailError({
      ...emailError,
      emailError: !emailRegex.test(sendEmail),
    });
    if (
      sendEmail === (null || "") ||
      sendName === (null || "") ||
      !emailRegex.test(sendEmail)
    ) {
      if (sendName === (null || "")) {
        setEmailError({
          ...emailError,
          nameError: true,
        });
        if (!toast.isActive("SendMail_UsernameError")) {
          toast.warning("Please Enter Name ", {
            position: "top-center",
            toastId: "SendMail_UsernameError",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            // theme: "colored",
          });
        }
      } else {
        setEmailError({
          ...emailError,
          emailError: true,
        });
        if (!toast.isActive("SendMail_UsernameError")) {
          toast.warning("Please Enter Email-Id ", {
            position: "top-center",
            toastId: "SendMail_UsernameError",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            // theme: "colored",
          });
        }
      }
    } else {
      setEmailError({
        ...emailError,
        nameError: false,
        emailError: false,
      });

      let registeredUser = {
        savedAssessmentId:
          questionLink?.savedAssessmentId || assessmentData?.id,
        userName: reqBody?.userNames,
        emails: reqBody?.userEmails,
        adminEmail: null,
        link: [testLink].toString(),
        userId: auth?.userId,
      };

      try {
        setLoading(true);
        if (isRegister) {
          await axiosInstance
            .post(`register/send-mail-to-user`, registeredUser)
            .then((res) => {
              setSendEmailLoading({ data: res.data, res: "success" });
              if (!toast.isActive("SendMailSuccessMSG")) {
                toast.success("Your mail has been successfully sent!", {
                  toastId: "SendMailSuccessMSG",
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  // theme: "colored",
                });
              }

              setLoading(false);
            })
            .catch((err) => {
              setSendEmailLoading({
                data: err.response.data.status,
                res: "fail",
              });
            });
        } else {
          await axiosInstance
            .post(`register/send-mail-without-register?`, registeredUser)
            .then((res) => {
              setSendEmailLoading({ data: res.data, res: "success" });
              if (!toast.isActive("SendMailSuccessMSG")) {
                toast.success("Your mail has been successfully sent!", {
                  toastId: "SendMailSuccessMSG",
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  // theme: "colored",
                });
              }

              setLoading(false);
            })
            .catch((err) => {
              console.log(err, "err");
              setSendEmailLoading({
                data: err.response.data?.status,
                res: "fail",
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleGroupMail = async () => {
    let registeredUser = {
      savedAssessmentId: questionLink?.savedAssessmentId || assessmentData?.id,
      userName: reqBody?.userNames,
      emails: reqBody?.userEmails,
      adminEmail: null,
      link: [testLink].toString(),
      userId: auth?.userId,
    };

    if (!CandidateDetailErrors?.email && !CandidateDetailErrors?.name) {
      try {
        setLoading(true);

        await axiosInstance
          .post(`register/send-mail-without-register?`, registeredUser)
          .then((res) => {
            setSendEmailLoading({ data: res.data, res: "success" });
            if (!toast.isActive("SendMailSuccessMSG")) {
              toast.success("Your mail has been successfully sent!", {
                toastId: "SendMailSuccessMSG",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                // theme: "colored",
              });
            }

            setLoading(false);
          })
          .catch((err) => {
            setSendEmailLoading({
              data: err.response.data?.status,
              res: "fail",
            });
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleErrorModelClose = () => {
    setLoading(false);
    setSendEmailLoading({
      data: null,
      res: "",
    });
  };

  const handleGroupCandidata = () => {
    setGroupCandidate(!groupCandidate);
    setSendName("");
    setSendEmail("");
    setCandidateListArr([]);
    setCandidateDetails({
      name: null,
      email: null,
    });
    setEmailError({
      nameError: false,
      emailError: false,
    });
    setCandidateDetailsError({
      email: false,
      name: false,
    });
  };

  const [CandidateDetailErrors, setCandidateDetailsError] = useState({
    name: false,
    email: false,
  });

  const handleCandidateDetailChange = (e) => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const value = e.target.value;
    const name = e.target.name;
    if (groupCandidate) {
      if (name === "name") {
        setCandidateDetailsError({
          ...CandidateDetailErrors,
          name: value === "",
        });
      }
      if (name === "email") {
        setCandidateDetailsError({
          ...CandidateDetailErrors,
          email: !emailRegex.test(value),
        });
      }

      setCandidateDetails({ ...candidateDetails, [name]: value });
    } else {
      if (name === "email") setSendEmail(value);

      if (name === "name") setSendName(value);
    }
  };

  useEffect(() => {
    if (navStateVal?.formData?.email) {
      setSendEmail(navStateVal?.formData?.email);
    }
    if (navStateVal?.formData?.name) {
      setSendName(navStateVal?.formData?.name);
    }
  }, [navStateVal, navStateVal]); // eslint-disable-line

  const { name, email } = candidateDetails;
  const handleAddCandidate = () => {
    setCandidateDetailsError(() => {
      return {
        email: !emailRegex.test(candidateDetails?.email),
        name: !candidateDetails?.name,
      };
    });
    setCandidateListArr([...candidateListArr, { name, email }]);
    setCandidateDetails({ ...candidateDetails, name: null, email: null });
  };

  const handleRemoveCandidate = (i) => {
    const filterCandidateListArr = candidateListArr?.filter(
      (dt, ind) => ind !== i
    );
    setCandidateListArr(filterCandidateListArr);
  };
  // const [editValue, setEditValue] = useState(false);
  // const handleEditValue = () => {
  //   if (
  //     sendName !== navStateVal?.formData?.name ||
  //     sendEmail !== navStateVal?.formData?.email
  //   ) {
  //     axios
  //       .put(
  //         `http://localhost:8080/register/updateUserEmail?userId=${registerUserId}&userName=${sendName}&userEmail=${sendEmail}`
  //       )
  //       .then((res) => res)
  //       .catch((err) => console.log(err));
  //   }
  //   setEditValue(!editValue);
  // };

  return (
    <Slide
      direction={"left"}
      in={activeStep === 4 ? true : false}
      timeout={700}
      sx={{ width: "100%" }}
    >
      <Box
        component={Stack}
        sx={{ width: "100%" }}
        spacing={3}
        direction="column"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              paddingY: 2,
              color: "#212121",
            }}
          >
            Share Assessment
          </Typography>
        </Box>
        {/* <Box
          sx={{ width: "60%", borderRadius: "15px" }}
          paddingX={4}
          paddingY={1}
          component={Paper}
          elevation={2}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              paddingTop: 2,
              color: "#A3A3A3",
              textTransform: "uppercase",
            }}
          >
            General Link
          </Typography>
          <Button
            onClick={() => navigate(`/UserTestLogin/${testLink}`)}
          ></Button>
          <Stack
            direction={"row"}
            alignItems={"center"}
            paddingBottom={3}
            paddingTop={2}
            spacing={3}
          >
            <TextField
              id="outlined-basic"
              value={testLink}
              sx={{
                backgroundColor: "white",
                textAlign: "center",
                flexGrow: 1,
              }}
              size="small"
              variant="outlined"
            />
            <Button
              variant="contained"
              disabled={testLink == ""}
              onClick={() => navigator.clipboard.writeText(testLink)}
              sx={{
                bgcolor: "#46A997",
                "&:hover": {
                  backgroundColor: "#46A997",
                },
                borderRadius: "4px",
                fontSize: "11.5px",
                paddingBottom: "9px",
                paddingTop: "9px",
                textTransform: "capitalize",
              }}
            >
              Copy Link
            </Button>
          </Stack>
        </Box> */}
        <Box
          sx={{
            width: "60%",
            height: 380,
            overflow: "auto",
            borderRadius: "15px",
          }}
          paddingX={4}
          paddingY={4}
          component={Paper}
          elevation={2}
        >
          {/* {isRegister && (
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 900 }}>
                {" "}
                Check & Send
              </Typography>{" "}
              <Typography>, The Register Candidate Assesment</Typography>
            </Typography>
          )} */}
          {isRegister && (
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <img
                src={person}
                alt="userImg"
                style={{ width: 100, height: 100 }}
              />
            </Typography>
          )}
          {groupCandidate ? (
            <Stack direction={"column"} spacing={2}>
              {!isRegister && (
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    paddingTop: 0,
                    color: "#A3A3A3",
                    // alignSelf:'center',
                    textTransform: "uppercase",
                  }}
                >
                  Group Candidate
                </Typography>
              )}
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                alignItems={"center"}
                spacing={2}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
                  >
                    Name
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
                  >
                    Email
                  </Typography>
                </Box>
              </Stack>
              {candidateListArr?.length !== 5 && (
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  // alignItems={"center"}
                  spacing={2}
                >
                  <Box sx={{ width: "42.5%" }}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Name"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="name"
                      error={CandidateDetailErrors.name}
                      helperText={
                        CandidateDetailErrors.name ? "Enter Valid Name" : ""
                      }
                      value={
                        candidateDetails.name === 0 || candidateDetails.name
                          ? candidateDetails.name
                          : ""
                      }
                      onChange={(e) => handleCandidateDetailChange(e)}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "42.5%" }}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Mail"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="email"
                      error={CandidateDetailErrors.email}
                      helperText={
                        CandidateDetailErrors.email ? "Enter Valid Email" : ""
                      }
                      value={
                        candidateDetails.email === 0 || candidateDetails.email
                          ? candidateDetails.email
                          : ""
                      }
                      onChange={handleCandidateDetailChange}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "5%" }}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleAddCandidate}
                      disabled={
                        !candidateDetails?.name ||
                        !emailRegex.test(candidateDetails?.email) ||
                        sendEmailLoading?.res === "success" ||
                        candidateListArr?.length === 5
                      }
                      sx={{
                        backgroundColor: "#46a997",
                        "&:hover": {
                          backgroundColor: "#46a997",
                        },
                        height: 40,
                      }}
                    >
                      <IconButton sx={{ marginTop: "-3px", color: "#fff" }}>
                        <AddIcon />
                      </IconButton>
                    </Button>
                  </Box>
                </Stack>
              )}
              {candidateListArr?.map((data, i) => (
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Box sx={{ width: "42.5%" }}>
                    <TextField
                      id="outlined-read-only-input"
                      size="small"
                      // defaultValue={data && data.name}
                      value={data && data.name}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "42.5%" }}>
                    <TextField
                      id="outlined-read-only-input"
                      size="small"
                      value={data && data.email}
                      defaultValue={data && data.email}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "5%" }}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => handleRemoveCandidate(i)}
                      sx={{
                        backgroundColor: "red",
                        "&:hover": {
                          backgroundColor: "red",
                        },
                        height: 40,
                      }}
                      disabled={sendEmailLoading?.res === "success"}
                    >
                      <IconButton sx={{ marginTop: "-3px", color: "#fff" }}>
                        <RemoveIcon />
                      </IconButton>
                    </Button>
                  </Box>
                </Stack>
              ))}
              <Typography
                sx={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  variant="contained"
                  onClick={!groupCandidate ? handleSendEmail : handleGroupMail}
                  size="small"
                  disabled={
                    reqBody?.userEmails.length === 0 ||
                    sendEmailLoading?.res === "success"
                  }
                  sx={{
                    backgroundColor: "#46a997",
                    "&:hover": {
                      backgroundColor: "#46A997",
                    },
                  }}
                >
                  Send
                </Button>
              </Typography>
              {!isRegister && (
                <Box
                  sx={{ alignSelf: "flex-end" }}
                  component={Stack}
                  direction={"row"}
                >
                  <IconButton
                    sx={{
                      fontSize: "34px",
                      // color: "#212121",
                      borderRadius: "10px",
                    }}
                    onClick={handleGroupCandidata}
                    disabled={sendEmailLoading?.res === "success"}
                  >
                    <PersonIcon fontSize="inherit" />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "15px",
                        ml: 1,
                        color:
                          sendEmailLoading?.res === "success"
                            ? "#e0e0e0"
                            : "#212121",
                      }}
                    >
                      Individual Candidate
                    </Typography>
                  </IconButton>
                </Box>
              )}
            </Stack>
          ) : (
            <Stack direction={"column"} spacing={2}>
              {!isRegister && (
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    paddingTop: 0,
                    color: "#A3A3A3",
                    textTransform: "uppercase",
                  }}
                >
                  Individual Candidate
                </Typography>
              )}
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                alignItems={"center"}
                spacing={2}
              >
                <Box
                  sx={{
                    width: "45%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
                  >
                    Name
                  </Typography>
                  <TextField
                    id="filled-read-only-input"
                    onChange={(e) => setSendName(e.target.value)}
                    defaultValue={
                      isRegister ? navStateVal?.formData?.name : null
                    }
                    error={emailError?.nameError}
                    placeholder="Enter Name"
                    sx={{
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      readOnly: isRegister ? true : false,
                    }}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{
                    width: "45%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="filled-read-only-input"
                    onChange={(e) => setSendEmail(e.target.value)}
                    error={emailError?.emailError}
                    defaultValue={
                      isRegister ? navStateVal?.formData?.email : null
                    }
                    InputProps={{
                      readOnly: isRegister ? true : false,
                    }}
                    placeholder="Enter Email-Id"
                    sx={{
                      backgroundColor: "white",
                    }}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                {/* <Button
                  onClick={handleEditValue}
                  disabled={
                    sendEmailLoading?.res == "success" &&
                    addLinkLoading?.res == "success"
                  }
                  sx={{
                    width: "10%",
                    alignSelf: "end",
                    p: 1.1,
                    backgroundColor:
                      sendName !== navStateVal?.formData?.name ||
                      sendEmail !== navStateVal?.formData?.email
                        ? "#46A997"
                        : "#caa800",
                    "&:hover": { backgroundColor: "#caa800" },
                    color: "black",
                  }}
                  variant="contained"
                  startIcon={
                    sendName !== navStateVal?.formData?.name ||
                    sendEmail !== navStateVal?.formData?.email ? (
                      <SaveIcon />
                    ) : (
                      <EditIcon />
                    )
                  }
                ></Button> */}
              </Stack>
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSendEmail}
                  disabled={sendEmailLoading?.res === "success"}
                  sx={{
                    bgcolor: "#46A997",
                    "&:hover": {
                      backgroundColor: "#46A997",
                    },

                    borderRadius: "4px",
                    fontSize: "11.5px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",

                    textTransform: "capitalize",
                  }}
                >
                  Send
                </Button>
              </Box>
              {!isRegister && (
                <Box
                  // sx={{ cursor: "pointer" }}
                  component={Stack}
                  direction={"row"}
                  justifyContent={"flex-end"}
                >
                  <IconButton
                    sx={{
                      fontSize: "34px",
                      color: "#212121",
                      borderRadius: "10px",
                    }}
                    onClick={handleGroupCandidata}
                    disabled={sendEmailLoading?.res === "success"}
                  >
                    <GroupsOutlinedIcon fontSize="inherit" />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "15px",
                        color:
                          sendEmailLoading?.res === "success"
                            ? "#e0e0e0"
                            : "#212121",
                        ml: 1,
                      }}
                    >
                      Group Candidate
                    </Typography>
                  </IconButton>
                </Box>
              )}
            </Stack>
          )}
        </Box>
        <Dialog
          open={loading}
          onClose={handleErrorModelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Typography
            sx={{
              p: 5,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection:
                sendEmailLoading?.res === "fail" ||
                addLinkLoading?.res === "fail"
                  ? "column"
                  : "row",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <Typography>
              {sendEmailLoading?.res === "fail" ||
              addLinkLoading?.res === "fail" ? (
                <DialogTitle id="alert-dialog-title">
                  {sendEmailLoading?.res === "fail" && sendEmailLoading?.data}
                  {/* {addLinkLoading?.res == "fail" && "Error"} */}
                </DialogTitle>
              ) : (
                <DialogTitle
                  sx={{ mt: 3, fontWeight: "bold" }}
                  id="alert-dialog-title"
                >
                  Sending ...
                </DialogTitle>
              )}
            </Typography>
            <DialogContent>
              {sendEmailLoading?.res === "fail" ||
              addLinkLoading?.res === "fail" ? (
                <Typography>
                  {sendEmailLoading?.res === "fail" &&
                    sendEmailLoading?.data?.message}
                  {addLinkLoading?.res === "fail" &&
                    addLinkLoading?.data?.message}
                </Typography>
              ) : (
                <CircularProgress
                  size={68}
                  sx={{
                    color: "black",
                  }}
                />
              )}
            </DialogContent>
            {(sendEmailLoading?.res === "fail" ||
              addLinkLoading?.res === "fail") && (
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleErrorModelClose}
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            )}
          </Typography>
        </Dialog>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="colored"
        />
      </Box>
    </Slide>
  );
};

export default FourthStepForm;
