import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const CandiDateScoreTable = (props) => {
  const { scoreData } = props;

  return (
    <Box sx={{ height: "100%", width: "100%", pt: 2.5 }}>
      {scoreData?.map((dt) => {
        return (
          <Stack
            direction="column"
            sx={{
              width: "100%",
              borderBottom: "1px solid #d3d3d3",
            }}
            alignItems={"center"}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14.2px",
                  color: "black",
                  fontWeight: 600,
                  textTransform: "capitalize",
                  fontFamily: "inter",
                }}
              >
                {dt.topicName}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "auto",
                width: "80%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Grid container>
                {dt.details.map((val, index) => {
                  return (
                    <Grid
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          width: "40px",
                          border: "none",
                          backgroundColor:
                            val.status === "Correct"
                              ? "#43ad41"
                              : val.status === "Wrong"
                              ? "#ff4a4a"
                              : "#ffd91c",
                          borderRadius: "5px",
                          padding: "4px",
                          margin: "0px 5px 10px 5px",
                          fontSize: "12px",
                          fontFamily: "inter",
                        }}
                      >
                        {index + 1}
                      </button>
                    </Grid>
                  );
                })}{" "}
              </Grid>
            </Box>
            <Divider />
          </Stack>
        );
      })}
    </Box>
  );
};

export default CandiDateScoreTable;
