import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../../Components/Login-Signup/LoginCarousel.css";

export default function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const matches = useMediaQuery("(max-width:1330px)");
  const matchesLargeScreen = useMediaQuery("(max-width:1536px)");

  const items = [
    {
      title: "Expert Analysis",
      description:
        "Precise and skillful evaluation ensuring reliable insights.",
      icon: require("../../Assets/LoginSlider1.png"),
    },
    {
      title: "Assessment Spectrum",
      description:
        "Comprehensive range of assessments, tailored to measure various aspects of performance and skills.",
      icon: require("../../Assets/LoginSlider2.png"),
    },
    {
      title: "Right Hiring Decisions.",
      description: "Streamlining your path to building a high-performing team",
      icon: require("../../Assets/LoginSlider3.png"),
    },
  ];

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = items.length - 1;
    } else if (newIndex >= items.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const timer = setTimeout(() => updateIndex(activeIndex + 1), 3000);
    return () => clearTimeout(timer); // Clear the timer on component unmount or before setting a new one
  }, [activeIndex]); // eslint-disable-line

  return (
    <div className="carousel" style={{ width: "100%", height: "100%" }}>
      <div
        className="inner"
        style={{ transform: `translate(-${activeIndex * 100}%)` }}
      >
        {items.map((item) => {
          return (
            <div
              key={item.title}
              className="carousel-item"
              style={{ width: "100%", height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "88%",
                }}
              >
                <img
                  className="carousel-img"
                  src={item.icon}
                  alt=""
                  style={{
                    width: matches ? "100%" : matchesLargeScreen ? "80%" : "",
                  }}
                />
                <h2
                  className="carousel-item-title"
                  style={{
                    fontSize: matches
                      ? "24px"
                      : matchesLargeScreen
                      ? "28px"
                      : "",
                  }}
                >
                  {item.title}
                </h2>
                <div
                  className="carousel-item-text"
                  style={{
                    fontSize: matches
                      ? "12px"
                      : matchesLargeScreen
                      ? "14px"
                      : "",
                  }}
                >
                  {item.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="carousel-buttons">
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <span className="material-symbols-outlined"></span>
        </button>
        <div className="indicators">
          {items.map((item, index) => {
            return (
              <button
                key={index}
                className="indicator-buttons"
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <span
                  className={`material-symbols-outlined ${
                    index === activeIndex
                      ? "indicator-symbol-active"
                      : "indicator-symbol"
                  }`}
                ></span>
              </button>
            );
          })}
        </div>
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <span className="material-symbols-outlined"></span>
        </button>
      </div>
    </div>
  );
}
