import { axiosInstance } from "./Api";

const ModifyTestLibraryDescription = (topic, reqBody) => {
  return axiosInstance.put(
    `topicSubCategory/modify-description?id=${topic.id}&description=${topic?.description}&modifiedBy=${topic?.modifiedBy}`,
    reqBody
  );
};

export default ModifyTestLibraryDescription;
