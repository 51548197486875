import { Box, Button, Chip, Collapse, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import StepperProgress from "./StepperProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack, Typography } from "@mui/joy";
import FirstStepForm from "./FirstStepForm";
import SecondStepForm from "./SecondStepForm";
import ThirdStepForm from "./ThirdStepForm";

import FourthStepForm from "./FourthStepForm";
import { useDispatch, useSelector } from "react-redux";
import {
  complexity,
  skill,
  questionType,
  setSelectedType,
  setSelectedLevel,
  setSelectedMode,
  setUnSaveAssessment,
  setDirectAssessment,
  setAssessmentData,
} from "../Redux/FirstStep";
import {
  incActiveStep,
  decActiveStep,
  updateActiveStep,
  toggleTimer,
} from "../Redux/Stepper";
import {
  onCountChange,
  onTestTypeChange,
  removeProduct,
  setDropDownData,
  setFilterDropdown,
  setFilterValue,
} from "../Redux/SecondStep";
import GetAllComplexityType from "../API/GetAllComplexityType";

import FindQuestionTypebySkillId from "../API/FindQuestionTypebySkillId";

import GetAllTopicSubCategory from "../API/GetAllTopicSubCategory";

import GetSkillModeType from "../API/GetSkillModeType";
import { TimerOff } from "@material-ui/icons";
import Timer from "@mui/icons-material/Timer";
import { toast } from "react-toastify";

const FirstStep = () => {
  const [registerUserId, setRegisterUserId] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [submitData, setSubmitData] = useState([]);
  const [questionLink, setLink] = useState(""); // eslint-disable-line
  const [nameOfTheTest, setNameOfTheTest] = useState("");
  const [testTypeId, setTestTypeId] = useState(null);
  const [isIn, setIsIn] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [navStateVal, setNavStateVal] = useState(null);

  const activeStep = useSelector((state) => state.stepper.activeStep);
  const steps = useSelector((state) => state.stepper.stepCount);
  const selectedType = useSelector((state) => state.firstStepForm.selectedType);
  const selectedMode = useSelector((state) => state.firstStepForm.selectedMode);
  const selectLevel = useSelector((state) => state.firstStepForm.selectLevel);
  const selectedProducts = useSelector((state) => state.secondStepForm.product);
  const timer = useSelector((state) => state.stepper.timer);
  const [CountErrors, setCountErrors] = useState(false);

  useEffect(() => {
    if (!isIn) {
      setIsIn(true);
      // dispatch(updateActiveStep(1));
    }
    return () => {
      groupDispatch();
    };
  }, []); // eslint-disable-line

  const groupDispatch = () => {
    dispatch(complexity([]));
    dispatch(skill([]));
    dispatch(questionType([]));
    dispatch(setSelectedType(0));
    dispatch(setSelectedLevel(0));
    dispatch(setSelectedMode(0));
    dispatch(setFilterDropdown([]));
    dispatch(setFilterValue(""));
    dispatch(setDropDownData([]));
    dispatch(removeProduct([]));
    dispatch(setUnSaveAssessment(false));
    dispatch(setDirectAssessment(false));
    dispatch(setAssessmentData([]));
    dispatch(toggleTimer(true));
    dispatch(updateActiveStep(1));
    setCountErrors(false);
  };
  useEffect(() => {
    fetchCategoryData();
  }, [activeStep, selectLevel]); // eslint-disable-line

  useEffect(() => {
    if (activeStep === 3 && selectedProducts?.length === 0) {
      dispatch(updateActiveStep(2));
    }
  }, [activeStep, selectedProducts]); // eslint-disable-line

  const dispatch = useDispatch();

  const handleTimer = () => {
    if (timer) {
      dispatch(toggleTimer(false));
    } else {
      dispatch(toggleTimer(true));
    }
  };

  const nextStep = () => {
    dispatch(incActiveStep());
    let final;
    if (activeStep === 3) {
      if (selectedType === 4) {
        final = selectedProducts?.map((dt) => {
          return {
            header: Number(dt.Id),
            // count: Number(dt.count),
            complexityCount: [
              { count: Number(dt.count), complexity: 1 },
              { count: Number(dt.count1), complexity: 2 },
              { count: Number(dt.count2), complexity: 3 },
            ],
            // selectedType === 4 ? Number(dt.testType) : Number(selectedType),
            questionTypeId: Number(selectLevel),
          };
        });
      } else {
        final = selectedProducts?.map((dt) => {
          return {
            header: Number(dt.Id),
            // count: Number(dt.count),
            complexityCount: [
              {
                count: selectedType === 1 ? Number(dt.count) : 0,
                complexity: 1,
              },
              {
                count: selectedType === 2 ? Number(dt.count) : 0,
                complexity: 2,
              },
              {
                count: selectedType === 3 ? Number(dt.count) : 0,
                complexity: 3,
              },
            ],
            // selectedType === 4 ? Number(dt.testType) : Number(selectedType),
            questionTypeId: Number(selectLevel),
          };
        });
      }
      // let final = selectedProducts?.map((dt) => {
      //   return {
      //     header: Number(dt.Id),
      //     count: Number(dt.count),
      //     complexityId:
      //       selectedType === 4 ? Number(dt.testType) : Number(selectedType),
      //     questionTypeId: Number(selectLevel),
      //   };
      // });
      setSubmitData(final);
    }
  };

  const prevStep = () => {
    dispatch(decActiveStep());
    setCountErrors(false);
  };
  const totalSteps = steps.length;

  const fetchComplexity = async () => {
    await GetAllComplexityType()
      .then((res) => dispatch(complexity(res.data.slice(0, 4))))
      .catch((err) => console.log(err));
  };

  const fetchSkill = async () => {
    await GetSkillModeType()
      .then((res) => dispatch(skill(res.data)))
      .catch((err) => console.log(err));
  };

  const fetchQuestionType = async (dt) => {
    await FindQuestionTypebySkillId(dt)
      .then((res) => dispatch(questionType(res.data.slice(0, 3))))
      .catch((err) => console.log(err));
  };

  const fetchCategoryData = async () => {
    await GetAllTopicSubCategory({
      categoryID: 0,
      questionTypeId: selectLevel,
      skillId: selectedMode,
    })
      .then((res) => {
        if (selectedType === 1) {
          setCategoryData(
            res?.data?.filter((item) => {
              // Check if there is at least one count value greater than 0
              return item?.ComplexityCounts["easy"] > 0;
            })
          );
        } else if (selectedType === 2) {
          setCategoryData(
            res?.data?.filter((item) => {
              // Check if there is at least one ComplexityCounts value greater than 0
              return item?.ComplexityCounts["medium"] > 0;
            })
          );
        } else if (selectedType === 3) {
          setCategoryData(
            res?.data?.filter((item) => {
              // Check if there is at least one ComplexityCounts value greater than 0
              return item?.ComplexityCounts["hard"] > 0;
            })
          );
        } else {
          setCategoryData(
            res?.data?.filter((item) => {
              // Check if there is at least one ComplexityCounts value greater than 0
              return Object.values(item.ComplexityCounts).some(
                (count) => count > 0
              );
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchComplexity();
    fetchSkill();
  }, []); // eslint-disable-line

  //SecondStepForm

  // const handleAddProduct = (product) => {
  //   const updatedProduct = {
  //     ...product,
  //     count: 0,
  //     timer:0// Set initial count value
  //     // testType: "Easy", // Set initial test type
  //   };
  //   setSelectedProducts((prevSelectedProducts) => [
  //     ...prevSelectedProducts,
  //     updatedProduct,
  //   ]);
  // };

  // const handleRemoveProduct = (product) => {
  //   setSelectedProducts((prevSelectedProducts) =>
  //     prevSelectedProducts.filter(
  //       (selectedProduct) => selectedProduct.Name !== product.Name
  //     )
  //   );
  // };

  const calculateTime = (countValue) => {
    if (selectedType === 1) {
      // Easy
      return 30 * countValue;
    } else if (selectedType === 2) {
      // Difficult
      return 60 * countValue;
    } else {
      // Default time
      return 90 * countValue;
    }
  };

  const handleCountChange = async (event, product) => {
    let updatedProduct;
    const regex = /^[0-9\b]+$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      const prevData = selectedProducts.filter(
        (selectedProduct) => selectedProduct.Name === product.Name
      );

      if (selectedType === 4) {
        updatedProduct = {
          ...prevData[0],
          [event.target.name]:
            Number(event.target.value) >
            prevData[0]?.ComplexityCounts[
              event.target.name === "count"
                ? "easy"
                : event.target.name === "count1"
                ? "medium"
                : "hard"
            ]
              ? 0
              : Number(event.target.value),
          timer:
            event.target.name === "count"
              ? Number(event.target.value) >
                prevData[0]?.ComplexityCounts[
                  event.target.name === "count"
                    ? "easy"
                    : event.target.name === "count1"
                    ? "medium"
                    : "hard"
                ]
                ? 0 + prevData[0].count1 * 60 + prevData[0].count2 * 90
                : event.target.value * 30 +
                  prevData[0].count1 * 60 +
                  prevData[0].count2 * 90
              : event.target.name === "count1"
              ? Number(event.target.value) >
                prevData[0]?.ComplexityCounts[
                  event.target.name === "count"
                    ? "easy"
                    : event.target.name === "count1"
                    ? "medium"
                    : "hard"
                ]
                ? 0 + prevData[0].count * 30 + prevData[0].count2 * 90
                : event.target.value * 60 +
                  prevData[0].count * 30 +
                  prevData[0].count2 * 90
              : event.target.name === "count2"
              ? Number(event.target.value) >
                prevData[0]?.ComplexityCounts[
                  event.target.name === "count"
                    ? "easy"
                    : event.target.name === "count1"
                    ? "medium"
                    : "hard"
                ]
                ? 0 + prevData[0].count * 30 + prevData[0].count1 * 60
                : event.target.value * 90 +
                  prevData[0].count * 30 +
                  prevData[0].count1 * 60
              : 0,
        };
      } else {
        updatedProduct = {
          ...prevData[0],
          [event.target.name]:
            event.target.value >
            prevData[0]?.ComplexityCounts[
              selectedType === 1
                ? "easy"
                : selectedType === 2
                ? "medium"
                : "hard"
            ]
              ? 0
              : Number(event.target.value),
          timer:
            event.target.value >
            prevData[0]?.ComplexityCounts[
              selectedType === 1
                ? "easy"
                : selectedType === 2
                ? "medium"
                : "hard"
            ]
              ? 0
              : calculateTime(event.target.value),
        };
      }

      dispatch(
        onCountChange({ updatedProduct: updatedProduct, product: product })
      );
      if (selectedType === 4) {
        if (
          event.target.value >
          prevData[0]?.ComplexityCounts[
            event.target.name === "count"
              ? "easy"
              : event.target.name === "count1"
              ? "medium"
              : "hard"
          ]
        ) {
          // setCountErrors(true);
          toast.error(
            `Question count cannot be more than ${
              prevData[0]?.ComplexityCounts[
                event.target.name === "count"
                  ? "easy"
                  : event.target.name === "count1"
                  ? "medium"
                  : "hard"
              ]
            }`
          );
        } else {
          setCountErrors(false);
        }
      } else {
        if (
          event.target.value >
          product.ComplexityCounts[
            selectedType === 1 ? "easy" : selectedType === 2 ? "medium" : "hard"
          ]
        ) {
          // setCountErrors(true);
          toast.error(
            `Question count cannot be more than ${
              product?.ComplexityCounts[
                selectedType === 1
                  ? "easy"
                  : selectedType === 2
                  ? "medium"
                  : "hard"
              ]
            }`
          );
        } else {
          setCountErrors(false);
        }
      }
      // setSelectedProducts((prevSelectedProducts) =>
      //   prevSelectedProducts.map((selectedProduct) =>
      //     selectedProduct.Name === product.Name ? updatedProduct : selectedProduct
      //   )
      // );
    }
  };

  const handleTestTypeChange = (event, product) => {
    const prevData = selectedProducts.filter(
      (selectedProduct) => selectedProduct.Name === product.Name
    );

    const updatedProduct = {
      ...prevData[0],
      testType: event.target.value,
    };
    dispatch(
      onTestTypeChange({ updatedProduct: updatedProduct, product: product })
    );
    // setSelectedProducts((prevSelectedProducts) =>
    //   prevSelectedProducts.map((selectedProduct) =>
    //     selectedProduct.Name === product.Name ? updatedProduct : selectedProduct
    //   )
    // );
  };

  let overAlltime =
    selectedProducts.length === 0
      ? 0
      : selectedProducts?.reduce(
          (prevValue, currValue) => prevValue + currValue.timer,
          0
        );

  return (
    <Box
      sx={{
        backgroundColor: "#EBECF1",
        minHeight: "90.5vh",
        mt: "0.5%",
        overFlow: "auto",
      }}
    >
      <Collapse in={isIn} timeout={2000}>
        <Box sx={{ width: "100%", marginX: "auto", paddingTop: "25px" }}>
          <Stack
            direction={"row"}
            spacing={5}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <IconButton
              disabled={activeStep === 1 || activeStep === 4}
              disableRipple
              onClick={prevStep}
              sx={{
                fontSize: "54px",
                color: "#000000",
                width: "10%",
                opacity: (activeStep === 1 || activeStep === 4) && 0,
              }}
            >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ color: "#212121", fontWeight: 500, fontSize: "17px" }}
            >
              Create Assessment
            </Typography>
            <Box sx={{ width: "40%" }}>
              <StepperProgress
                // activeStep={activeStep}
                disabled={activeStep === 1}
                // setActiveStep={setActiveStep}
                nextStep={nextStep}
                prevStep={prevStep}
              />
            </Box>
            <Chip
              label={activeStep === 4 ? "Finish" : "Next Step"}
              sx={{
                backgroundColor: "#908DB8",
                color: "white",
                height: "40px",
                "&:hover": { bgcolor: "#908DB8" },
                width: "110px",
                borderRadius: "50px",
                fontWeight: 500,
                opacity: activeStep === 4 && "0",
              }}
              onClick={nextStep}
              disabled={
                selectLevel === 0 ||
                selectedMode === 0 ||
                selectedType === 0 ||
                activeStep === totalSteps ||
                (selectedProducts?.length === 0 && activeStep === 2) ||
                overAlltime / 60 > 60 ||
                (activeStep === 2 && overAlltime / 60 === 0) ||
                nameOfTheTest === "" ||
                CountErrors
              }
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyItems={"center"}
              alignItems={"center"}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#908DB8",
                  border: "1px solid #908DB8",
                  borderRadius: "30px",
                  "&:hover": { border: "1px solid #908DB8" },
                  textTransform: "capitalize",
                }}
                disabled={activeStep === 4}
                onClick={handleTimer}
              >
                {timer ? (
                  <Timer color="inherit" />
                ) : (
                  <TimerOff color="inherit" />
                )}
                {timer ? "Timer" : "No Timer"}
              </Button>
              {activeStep !== 1 && (
                <Typography variant="body2" sx={{ color: "black" }}>
                  {selectedProducts?.length !== 0 &&
                    (selectedProducts?.length === 1
                      ? "1 Library"
                      : `${selectedProducts?.length} Libraries`)}
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>

        <Box
          sx={
            activeStep === 1
              ? {
                  display: "flex",

                  direction: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  alignContent: "center",
                }
              : activeStep === 2
              ? { display: "flex", justifyContent: "center" }
              : { display: "flex" }
          }
        >
          {activeStep === 1 ? (
            <FirstStepForm
              setRegisterUserId={setRegisterUserId}
              navStateVal={navStateVal}
              setNavStateVal={setNavStateVal}
              testTypeId={testTypeId}
              setTestTypeId={setTestTypeId}
              nameOfTheTest={nameOfTheTest}
              setNameOfTheTest={setNameOfTheTest}
              setIsRegister={setIsRegister}
              // complexity={getComplexity}
              // skill={skill}
              // activeStep={activeStep}
              // selectedType={selectedType}
              // selectedMode={selectedMode}
              // setSelectedType={setSelectedType}
              // setSelectedMode={setSelectedMode}
              fetchQuestionType={fetchQuestionType}
              // questionType={questionType}
              // selectLevel={selectLevel}
              // setSelectLevel={setSelectLevel}
            />
          ) : activeStep === 2 ? (
            <SecondStepForm
              categoryData={categoryData}
              // selectedProducts={selectedProducts}
              // onAdd={handleAddProduct}
              // onRemove={handleRemoveProduct}
              activeStep={activeStep}
              setTestCount={handleCountChange}
              overAlltime={overAlltime / 60 > 60}
              checkCustom={
                selectedType === 4
                  ? selectedMode === 1
                    ? selectLevel === 3
                      ? "visible"
                      : "hidden"
                    : "hidden"
                  : "hidden"
              }
              checkType={selectedType === 4 ? "visible" : "hidden"}
              checkCode={
                selectedMode === 1
                  ? selectLevel === 2
                    ? "visible"
                    : "hidden"
                  : "hidden"
              }
              setTestMode={handleTestTypeChange}
              setCategoryData={setCategoryData}
              selectedType={selectedType}
              selectLevel={selectLevel}
              selectedMode={selectedMode}
              CountErrors={CountErrors}
              setCountErrors={setCountErrors}
            />
          ) : activeStep === 3 ? (
            <ThirdStepForm
            //  activeStep={activeStep}
            //  selectedProducts={selectedProducts}
            //  handleRemoveProduct={handleRemoveProduct}
            />
          ) : activeStep === 4 ? (
            <FourthStepForm
              registerUserId={registerUserId}
              navStateVal={navStateVal}
              setNavStateVal={setNavStateVal}
              testTypeId={testTypeId}
              setTestTypeId={setTestTypeId}
              nameOfTheTest={nameOfTheTest}
              isRegister={isRegister}
              selectedProducts={selectedProducts}
              submitData={submitData}
              // questionLink={questionLink || ""}
              // activeStep={activeStep}
            />
          ) : null}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FirstStep;
