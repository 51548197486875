import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Chip,
  Popover,
  Slider,
  TablePagination,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";
// import ReplayIcon from "@mui/icons-material/Replay";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SortIcon from "@mui/icons-material/Sort";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers";
import "dayjs/locale/en-gb";

import { makeStyles } from "@material-ui/core";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import moment from "moment/moment";

import { useNavigate } from "react-router-dom";

import { GetAllMemberDetails } from "../API/GetAllMemberDetails";
import Get_Unregister_results from "../API/Get_Unregister_results";
// import Get_Internal_results from "../API/Get_Internal_results";
import TestResultReportFilter from "../API/TestResultReportFilter";

const newWeekFilter = [
  {
    id: 1,
    label: "Last 1 Week",
    selected: true,
    value: 7,
  },
  {
    id: 2,
    label: "Last 1 Month",
    selected: false,
    value: 30,
  },
  {
    id: 3,
    label: "Last 3 Months",
    selected: false,
    value: 90,
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  headerAlign: "center",
  color: theme.palette.text.secondary,
  fontFamily: "Manrope",
}));

const options = [
  { tag: <VisibilityIcon sx={{ color: "#5ecdf7" }} />, name: "View Result" },
  // { tag: <ReplayIcon sx={{ color: "#3b8855" }} />, name: "Reset Test" },
  // { tag: <PauseCircleIcon sx={{ color: "#ff0000" }} />, name: "Stop Test" },
];

const ITEM_HEIGHT = 48;

const AllReport = ({ isDashboard }) => {
  const [tableData, setTableData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [sliderValue, setSliderValue] = useState(100);
  const [getRowData, setGetRowData] = useState();
  const [ResultMode, setResultMode] = useState({
    register: true,
    unregister: false,
    // internal: false,
  });
  const [weekFilter, setWeekFilter] = useState(newWeekFilter);

  // Filter by Status State
  const [filterStatus, setFilterStatus] = useState({
    selected: false,
    rejected: false,
    hold: false,
  });

  // Filter by Date State
  // const [filterDate, setFilterDate] = useState({
  //   today: false,
  //   yesterday: false,
  //   customDate: false,
  // });
  const [filterDate, setFilterDate] = useState({
    selectedOption: null, // Track the selected option
    customDate: null,
  });

  // Filter by Custom Date State
  // const [customDate, setCustomDate] = useState(null);

  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:1912px)");

  // Popover fuction Start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSecond, setAnchorElSecond] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState([]); // eslint-disable-line

  // Initialize menu states for each row
  const initialMenuStates = tableData.map(() => null);
  const [menuStates, setMenuStates] = React.useState(initialMenuStates);

  const HandleResultMode = (selectedState) => {
    setResultMode((prevResultMode) => {
      const newState = { ...prevResultMode };

      // Set the selected state to true
      newState[selectedState] = true;

      // Set all other states to false
      Object.keys(newState).forEach((state) => {
        if (state !== selectedState) {
          newState[state] = false;
        }
      });

      return newState;
    });
    setPage(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickSecond = (event) => {
    setAnchorElSecond(event.currentTarget);
  };
  const handleClickMenu = (event, index) => {
    const newMenuStates = [...menuStates];
    newMenuStates[index] = event.currentTarget;
    setMenuStates(newMenuStates);
  };

  const handleCloseMenu = (index) => {
    const newMenuStates = [...menuStates];
    newMenuStates[index] = null;
    setMenuStates(newMenuStates);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSliderValue(0);
  };
  const handleCloseSecond = () => {
    setAnchorElSecond(null);
  };

  const open = Boolean(anchorEl);
  const openSecond = Boolean(anchorElSecond);
  const openMenu = Boolean(anchorElMenu);

  const id = open ? "simple-popover" : undefined;
  const idSecond = openSecond ? "simple-popover" : undefined;
  const [page, setPage] = React.useState(
    JSON?.parse(sessionStorage?.getItem("page"))?.page || 0
  );
  // Popover fuction End

  // useEffect(() => {
  //   // Fetch data when component mounts
  //   GetAllMemberDetails()
  //     .then((res) => setMainTableData(res.data))
  //     .catch((err) => console.error(err));
  // }, []);

  useEffect(() => {
    if (ResultMode?.register) {
      GetAllMemberDetails(7)
        .then((res) => {
          setMainTableData(res.data);
        })
        .catch((err) => console.error(err));
      setWeekFilter(newWeekFilter);
    }

    if (ResultMode?.unregister) {
      Get_Unregister_results(7)
        .then((res) => {
          setMainTableData(res.data);
        })
        .catch((err) => console.error(err));
      setWeekFilter(newWeekFilter);
    }
    // if (ResultMode?.internal) {
    //   Get_Internal_results()
    //     .then((res) => setMainTableData(res.data))
    //     .catch((err) => err);
    // }
  }, [ResultMode]);

  useEffect(() => {
    // Update table data when mainTableData changes
    setTableData(mainTableData);
  }, [mainTableData]);

  useEffect(() => {
    sessionStorage.setItem(
      "page",
      JSON.stringify({
        page: page || 0,
      })
    );
  }, [page]);

  // const handleFilterDateOption = (selectedOption) => {
  //   setFilterDate({
  //     selectedOption,
  //     customDate:
  //       selectedOption === "customDate" ? filterDate.customDate : null,
  //   });
  // FilterByDate();

  // if (filterDate.selectedOption !== null) {
  //   FilterByDate();
  // }
  // };

  // Fiter Function Start
  let filteredData = mainTableData;

  const filterData = async () => {
    // Filter by status

    if (filterStatus.selected || filterStatus.rejected || filterStatus.hold) {
      filteredData = filteredData.filter((item) => {
        if (filterStatus.selected && item.status === "Selected") return true;
        if (filterStatus.rejected && item.status === "Rejected") return true;
        if (filterStatus.hold && item.status === "Hold") return true;
        return false;
      });
    }
    // Filter by percentage
    const filteredByPercentage = filteredData.filter((item) => {
      const itemPercentage = item.percentage !== null ? item.percentage : 0;
      return itemPercentage <= sliderValue;
    });

    setTableData(filteredByPercentage);
    return filteredByPercentage;
  };

  const handleFilterButtonClick = () => {
    filterData();
    handleClose();
  };
  const FilterByDate = async (filterType, value) => {
    let userType = ResultMode.register ? "registered" : "unregistered";
    setFilterDate({
      selectedOption: "",
      customDate: filterType === "customDate" ? value : null,
    });
    // Filter by date

    if (filterStatus.selected || filterStatus.rejected || filterStatus.hold) {
      // const tableDataModified = await filterData();

      if (filterType === "today") {
        // filteredData = tableDataModified.filter((item) =>
        //   moment(item.testDate).isSame(moment().format("YYYY-MM-DD"), "day")
        // );
        TestResultReportFilter(userType, moment().format("YYYY-MM-DD"))
          .then((res) => setTableData(res.data))
          .catch((err) => err);
      } else if (filterType === "yesterday") {
        // filteredData = tableDataModified.filter((item) =>
        //   moment(item.testDate).isSame(
        //     moment().subtract(1, "day").format("YYYY-MM-DD"),
        //     "day"
        //   )
        // );

        TestResultReportFilter(
          userType,
          moment().subtract(1, "day").format("YYYY-MM-DD")
        )
          .then((res) => setTableData(res.data))
          .catch((err) => err);
      } else if (filterType === "customDate" && value) {
        // filteredData = tableDataModified.filter((item) => {
        //   return moment(item.testDate).isSame(
        //     value.format("YYYY-MM-DD"),
        //     "day"
        //   );
        // });
        TestResultReportFilter(userType, value.format("YYYY-MM-DD"))
          .then((res) => setTableData(res.data))
          .catch((err) => err);
      }
      // }

      setTableData(filteredData);
    } else {
      if (filterType === "today") {
        TestResultReportFilter(userType, moment().format("YYYY-MM-DD"))
          .then((res) => setTableData(res.data))
          .catch((err) => err);
      } else if (filterType === "yesterday") {
        TestResultReportFilter(
          userType,
          moment().subtract(1, "day").format("YYYY-MM-DD")
        )
          .then((res) => setTableData(res.data))
          .catch((err) => err);
      } else if (filterType === "customDate" && value) {
        TestResultReportFilter(userType, value.format("YYYY-MM-DD"))
          .then((res) => setTableData(res.data))
          .catch((err) => err);
      }
      // setTableData(filteredData);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    // filterDataByPercentage(newValue);
  };

  const useStyles = makeStyles({
    tableRow: {
      height: "10px",
      padding: "0px",
      fontFamily: "Manrope",
    },
    tableCell: {
      padding: isDashboard ? "1px !important" : "8px !important",
      fontSize: isDashboard && "12px",
      fontFamily: "Manrope",
    },
  });

  const classes = useStyles();

  const handleSearchName = (e) => {
    const value = e.target.value;
    if (value && value.length >= 1) {
      const _searchData = mainTableData.filter(
        (item) =>
          item.username &&
          item.username
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
      );
      setTableData(_searchData);
    } else {
      setTableData(mainTableData);
    }
  };

  const handleGetRowData = (rowdata) => {
    setGetRowData(rowdata);
  };

  const handleRowClickEvent = () => {
    navigate(`/CandidateAssesmentReport`, {
      state: { rowData: getRowData },
    });
  };

  // const getRowId = useMemo(() => {
  //   return (params) => params.id;
  // }, []);

  const menuIconItem = // eslint-disable-line
    (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openMenu ? "long-menu" : undefined}
          aria-expanded={openMenu ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClickMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={handleRowClickEvent}
            >
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  fontFamily: "Manrope",
                }}
              >
                <Typography sx={{ mr: 2, fontFamily: "Manrope" }}>
                  {option.tag}
                </Typography>
                <Typography sx={{ fontFamily: "Manrope" }}>
                  {option.name}
                </Typography>
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );

  //Popover code

  const marks = [
    { value: 0, label: "0" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
    { value: 60, label: "60" },
    { value: 70, label: "70" },
    { value: 80, label: "80" },
    { value: 90, label: "90" },
    { value: 100, label: "100" },
  ];

  const filterMenuIconItem = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          paddingY: 4,
          paddingX: 4,
          minHeight: "15vh",
          width: "30vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #ababab", marginBottom: 2.5, pb: 2.5 }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 800,
              px: 1,
              pb: 2,
              fontFamily: "Manrope",
            }}
          >
            Status
          </Typography>
          <Box
            sx={{
              px: 2,
              display: "flex",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <Button
              variant={filterStatus.selected ? "contained" : "outlined"}
              color="success"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
                px: 2,
                fontFamily: "Manrope",
                fontSize: "12px",
              }}
              onClick={() =>
                setFilterStatus({
                  ...filterStatus,
                  selected: !filterStatus.selected,
                })
              }
            >
              Selected
            </Button>
            <Button
              variant={filterStatus.rejected ? "contained" : "outlined"}
              color="error"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
                px: 2,
                fontFamily: "Manrope",
                fontSize: "12px",
              }}
              onClick={() =>
                setFilterStatus({
                  ...filterStatus,
                  rejected: !filterStatus.rejected,
                })
              }
            >
              Rejected
            </Button>
            <Button
              variant={filterStatus.hold ? "contained" : "outlined"}
              color="warning"
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
                px: 3.5,
                fontFamily: "Manrope",
                fontSize: "12px",
              }}
              onClick={() =>
                setFilterStatus({
                  ...filterStatus,
                  hold: !filterStatus.hold,
                })
              }
            >
              Hold
            </Button>
          </Box>
        </Box>

        <Box sx={{ mb: 2.5 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 800,
              px: 1,
              fontFamily: "Manrope",
            }}
          >
            Score Range{" "}
            <span
              style={{
                fontSize: "12px",
                verticalAlign: "center",
                fontWeight: 600,

                color: "#888",
              }}
            >{`(Percentage)`}</span>
          </Typography>
          <Box sx={{ px: 2 }}>
            <Slider
              aria-label="score"
              // defaultValue={100}
              valueLabelDisplay="auto"
              value={sliderValue}
              onChange={handleSliderChange}
              sx={{ color: "#908DB8", fontFamily: "Manrope", fontSize: "5px" }}
              step={10}
              marks={marks}
              min={0}
              max={100}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "rgba(209, 0, 0, 1)",
              border: "1px solid rgba(209, 0, 0, 1)",
              "&:hover": { border: "1px solid rgba(209, 0, 0, 1)" },
              fontFamily: "Manrope",
            }}
            size="small"
            onClick={handleClose}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleFilterButtonClick}
            sx={{
              backgroundColor: "#908DB8",
              "&:hover": { backgroundColor: "#908DB8" },
              fontFamily: "Manrope",
            }}
            size="small"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Popover>
  );

  const filterDateIconItem = (
    <Popover
      id={idSecond}
      open={openSecond}
      anchorEl={anchorElSecond}
      onClose={handleCloseSecond}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{ mt: "5px" }}
    >
      {(!filterDate.selectedOption || filterData.selectedOption === "") && (
        <Box
          sx={{
            // p: 2,
            minHeight: "12vh",
            width: "7vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            sx={{
              color: "#b2acac",
              border: "none",
              textTransform: "capitalize",
              p: 1.5,
              "&:hover": { border: "none" },
              fontFamily: "Manrope",
              fontSize: "11px",
            }}
            size="small"
            variant={
              filterDate.selectedOption === "today" ? "contained" : "outlined"
            }
            onClick={() => {
              FilterByDate("today");
              handleCloseSecond();
            }}
          >
            Today
          </Button>
          <Button
            sx={{
              color: "#b2acac",
              border: "none",
              textTransform: "capitalize",
              p: 1.5,
              "&:hover": { border: "none" },
              fontFamily: "Manrope",
              fontSize: "11px",
            }}
            size="small"
            variant={
              filterDate.selectedOption === "yesterday"
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              FilterByDate("yesterday");
              handleCloseSecond();
            }}
          >
            Yesterday
          </Button>
          <Button
            sx={{
              color: "#b2acac",
              border: "none",
              textTransform: "capitalize",
              p: 1.5,
              "&:hover": { border: "none" },
              fontFamily: "Manrope",
              fontSize: "11px",
            }}
            size="small"
            variant={
              filterDate.selectedOption === "customDate"
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              setFilterDate({ ...filterDate, selectedOption: "customDate" });
              // handleCloseSecond();
            }}
          >
            Custom
          </Button>
          <Button
            sx={{
              color: "#b2acac",
              border: "none",
              textTransform: "capitalize",
              p: 1.5,
              "&:hover": { border: "none" },
              fontFamily: "Manrope",
              fontSize: "11px",
            }}
            size="small"
            // variant={
            //   filterDate.selectedOption === "customDate"
            //     ? "contained"
            //     : "outlined"
            // }
            onClick={() => {
              setFilterDate({ ...filterDate, selectedOption: null });
              handleCloseSecond();
              filterData();
              handleWeekFilter(1);
            }}
          >
            Reset
          </Button>
        </Box>
      )}
      {filterDate.selectedOption === "customDate" && (
        <Box
          sx={{
            // p: 2,
            minHeight: "12vh",
            width: isDashboard && matches ? "17vw" : matches ? "17vw" : "21vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            {/* <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Custom Date"
                  value={customDate}
                  onChange={(date) => setCustomDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </DemoContainer> */}

            <DemoContainer components={["StaticDatePicker"]}>
              <StaticDatePicker
                label="Custom Date"
                defaultValue={moment()}
                value={filterDate.customDate}
                onChange={(date) => {
                  // await filterData();
                  // setFilterDate({ ...filterDate, customDate: date });
                  handleCloseSecond();
                  FilterByDate("customDate", date);
                }}
                componentsProps={{ actionBar: { actions: [] } }}
                renderInput={(params) => <TextField {...params} />}
              />
            </DemoContainer>
          </LocalizationProvider>
          {filterDate.selectedOption === "customDate" ? (
            <Button
              sx={{
                color: "#b2acac",
                border: "none",
                textTransform: "capitalize",
                p: 1,
                "&:hover": { border: "none" },
              }}
              size="small"
              // variant={
              //   filterDate.selectedOption === "customDate"
              //     ? "contained"
              //     : "outlined"
              // }
              onClick={() => {
                setFilterDate({ ...filterDate, selectedOption: null });
                filterData();
                handleWeekFilter(1);
              }}
            >
              Reset
            </Button>
          ) : (
            ""
          )}
        </Box>
      )}
      {/* </Box> */}
    </Popover>
  );

  const columns = [
    {
      field: "username",
      headerName: "NAME",
    },
    {
      field: "testDate",
      headerName: "DATE",
    },
    {
      field: "NameofAssessment",
      headerName: "ASSESSMENT NAME / COMPLEXITY",
    },
    {
      field: "progress",
      headerName: "PROGRESS",
    },
    {
      field: "status",
      headerName: "STATUS",
    },
    {
      field: "score",
      headerName: "SCORE",
    },
    {
      field: "ACTION",
      headerName: "ACTION",
    },
  ];

  const SearchInput = (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        fontFamily: "Manrope",
      }}
    >
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          fontSize: isDashboard && "12px",
          fontFamily: "Manrope",
        }}
        placeholder="Search Name"
        onChange={handleSearchName}
        inputProps={{ "aria-label": "search name" }}
      />
      <IconButton type="button" sx={{ p: "6px" }} aria-label="search">
        <SearchIcon sx={{ width: "18px" }} />
      </IconButton>
    </Paper>
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(isDashboard ? 3 : 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleWeekFilter(id) {
    const selectedFilter = weekFilter.find((filter) => filter.id === id);

    if (ResultMode?.register) {
      GetAllMemberDetails(selectedFilter.value)
        .then((res) => {
          setMainTableData(res.data);
        })
        .catch((err) => console.error(err));
    }

    if (ResultMode?.unregister) {
      Get_Unregister_results(selectedFilter.value).then((res) => {
        setMainTableData(res.data);
      });
    }

    const updatedFilter = weekFilter.map((filter) => ({
      ...filter,
      selected: filter.id === id,
    }));

    setWeekFilter(updatedFilter);
  }

  return (
    <Box
      sx={{
        backgroundColor: isDashboard ? "#fff" : "#ebecf1",
        width: "100%",
        minHeight: isDashboard ? "20vh" : "91.5svh",

        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: isDashboard ? "auto" : "98%",
          ml: isDashboard ? 0 : 2,
          pl: isDashboard ? 0 : 2,
          display: isDashboard ? "block" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textAlign: "left",
          mb: isDashboard && 1,
        }}
      >
        <Box
          sx={{
            mt: isDashboard ? 0 : "58px",
            ml: isDashboard ? 0 : 2,
            width: isDashboard ? "auto" : "15%",
            height: isDashboard ? 0 : 40,
            display: isDashboard ? "inline-block" : "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            gap: isDashboard ? 0 : 4,
          }}
        >
          {/* {!isDashboard && (
            <IconButton
              type="button"
              // sx={{ mb: "10px" }}
              disableRipple
              aria-label="aerrow back"
            >
              <ArrowBackIcon fontSize="large" sx={{ color: "black" }} />
            </IconButton>
          )} */}
          <Typography
            sx={{
              fontWeight: 800,
              ml: isDashboard ? "0px" : "7px",
              fontSize: isDashboard && "14px",
              color: "#000",
              fontFamily: "Manrope",
            }}
          >
            Assessment Results
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: isDashboard ? "100%" : "98%",
          // mt: 2,
          pt: isDashboard ? 0 : 3,
          ml: isDashboard ? 0 : 2,
          pl: isDashboard ? 0 : 3,
          mr: isDashboard ? 0 : 2,
          pr: isDashboard ? 0 : 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: isDashboard && 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "#888",
              borderRadius: "0px",
              borderBottom: ResultMode?.register ? "3px solid #908DB8" : "none",
              fontSize: "10px",
              fontWeight: 600,
              fontFamily: "Manrope",
            }}
            onClick={() => HandleResultMode("register")}
          >
            REGISTERED
          </Button>
          <Button
            variant="text"
            sx={{
              color: "#888",
              borderRadius: "0px",
              borderBottom: ResultMode?.unregister
                ? "3px solid #908DB8"
                : "none",
              fontSize: "10px",
              fontWeight: 600,
              fontFamily: "Manrope",
            }}
            onClick={() => HandleResultMode("unregister")}
          >
            UNREGISTERED
          </Button>
          {/* <Button
            variant="text"
            sx={{
              color: "#888",
              borderRadius: "0px",
              borderBottom: ResultMode?.internal ? "3px solid #908DB8" : "none",
              fontSize: "10px",
              fontWeight: 600,
              fontFamily: "Manrope",
            }}
            onClick={() => HandleResultMode("internal")}
          >
            INTERNAL
          </Button> */}
        </Box>

        <Box
          sx={{
            // mb: -2,
            // width: "20%",
            height: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Box sx={{ mb: isDashboard ? 0 : 0 }}>{SearchInput}</Box>

          <Button
            aria-describedby={id}
            onClick={handleClick}
            variant="contained"
            sx={{
              color: "#fff",
              backgroundColor: "#908db8",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#908DB8" },
              p: "10 20",
              fontSize: "10px",
              fontWeight: "600",
              fontFamily: "Manrope",
            }}
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
          {filterMenuIconItem}

          <Button
            aria-describedby={idSecond}
            variant="contained"
            onClick={handleClickSecond}
            sx={{
              color: "#fff",
              backgroundColor: "#908db8",
              textTransform: "capitalize",
              // width: 150,
              "&:hover": { backgroundColor: "#908DB8" },
              p: "10 20",
              fontSize: "10px",
              fontWeight: "600",
              fontFamily: "Manrope",
            }}
            startIcon={<SortIcon />}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {filterDate.selectedOption === "today"
              ? "today"
              : filterDate.selectedOption === "yesterday"
              ? "yesterday"
              : filterDate.selectedOption === "customDate"
              ? "custom"
              : "Date"}
          </Button>
          {filterDateIconItem}
        </Box>
      </Box>

      <Stack
        sx={{
          mx: isDashboard ? 0 : 2,
          mt: 0,
          p: isDashboard ? 0 : 3,
          position: "relative",
        }}
      >
        <Item
          sx={{
            borderRadius: "10px",
            boxShadow: isDashboard && "none",
            p: isDashboard && 0,
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <TableContainer
              sx={{
                maxHeight: isDashboard ? 300 : 600,
                "::-webkit-scrollbar-track": {
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                  borderRadius: "10px",
                  backgroundColor: "rgb(179, 177, 177)",
                },

                "::-webkit-scrollbar": {
                  width: "5px",
                  backgroundColor: "#FFFFFF",
                },

                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                  backgroundColor: "rgb(136, 136, 136)",
                },
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          sx={{
                            color: "#333",
                            fontWeight: 800,
                            fontSize: 10,
                            textAlign: "justify",
                            padding: "8px !important",
                            fontFamily: "Manrope",
                          }}
                          key={column.id}
                        >
                          {column.headerName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          className={!isDashboard ? "" : classes.tableRow}
                          key={row.code}
                        >
                          <TableCell
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <div
                              style={{
                                // width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                gap: 20,
                                // justifyContent: "space-between",
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "grey",
                                  width: isDashboard && "30px",
                                  height: isDashboard && "30px",
                                  fontSize: isDashboard && "16px",
                                }}
                                alt={row.username}
                                src="/broken-image.jpg"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: 800,
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    fontFamily: "Manrope",
                                  }}
                                >
                                  {row.username}
                                </div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    color: "#8d8d8d",
                                    fontFamily: "Manrope",
                                  }}
                                >
                                  {row.id <= 9 ? `#0${row.id}` : `#${row.id}`}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <Typography
                              variant="body1"
                              color="initial"
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                              }}
                            >
                              {row.testDate === null
                                ? "-"
                                : row.testDate
                                    ?.split("-")
                                    .reverse()
                                    .join("-")
                                    .replace(/-/g, "/")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: 800,
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                  fontFamily: "Manrope",
                                }}
                              >
                                {row.NameofAssessment}
                              </div>
                              <div
                                style={{
                                  fontSize: "10px",
                                  color: "#8d8d8d",
                                  fontFamily: "Manrope",
                                }}
                              >
                                {row.complexity}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <Chip
                              variant="outlined"
                              sx={{
                                width: "100px",
                                height: isDashboard && "28px",
                                fontSize: "10px !important",
                                fontFamily: "Manrope",
                              }}
                              label={
                                row.completionStatus === null
                                  ? "N/A"
                                  : row?.completionStatus
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <Chip
                              color={
                                row.status === "Rejected"
                                  ? "error"
                                  : row.status === "Selected"
                                  ? "success"
                                  : "warning"
                              }
                              sx={{
                                width: "80px",
                                height: isDashboard && "28px",
                                fontSize: "10px !important",
                                fontFamily: "Manrope",
                              }}
                              label={
                                row.status === null ? "Pending" : row.status
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <Typography
                              variant="body1"
                              color="initial"
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Manrope",
                                fontWeight: 800,
                              }}
                            >
                              {row.score === 0
                                ? 0
                                : row.score <= 9
                                ? `0${row.score}`
                                : row.score}
                            </Typography>
                          </TableCell>
                          <TableCell
                            onClick={() => handleGetRowData(row)}
                            sx={{ cursor: "pointer" }}
                            className={!isDashboard ? "" : classes.tableCell}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <IconButton
                                  aria-label="more"
                                  id={`long-button-${index}`}
                                  aria-controls={`long-menu-${index}`}
                                  aria-expanded={Boolean(menuStates[index])}
                                  aria-haspopup="true"
                                  onClick={(event) =>
                                    handleClickMenu(event, index)
                                  }
                                >
                                  <MoreVertIcon fontSize="12px" />
                                </IconButton>
                                <Menu
                                  id={`long-menu-${index}`}
                                  MenuListProps={{
                                    "aria-labelledby": `long-button-${index}`,
                                  }}
                                  anchorEl={menuStates[index]}
                                  open={Boolean(menuStates[index])}
                                  onClose={() => handleCloseMenu(index)}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: "20ch",
                                    },
                                  }}
                                >
                                  {options.map((option) => (
                                    <MenuItem
                                      key={option}
                                      selected={option === "Pyxis"}
                                      onClick={handleRowClickEvent}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography sx={{ mr: 2 }}>
                                          {option.tag}
                                        </Typography>
                                        <Typography>{option.name}</Typography>
                                      </Box>
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </div>
                              {/* <MoreVertIcon /> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {tableData.length === 0 && (
                <Typography sx={{ mt: 10, mb: 5 }} variant="h5">
                  No Data
                </Typography>
              )}
            </TableContainer>
            <TablePagination
              sx={{ boxShadow: "0px -1px 0px #e0e0e0" }}
              rowsPerPageOptions={[3, 10, 20]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Item>
        <Box
          sx={{
            position: "absolute",
            bottom: isDashboard ? "12px" : "43px",
            right: isDashboard ? "370px" : "404px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          {/* <Typography variant="body1" color="initial" sx={{ fontSize: "12px" }}>
            Last :
          </Typography> */}
          {weekFilter.map((data) => (
            <Chip
              key={data.id}
              sx={{
                width: "100px",
                height: isDashboard && "28px",
                fontSize: "10px !important",
                fontFamily: "Manrope",
                backgroundColor: data.selected && "#908db8",
                color: data.selected && "#fff",
              }}
              label={data.label}
              onClick={() => handleWeekFilter(data.id)}
            />
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default AllReport;
