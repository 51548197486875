import { axiosInstance } from "./Api";

const GenerateTestLink = (data, reqBody) => {
  return axiosInstance.post(
    `questions/generate-link?assessmentName=${encodeURIComponent(
      reqBody?.nameOfTheAssessment
    )}&complexityId=${encodeURIComponent(
      reqBody?.complexityId
    )}&assessmentTime=${encodeURIComponent(
      reqBody?.assessmentTime
    )}&questionCount=${encodeURIComponent(
      reqBody?.questionCount
    )}&savedAssessment=${encodeURIComponent(reqBody?.savedAssessment)}`,
    data
  );
};

export default GenerateTestLink;
