import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import "./UserTestStartPage.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TimerIcon from "@mui/icons-material/Timer";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useLocation, useNavigate } from "react-router-dom";
import bigLogo from "../../Assets/image-removebg.png";

const UserTestStartPage = () => {
  const [checkedVal, setCheckedVAl] = useState(false);

  const navigation = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const userDetails = location.state.userDetails;
  const resDetail = location.state.resDetail;
  const loginDetail = location.state.loginDetail;
  const handleCheckbox = (event) => {
    setCheckedVAl(event.target.checked);
  };

  const handleAssesmentStart = () => {
    navigation("/usertest", {
      state: {
        codeId: id,
        userDetails: userDetails,
        resDetail: resDetail,
        startTime: new Date(),
        loginDetail: loginDetail,
      },
    });

    sessionStorage.setItem(
      "time",
      JSON.stringify({
        AssessmentTime: resDetail?.AssessmentTime,
      })
    );
  };

  return (
    <Box
      sx={{ minHeight: "100vh", fontFamily: "manrope" }}
      className="container-bg"
    >
      <Typography className="login-box">
        <Typography className="align-center">
          <Typography className="content-box">
            <Typography className="left-content">
              <img
                alt="logos"
                src={bigLogo}
                style={{
                  marginTop: "20px",
                  marginLeft: "14px",
                  // ...(open === false && { display: "none" }),
                }}
                width={"30%"}
              />
              <Typography sx={{ p: 1 }}>
                <Typography
                  sx={{
                    p: 1,
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    fontFamily: "manrope",
                  }}
                  variant="h5"
                >
                  Hello {userDetails?.userName},
                </Typography>
                <Typography sx={{ p: 1, fontFamily: "manrope" }}>
                  Welcome! Take a moment to review the instructions before
                  starting the Assessment.
                  {/* <span
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      fontFamily: "manrope",
                    }}
                  >
                    {resDetail.NameOfAssessment}
                  </span>{" "} */}
                </Typography>
                <Typography
                  sx={{ p: 1, fontWeight: "bold", fontFamily: "manrope" }}
                >
                  Good Luck!
                </Typography>
              </Typography>
              <Typography
                className="time-and-test-row "
                sx={{ mx: 2, display: "flex" }}
              >
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: "24px",
                    fontFamily: "manrope",
                    textAlign: "left",
                  }}
                >
                  <Typography>
                    <EventNoteIcon
                      sx={{ color: "#908db8", height: 50, width: 50 }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "manrope",
                      fontWeight: 500,
                      textAlign: "left",
                      fontSize: "24px",
                      textTransform: "capitalize",
                    }}
                  >
                    Assessment:
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "manrope",
                      fontWeight: 500,
                    }}
                  >
                    {resDetail.NameOfAssessment}
                  </Typography>
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  <Typography>
                    <TimerIcon
                      sx={{ color: "#908db8", height: 50, width: 50 }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "manrope",
                      fontWeight: 500,
                      textAlign: "left",
                      fontSize: "24px",
                      textTransform: "capitalize",
                    }}
                  >
                    Duration:
                  </Typography>
                  <Typography sx={{ fontFamily: "manrope", fontWeight: 500 }}>
                    {resDetail.AssessmentTime} Mins
                  </Typography>
                </Typography>
                {/* <Typography className="align-center">
                  <Typography>
                    <EventNoteIcon
                      sx={{ color: "#908db8", height: 50, width: 50 }}
                    />
                  </Typography>
                  <Typography sx={{ fontFamily: "manrope", fontWeight: 500 }}>
                    {loginDetail?.categoryCount} Test
                  </Typography>
                </Typography> */}
              </Typography>
            </Typography>
            <Typography className="right-content">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  fontFamily: "manrope",
                  mb: 1,
                }}
                className="text-gap"
              >
                Terms and Conditions:
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "manrope",
                  fontWeight: 600,
                  mb: 3,
                }}
                className="text-gap"
              >
                A timer is available to measure the exam duration. <br />
                <br />
                Any attempt to cheat, including the use of unauthorized
                materials, assistance, copying, sharing exam content, or
                impersonation, is strictly prohibited.
                <br />
                <br />
                By proceeding, you agree to comply with all rules and
                regulations governing the exam.
              </Typography>

              <Typography>
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        onChange={handleCheckbox}
                        checked={checkedVal}
                      />
                    }
                    label=<Typography
                      sx={{ fontSize: "14px", fontFamily: "manrope" }}
                    >
                      I have read and I accept the{" "}
                      <span style={{ fontWeight: 700, fontFamily: "manrope" }}>
                        Terms & Conditions
                      </span>
                    </Typography>
                  />
                </FormGroup>
              </Typography>
            </Typography>
          </Typography>
          <Button
            variant="contained"
            disabled={!checkedVal}
            sx={{
              m: 2,
              backgroundColor: "#908db8",
              color: "white",
              height: "40px",
              width: "15%",
            }}
            onClick={handleAssesmentStart}
          >
            Start
          </Button>
        </Typography>
      </Typography>
    </Box>
  );
};

export default UserTestStartPage;
