import { axiosInstance } from "./Api";

const FetchUserDetailsbyAuthentication = (mail, data) => {
  return axiosInstance.get(
    // `assessmentLink/get-user-name?mail=${mail}&link=${data}`
    `assessmentLink/get-user-name?mail=${mail}&link=${encodeURIComponent(data)}`
  );
};

export default FetchUserDetailsbyAuthentication;
