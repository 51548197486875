import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { setAuthToken } from "../../API/Api";
import RefreshTokenApi from "../../API/RefreshTokenApi";
import { useDispatch } from "react-redux";
import { toggleExpiry } from "../../Redux/Stepper";
import LogoutApi from "../../API/LogoutApi";

const ProtectedRoutes = ({ children, notAllowed }) => {
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const loginUserId = auth?.userId;

  //   useEffect(() => {
  //     if (auth) {
  //       if (Object.keys(auth).length !== 0) {
  //         dispatch(AuthToken(JSON.parse(sessionStorage.getItem("auth"))));
  //       }
  //     }
  //   }, [user]);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      console.log("Before unload event triggered");
      if (loginUserId) {
        LogoutApi(loginUserId)
          .then(() => console.log("Logout API called successfully"))
          .catch((err) => console.error("Logout API error:", err));
      } else {
        console.log("No logged-in user found");
      }
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []); // eslint-disable-line

  // console.log(auth?.role === notAllowed, "oath");
  useEffect(() => {
    const refreshToken = setTimeout(() => {
      clearTimeout(refreshToken);
      try {
        if (location.pathname !== "/welcome") {
          RefreshTokenApi()
            .then((res) => {
              setAuthToken(res.data.accessToken);
              dispatch(toggleExpiry(false));
            })
            .catch((e) => {
              setAuthToken(false);
              if (loginUserId) {
                LogoutApi(loginUserId)
                  .then(() => console.log("Logout API called successfully"))
                  .catch((err) => console.error("Logout API error:", err));
              } else {
                console.log("No logged-in user found");
              }
              dispatch(toggleExpiry(true));
            });
        }
      } catch (error) {}
    }, 28740000);
    return () => {
      clearTimeout(refreshToken);
    };
  }, [location]); // eslint-disable-line
  // 28740000;
  useEffect(() => {
    setAuthToken(auth?.token);
  }, [auth?.token]);
  if (auth?.allow === true) {
    if (auth?.role === notAllowed) {
      return (
        <>
          <Navigate to="/createassessment" replace />;
        </>
      );
    } else {
      return <>{children}</>;
    }
  } else {
    return (
      <>
        <Navigate to="/welcome" replace />;
      </>
    );
  }
};

export default ProtectedRoutes;
