import React, { useEffect, useState } from "react";
import {
  LockOutlined,
  PersonOutlineOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { setAuthToken } from "../../API/Api";
import UserLoginApi from "../../API/UserLogin";

import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "../../AzureActiveDirectory/authConfig";

const LoginInput = ({ handleLoginType, handleForgotPasswordType }) => {
  const { instance } = useMsal();

  const [userDetails, setUserDetails] = useState({
    userName: "",
    password: "",
  });

  const [msUserDetails, setMsUserDetails] = useState({
    userName: "",
    msAccessToken: "",
  });
  const [userDetailsError, setUserDetailsError] = useState({
    emailError: false,
    passwordError: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleLoginCreds = (property, value) => {
    setUserDetails({
      ...userDetails,
      [property]: value,
    });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const navigate = useNavigate();

  const handleLoginSubmit = () => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate email
    setUserDetailsError((prevErrors) => ({
      ...prevErrors,
      emailError: !emailRegex.test(userDetails.userName),
    }));

    setUserDetailsError((prevErrors) => ({
      ...prevErrors,
      passwordError: !userDetails.password,
    }));
  };

  useEffect(() => {
    if (!userDetailsError.userNameError && !userDetailsError.passwordError) {
      if (userDetails.userName !== "") {
        callLoginFunc();
      }
    }
  }, [userDetailsError]); // eslint-disable-line

  useEffect(() => {
    const handleLoginSuccess = (event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        setMsUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          msAccessToken: event.payload.accessToken,
          userName: event.payload.account.username,
        }));
      }
    };

    instance.addEventCallback(handleLoginSuccess);

    return () => {
      instance.removeEventCallback(handleLoginSuccess);
    };
  }, [instance]);

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  // MS LOGIN
  useEffect(() => {
    // setTimeout(() => {
    if (msUserDetails.userName !== "" || msUserDetails.msAccessToken !== "") {
      UserLoginApi({
        email: msUserDetails.userName,
        msAccessToken: msUserDetails.msAccessToken,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Login Success!");
            setAuthToken(res.data?.token);
            navigate("/");
            sessionStorage.setItem(
              "auth",
              JSON.stringify({
                allow: true,
                token: res.data?.token,
                name: res.data?.name,
                role: res?.data?.role,
                level: res?.data?.roleLevel,
                userId: res?.data?.id,
                loginType: res?.data?.loginType,
              })
            );
          }
        })
        .catch((error) => {
          console.error("API Error:", error);

          // Display user-friendly error message to the UI
          toast.error(
            error?.response?.data ||
              "An unexpected error occurred. Please try again."
          );

          instance.logoutPopup(loginRequest).catch((e) => {
            console.log(e);
          });
        });
    }
    // }, 5000);
  }, [msUserDetails.msAccessToken, msUserDetails.userName]); // eslint-disable-line

  // NORMAL LOGIN
  const callLoginFunc = () => {
    // If there are no errors, proceed with login
    if (!userDetailsError.userNameError && !userDetailsError.passwordError) {
      UserLoginApi({
        email: userDetails.userName,
        password: userDetails.password,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Login Success!");
            setAuthToken(res.data?.token);
            navigate("/");
            sessionStorage.setItem(
              "auth",
              JSON.stringify({
                allow: true,
                token: res.data?.token,
                name: res.data?.name,
                role: res?.data?.role,
                level: res?.data?.roleLevel,
                userId: res?.data?.id,
                loginType: res?.data?.loginType,
              })
            );
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.status || "Oops, Error 404");
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box>
        <Typography
          variant="p"
          sx={{ fontSize: "24px", fontWeight: 600, color: "#000000DE" }}
        >
          {" "}
          Hello Again
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: "10px",
          width: "25vw",
          height: "55vh",
          bgcolor: "#FFFFFF",
          boxShadow: "0px 8px 53px 0px #908DB840",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          py: 5,
          // boxShadow: " 0px 4px 9px 0px #0000001A",
        }}
      >
        <Box>
          <TextField
            label="Email"
            sx={{ m: 1, width: "18vw" }}
            value={userDetails?.userName}
            error={userDetailsError?.emailError}
            helperText={
              userDetailsError?.emailError ? "Enter Valid Email-Id" : ""
            }
            onChange={(e) => handleLoginCreds("userName", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            label="Password"
            sx={{ m: 1, width: "18vw" }}
            type={showPassword ? "text" : "password"}
            value={userDetails?.password}
            onChange={(e) => handleLoginCreds("password", e.target.value)}
            error={userDetailsError?.passwordError}
            helperText={
              userDetailsError?.passwordError ? "Enter Valid Password" : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button
            sx={{
              width: "18vw",
              margin: "0px auto",
              textTransform: "capitalize",
              color: "#908DB8",
            }}
            onClick={handleForgotPasswordType}
          >
            {" "}
            Forgot Password?
          </Button>
        </Box>
        <Box>
          <Button
            sx={{
              width: "16vw",
              margin: "0px auto",
              bgcolor: "#908DB8",
              ":hover": {
                bgcolor: "#908DB8",
              },
            }}
            variant="contained"
            onClick={handleLoginSubmit}
          >
            {" "}
            LOGIN
          </Button>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button
            sx={{
              mr: 1,
              // width: "18vw",
              // margin: "0px auto",
              textTransform: "capitalize",
              color: "#908DB8",
              // borderRadius: 0,
              // borderRight: "1px solid #908DB8",
            }}
            onClick={handleLoginType}
          >
            {" "}
            Sign up
          </Button>
          <Box
            sx={{
              height: "30px",
              width: "1px",
              backgroundColor: "#908DB8",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          ></Box>
          <Button
            sx={{
              ml: 1,
              // width: "18vw",
              // margin: "0px auto",
              textTransform: "capitalize",
              color: "#908DB8",
            }}
            onClick={() => {
              handleLogin("popup");
            }}
          >
            <MicrosoftIcon sx={{ mr: 1 }} />
            Microsoft LogIn
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginInput;
