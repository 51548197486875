import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SwapHorizSharp,
} from "@material-ui/icons";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddNewQuestionbyTestId from "../../API/AddNewQuestionbyTestId";
import { toast } from "react-toastify";

const NewQuestionCreationModal = ({
  SelectedCardData,
  AddQsnMode,
  setAddQsnMode,
  setRefetch,
}) => {
  const [questionError, setQuestionError] = useState([false]);
  const [optionErrors, setOptionErrors] = useState([
    [false, false, false, false],
  ]);
  const [correctAnswerError, setCorrectAnswerError] = useState([false]); // eslint-disable-line
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  //States
  const [PreviewQsn, setPreviewQsn] = useState([
    {
      creatorName: auth?.name,
      subTopicId: 1,
      questionTypeId: 1,
      complexityId: 1,
      questionsDTOList: [
        {
          userEmail: "null",
          question: "",
          remark: "null",
          option: [
            {
              optionContent: "",
            },
            {
              optionContent: "",
            },
            {
              optionContent: "",
            },
            {
              optionContent: "",
            },
          ],
          correctAnswer: "nil",
        },
      ],
    },
  ]);

  useEffect(() => {
    // Update subTopicId in  state when the prop changes
    setPreviewQsn((prevPreviewQsn) => {
      return prevPreviewQsn.map((item) => {
        return {
          ...item,
          subTopicId: SelectedCardData,
        };
      });
    });
  }, [SelectedCardData]);

  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [toggleComplexity, setToggleComplexity] = useState(1); // eslint-disable-line
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0);

  //Functions
  const handleToggleComplexity = (index, complexityId) => {
    let count = complexityId + 1;
    if (count === 4) {
      setToggleComplexity(1);
      setPreviewQsn((prevPreviewQsn) => {
        const updatedPreviewQsn = [...prevPreviewQsn];
        updatedPreviewQsn[index].complexityId = 1;
        return updatedPreviewQsn;
      });
    } else {
      setToggleComplexity(count);
      setPreviewQsn((prevPreviewQsn) => {
        const updatedPreviewQsn = [...prevPreviewQsn];
        updatedPreviewQsn[index].complexityId = count;
        return updatedPreviewQsn;
      });
    }
  };
  const SuccessFunc = () => {
    setToggleComplexity(1);
    setRefetch((prev) => prev + 1);
    setCurrQuestionIndex(0);
    setPreviewQsn([
      {
        creatorName: "string",
        subTopicId: SelectedCardData,
        questionTypeId: 1,
        complexityId: 1,
        questionsDTOList: [
          {
            userEmail: "null",
            question: "",
            remark: "null",
            option: [
              {
                optionContent: "",
              },
              {
                optionContent: "",
              },
              {
                optionContent: "",
              },
              {
                optionContent: "",
              },
            ],
            correctAnswer: "nil",
          },
        ],
      },
    ]);
    setAddQsnMode({
      ...AddQsnMode,
      status: false,
    });
  };
  const handleSaveQuestion = () => {
    // Perform validation checks for each question
    const newQuestionErrors = PreviewQsn.map(
      (question) => !question?.questionsDTOList[0]?.question
    );
    setQuestionError(newQuestionErrors);

    const newOptionErrors = PreviewQsn.map((question) =>
      question?.questionsDTOList[0]?.option.map(
        (option) => option?.optionContent.trim() === ""
      )
    );
    setOptionErrors(newOptionErrors);

    const newCorrectAnswerErrors = PreviewQsn.map(
      (question) => question?.questionsDTOList[0]?.correctAnswer === "nil"
    );
    setCorrectAnswerError(newCorrectAnswerErrors);

    // Check if any errors exist for any question
    if (
      newQuestionErrors.some((error) => error) ||
      newOptionErrors.some((questionErrors) =>
        questionErrors.some((error) => error)
      ) ||
      newCorrectAnswerErrors.some((error) => error)
    ) {
      // Show an error message or handle the validation error as needed
      console.error("Validation error: All fields must be filled");
      return;
    }

    // Assuming `AddNewQuestionbyTestId` supports an array of questions
    AddNewQuestionbyTestId(PreviewQsn)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Questions Added Successfully");
          SuccessFunc();
        }
      })
      .catch((err) => {
        toast.error("Uh-Oh, Try Again!");
      });
  };

  const handleQuestionChange = (newValue, index) => {
    setPreviewQsn((prevPreviewQsn) => {
      const updatedPreviewQsn = [...prevPreviewQsn];
      updatedPreviewQsn[index].questionsDTOList[0].question = newValue;
      return updatedPreviewQsn;
    });
    setQuestionError(false);
  };
  const [usedOptions, setUsedOptions] = useState([]); // eslint-disable-line

  // Function to generate a unique key for each option
  // eslint-disable-next-line
  const generateOptionKey = (option) => {
    return option?.optionContent + option?.optionId;
  };
  const handleOptionEditChange = (optionIndex, newValue, index) => {
    setPreviewQsn((prevPreviewQsn) => {
      const updatedPreviewQsn = [...prevPreviewQsn];
      const currentQuestion = updatedPreviewQsn[index].questionsDTOList[0];

      const isValueRepeated =
        newValue !== "" &&
        currentQuestion.option.some(
          (option, i) => i !== optionIndex && option.optionContent === newValue
        );

      if (isValueRepeated) {
        // Handle repetition (e.g., show an error, prevent update, etc.)
        setOptionErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[index][optionIndex] = true;
          return newErrors;
        });
        console.error("Option value is repeated in other options");
        return prevPreviewQsn;
      }

      currentQuestion.option[optionIndex].optionContent = newValue;

      if (correctAnswer === optionIndex) {
        currentQuestion.correctAnswer = newValue;
      }

      // Update the state of optionErrors based on the new value
      setOptionErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index][optionIndex] = newValue === "";
        return newErrors;
      });

      return updatedPreviewQsn;
    });
  };

  const handleCorrectAnswerChange = (optionIndex, newValue, index) => {
    setCorrectAnswer(optionIndex);
    setPreviewQsn((prevPreviewQsn) => {
      const updatedPreviewQsn = [...prevPreviewQsn];
      updatedPreviewQsn[index].questionsDTOList[0].correctAnswer = newValue;
      return updatedPreviewQsn;
    });
    setCorrectAnswerError(false);
  };

  const handleAdd = () => {
    setPreviewQsn([
      ...PreviewQsn,
      {
        creatorName: auth?.name,
        subTopicId: 1,
        questionTypeId: 1,
        complexityId: 1,
        questionsDTOList: [
          {
            userEmail: "null",
            question: "",
            remark: "null",
            option: [
              {
                optionContent: "",
              },
              {
                optionContent: "",
              },
              {
                optionContent: "",
              },
              {
                optionContent: "",
              },
            ],
            correctAnswer: "nil",
          },
        ],
      },
    ]);
    setOptionErrors([...optionErrors, [false, false, false, false]]);
    setCurrQuestionIndex(PreviewQsn?.length);
  };

  // eslint-disable-next-line
  const handleRemove = () => {};
  return (
    <>
      <Modal
        open={AddQsnMode?.status}
        onClose={() => {
          //   setEditState(null);
          //   setIsEditMode(false);
          //   setCurrQuestion({
          //     count: 0,
          //   });
          //   setToggleComplexity(1);
          //   setPreviewQsn([]);
          //   handleTestPreviewModal("closeMode");
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              bgcolor: "#F7F7F7",
              color: "#908DB8",
              width: "100%",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            ></Toolbar>
          </AppBar>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              bgcolor: "#EBECF1",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                sx={{ color: "#908DB8", fontSize: "35px", alignSelf: "center" }}
                disabled={currQuestionIndex === 0}
                onClick={() => setCurrQuestionIndex(currQuestionIndex - 1)}
              >
                <ArrowLeftOutlined color="inherit" fontSize="inherit" />
              </IconButton>
              {PreviewQsn?.map((data, QuestionIndex) => {
                return (
                  <Card
                    sx={{
                      display:
                        currQuestionIndex === QuestionIndex ? "block" : "none",
                      minHeight: "65%",
                      width: "70%",
                      alignSelf: "center",
                      marginTop: "60px",
                      fontSize: "10px",
                      borderRadius: "15px",
                      boxShadow: "5px 4px 10px 1px #00000040",
                    }}
                  >
                    <CardContent
                      sx={{
                        height: "100%",
                        width: "100%",
                        px: 8,
                        py: 3,
                      }}
                    >
                      <Box
                        component={Stack}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 600, fontSize: "18px", pt: 2 }}
                        >
                          Question{" "}
                          {AddQsnMode?.qsnCount + 1 + currQuestionIndex}
                        </Typography>

                        {/* <IconButton onClick={null}>
                    <AddCircleOutlineOutlined />
                  </IconButton> */}
                      </Box>
                      <Stack
                        direction="row"
                        spacing={10}
                        sx={{ marginTop: "1%" }}
                      >
                        <Box sx={{ flex: "50%" }}>
                          {/* <Typography>{questions[currQuestion]?.question}</Typography> */}

                          <TextField
                            id="outlined-multiline-flexible"
                            variant="outlined"
                            multiline
                            fullWidth
                            minRows={4}
                            maxRows={10}
                            value={data?.questionsDTOList?.[0]?.question}
                            placeholder="Type your new question"
                            onChange={(e) =>
                              handleQuestionChange(
                                e.target.value,
                                QuestionIndex
                              )
                            }
                            error={questionError[QuestionIndex]}
                            helperText={
                              questionError[QuestionIndex]
                                ? "Enter your Question"
                                : ""
                            }
                          />
                        </Box>
                        <Box sx={{ flex: "50%" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 300,
                              textTransform: "uppercase",
                              fontSize: "16px",
                              pb: 1,
                            }}
                          >
                            Select only one
                          </Typography>
                          <Stack>
                            <Box sx={{ mb: "6%", width: "100%" }}>
                              {data?.questionsDTOList?.[0]?.option?.map(
                                (option, optionIndex) => {
                                  return (
                                    <TextField
                                      size="small"
                                      sx={{
                                        mb: "5%",
                                        // border: "2px solid lightgrey",
                                      }}
                                      error={
                                        optionErrors[QuestionIndex][optionIndex]
                                      }
                                      placeholder="Enter your options"
                                      fullWidth
                                      onChange={(e) =>
                                        handleOptionEditChange(
                                          optionIndex,
                                          e.target.value,
                                          QuestionIndex
                                        )
                                      }
                                      // value={option?.optionContent}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <Radio
                                              checked={
                                                option?.optionContent === ""
                                                  ? false
                                                  : option?.optionContent ===
                                                    data?.questionsDTOList?.[0]
                                                      ?.correctAnswer
                                              }
                                              onChange={(e) =>
                                                handleCorrectAnswerChange(
                                                  optionIndex,
                                                  option?.optionContent,
                                                  QuestionIndex
                                                )
                                              }
                                              sx={{ ml: "-8.5px" }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      variant="outlined"
                                    />
                                  );
                                }
                              )}{" "}
                            </Box>
                          </Stack>
                        </Box>
                      </Stack>

                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                      >
                        <Typography
                          variant="body2"
                          paddingX={1}
                          paddingY={1}
                          sx={{
                            bgcolor: "#FDF2D6",
                            textAlign: "center",
                            fontWeight: 500,
                            fontSize: "11px",
                            minWidth: "45%",
                            mr: 2,
                            alignSelf: "center",
                            textTransform: "capitalize",
                            color: "#99834B",
                          }}
                        >
                          {" "}
                          Please choose the appropriate level of complexity for
                          your question creation
                        </Typography>
                        <Typography
                          alignSelf={"center"}
                          sx={{ fontWeight: 600, color: "#212121" }}
                          variant="body2"
                        >
                          {" "}
                          {data?.complexityId === 1
                            ? "Easy"
                            : data?.complexityId === 2
                            ? "Medium"
                            : data?.complexityId === 3
                            ? "Hard"
                            : ""}
                        </Typography>
                        <IconButton
                          style={{ color: "#46A997" }}
                          onClick={() =>
                            handleToggleComplexity(
                              QuestionIndex,
                              data?.complexityId
                            )
                          }
                        >
                          <SwapHorizSharp fontSize="large" />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
              <IconButton
                sx={{ color: "#908DB8", fontSize: "35px", alignSelf: "center" }}
                disabled={currQuestionIndex === PreviewQsn?.length - 1}
                onClick={() => setCurrQuestionIndex(currQuestionIndex + 1)}
              >
                <ArrowRightOutlined color="inherit" fontSize="inherit" />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "70%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                mt: "2%",
                gap: 2,
                backgroundColor:
                  PreviewQsn?.length === 5 ? "#ccc" : "#908DB933",
                border: "1px solid #908DB833",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  height: "50px",
                  width: "100%",
                  textAlign: "center",
                  alignSelf: "center",
                  display: "flex", // Add display: flex
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  pointerEvents: PreviewQsn?.length === 5 ? "none" : "all",
                }}
                onClick={handleAdd}
              >
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: PreviewQsn?.length === 5 ? "#888" : "#908DB8",
                    fontFamily: "manrope",
                    fontWeight: 800,
                  }}
                >
                  {" "}
                  + Add Question
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "70%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: "2%",
                gap: 2,
              }}
            >
              {/* <IconButton style={{ alignSelf: "center", color: "#F90F0F" }}>
                <Delete />
                <Typography color="#F90F0F" sx={{ fontWeight: 600 }}>
                  Delete
                </Typography>
              </IconButton> */}
              <Button
                variant="outlined"
                sx={{
                  color: "rgba(209, 0, 0, 1)",
                  border: "1px solid rgba(209, 0, 0, 1)",
                  "&:hover": { border: "1px solid rgba(209, 0, 0, 1)" },
                  textTransform: "capitalize",
                  width: "90px",
                  mr: 5,
                  ml: "auto",
                  alignSelf: "center",
                }}
                onClick={() => {
                  setToggleComplexity(1);
                  setCurrQuestionIndex(0);
                  setQuestionError([false]);
                  setOptionErrors([[false, false, false, false]]);
                  setCorrectAnswerError([false]);
                  setPreviewQsn([
                    {
                      creatorName: auth?.name,
                      subTopicId: SelectedCardData,
                      questionTypeId: 1,
                      complexityId: 1,
                      questionsDTOList: [
                        {
                          userEmail: "null",
                          question: "",
                          remark: "null",
                          option: [
                            {
                              optionContent: "",
                            },
                            {
                              optionContent: "",
                            },
                            {
                              optionContent: "",
                            },
                            {
                              optionContent: "",
                            },
                          ],
                          correctAnswer: "nil",
                        },
                      ],
                    },
                  ]);
                  setAddQsnMode({
                    ...AddQsnMode,
                    status: false,
                  });
                }}
              >
                Exit
              </Button>
              <Button
                variant="contained"
                // disabled={submitResult && submitScore}
                sx={{
                  backgroundColor: "#908DB8",
                  "&:hover": { backgroundColor: "#908DB8" },
                  textTransform: "capitalize",
                  width: "90px",
                  alignSelf: "center",
                }}
                onClick={handleSaveQuestion}
                // disabled={CurrQuestion.count === 0}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NewQuestionCreationModal;
