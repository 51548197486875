import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import GetSkillCategory from "../../API/GetSkillCategory";
import GetAllComplexityType from "../../API/GetAllComplexityType";
import GetAllTestType from "../../API/GetAllTestType";
import FindQuestionTypebySkillId from "../../API/FindQuestionTypebySkillId";
import { CloseOutlined, CloudUploadOutlined } from "@material-ui/icons";
import DownloadIcon from "@mui/icons-material/Download";
import UploadNewTestLibrary from "../../API/UploadNewTestLibrary";

// import GetAllTopicSubCategory from "../../API/GetAllTopicSubCategory";
import { Badge, Chip } from "@mui/joy";
import CheckExistingTestLibraryName from "../../API/CheckExistingTestLibraryName";
import CheckSkillTypebyIdforExistingTest from "../../API/CheckSkillTypebyIdforExistingTest";
import UpdateExistingTestLibrary from "../../API/UpdateExistingTestLibrary";
import { toast } from "react-toastify";
// import DownloadTemplatebyId from "../../API/DownloadTemplatebyId";
import GetTopicSubCategoryWithoutSkillid from "../../API/GetTopicSubCategoryWithoutSkillid";

const UploadQuestionModal = ({ handleClose, setRefetch }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [AllTopicSubCategory, setTopicSubcategory] = useState([]);
  const [SkillCategory, setSkillCategory] = useState([]);
  const [complexityType, setComplexityType] = useState([]);
  const [TestType, setTestType] = useState([]);
  const [QuestionType, setQuestionType] = useState([]);
  const [TestTypeValue, setTestTypeValue] = useState(0);

  const [uploadLibraryType, setUploadLibraryType] = useState({
    newLibrary: true,
    existingLibrary: false,
  });

  const [isLoading, setIsloading] = useState(false);

  const [NewTestLibraryForm, setNewTestLibraryForm] = useState({
    subCategoryName: "",
    categoryId: 0,
    categoryName: "",
    testTypeId: 0,
    testTypeName: "",
    testModeId: 0,
    testModeName: "",
    testModeTypeName: "",
    testModeTypeId: 0,
    uploadFiles: new FormData(),
  });

  const [ExistingLibraryForm, setExistingLibraryForm] = useState({
    categoryId: 0,
    categoryName: "",
    testTypeId: 0,
    testTypeName: "",
    testModeId: 0,
    testModeName: "",
    testModeTypeName: "",
    testModeTypeId: 0,
    uploadFiles: new FormData(),
  });

  const [NewFormErrors, setNewFormsError] = useState({
    categoryNameError: false,
    categoryNameErrorMSG: null,
    categoryDropdownError: false,
    categoryDropdownMSG: null,
    testTypeError: false,
    testTypeErrorMSG: null,
    testModeError: false,
    testModeErrorMSG: null,
    testModeTypeError: false,
    testModeTypeErrorMSG: null,
    uploadFilesError: false,
    uploadFilesErrorMSG: null,
  });
  const [ExistingFormErrors, setExistingFormsError] = useState({
    categoryDropdownError: false,
    categoryDropdownMSG: null,
    testTypeError: false,
    testTypeErrorMSG: null,
    // testModeError: false,
    // testModeErrorMSG: null,
    uploadFilesError: false,
    uploadFilesErrorMSG: null,
  });

  const inputUploadRef = useRef(null);

  useEffect(() => {
    GetTopicSubCategoryWithoutSkillid({
      SkillCategoryId: 0,
      questionTypeId: 0,
    })
      .then((response) => setTopicSubcategory(response.data))
      .catch((e) => e);
  }, [uploadLibraryType.existingLibrary]);

  useEffect(() => {
    if (ExistingLibraryForm.categoryName !== "") {
      CheckSkillTypebyIdforExistingTest(ExistingLibraryForm.categoryId)
        .then((response) => {
          if (response?.data?.questionTypes?.length !== 0)
            if (response?.data?.questionTypes?.length === 0) {
              setExistingLibraryForm({
                ...ExistingLibraryForm,
                testModeId: response?.data?.skillId,
                testModeName: response?.data?.skillName,
                testModeTypeId: 0,
                testModeTypeName: "",
              });
            } else {
              setExistingLibraryForm({
                ...ExistingLibraryForm,
                testModeId: response?.data?.skillId,
                testModeName: response?.data?.skillName,
                testModeTypeId:
                  response?.data?.questionTypes[0]["questionTypeId"],
                testModeTypeName:
                  response?.data?.questionTypes[0]["questionType"],
              });
            }
        })
        .catch((e) => e);
    }
  }, [ExistingLibraryForm.categoryName]); // eslint-disable-line

  // eslint-disable-next-line
  const [uploadingStatus, setUploadingStatus] = useState({
    isLoading: false,
  });

  useEffect(() => {
    if (NewTestLibraryForm.subCategoryName !== "") {
      if (!NewFormErrors?.categoryNameError)
        setUploadingStatus({
          isLoading: true,
        });
      CheckExistingTestLibraryName(NewTestLibraryForm)
        .then((response) => {
          setUploadingStatus({
            isLoading: false,
          });
          if (
            response.data.status ===
            "Specific questionType Already Presented in this topicName"
          ) {
            setNewFormsError({
              ...NewFormErrors,
              categoryNameError: true,
              categoryNameErrorMSG: "Name Already Exists",
            });
          }
          if (response.data?.status === "OK") {
            setNewFormsError({
              ...NewFormErrors,
              categoryNameError: false,
              categoryNameErrorMSG: null,
            });
          }
        })
        .catch((err) => {
          setUploadingStatus({
            isLoading: false,
          });
          setNewFormsError({
            ...NewFormErrors,
            categoryNameError: true,
            categoryNameErrorMSG: "Name Already Exists",
          });
        });
    }
  }, [NewTestLibraryForm]); // eslint-disable-line

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;

    const allowedExcelExtensions = [".xlsx", ".xls"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (!allowedExcelExtensions.includes(`.${fileExtension}`)) {
      toast.error(`Unsupported Format`);

      // Perform your desired actions for non-Excel files
      return;
    }
    const formData = new FormData();
    formData.append("file", files[0]);

    setNewTestLibraryForm({
      ...NewTestLibraryForm,
      uploadFiles: formData,
    });
  };

  useEffect(() => {
    if (SkillCategory?.length === 0) {
      GetSkillCategory()
        .then((response) => setSkillCategory(response?.data))
        .catch((e) => e);
    }

    if (complexityType?.length === 0) {
      GetAllComplexityType()
        .then((response) => setComplexityType(response?.data))
        .catch((e) => e);
    }

    if (TestType?.length === 0) {
      GetAllTestType()
        .then((response) => setTestType(response?.data))
        .catch((e) => e);
    }

    if (TestTypeValue !== 0) {
      FindQuestionTypebySkillId(TestTypeValue)
        .then((response) => setQuestionType(response?.data))
        .catch((e) => e);
    }
  }, [TestTypeValue, setTestTypeValue]); // eslint-disable-line

  const handleSubCategoryName = (e) => {
    setNewTestLibraryForm({
      ...NewTestLibraryForm,
      subCategoryName: e.target.value,
    });

    setNewFormsError((prevErrors) => ({
      ...prevErrors,
      categoryNameError: !e.target.value,
      categoryNameErrorMSG: e.target.value ? null : "Category name is required",
    }));
  };
  const handleFilterChange = (option) => {
    setNewTestLibraryForm({
      ...NewTestLibraryForm,
      categoryId: option.id,
      categoryName: option.skillCategoryName,
    });
  };

  const handleExistingFilterChange = (option) => {
    setExistingLibraryForm({
      ...ExistingLibraryForm,
      categoryId: option.Id,
      categoryName: option.Name,
    });
  };

  const handleLibraryType = () => {
    setUploadLibraryType({
      newLibrary: !uploadLibraryType.newLibrary,
      existingLibrary: !uploadLibraryType.existingLibrary,
    });
    setNewTestLibraryForm({
      subCategoryName: "",
      categoryId: 0,
      categoryName: "",
      testTypeId: 0,
      testTypeName: "",
      testModeId: 0,
      testModeName: "",
      testModeTypeName: "",
      testModeTypeId: 0,
      uploadFiles: new FormData(),
    });
    setNewFormsError({
      categoryNameError: false,
      categoryNameErrorMSG: null,
      categoryDropdownError: false,
      categoryDropdownMSG: null,
      testTypeError: false,
      testTypeErrorMSG: null,
      testModeError: false,
      testModeErrorMSG: null,
      testModeTypeError: false,
      testModeTypeErrorMSG: null,
      uploadFilesError: false,
      uploadFilesErrorMSG: null,
    });
    setExistingLibraryForm({
      categoryId: 0,
      categoryName: "",
      testTypeId: 0,
      testTypeName: "",
      testModeId: 0,
      testModeName: "",
      testModeTypeName: "",
      testModeTypeId: 0,
      uploadFiles: new FormData(),
    });
    setExistingFormsError({
      categoryDropdownError: false,
      categoryDropdownMSG: null,
      testTypeError: false,
      testTypeErrorMSG: null,
      // testModeError: false,
      // testModeErrorMSG: null,
      uploadFilesError: false,
      uploadFilesErrorMSG: null,
    });
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleTestType = (el) => {
    setNewTestLibraryForm({
      ...NewTestLibraryForm,
      testTypeId: el.id,
      testTypeName: el.complexityType,
    });
  };

  const handleExistingTestType = (el) => {
    setExistingLibraryForm({
      ...ExistingLibraryForm,
      testTypeId: el.id,
      testTypeName: el.complexityType,
    });
  };
  const handleTestMode = (el) => {
    setTestTypeValue(el.id);
    setNewTestLibraryForm({
      ...NewTestLibraryForm,
      testModeId: el.id,
      testModeName: el.skillName,
    });
  };
  const handleTestModeType = (el) => {
    setNewTestLibraryForm({
      ...NewTestLibraryForm,
      testModeTypeId: el.id,
      testModeTypeName: el.questionType,
    });
  };

  const validateForm = () => {
    let isValidForm = true;

    // Validate subCategoryName
    if (!NewTestLibraryForm.subCategoryName.trim()) {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        categoryNameError: true,
        categoryNameErrorMSG: "Category name is required",
      }));
      isValidForm = false;
    } else {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        categoryNameError: false,
        categoryNameErrorMSG: null,
      }));
    }

    // Validate other fields and update error state accordingly
    // ...

    // Example: Validate categoryId
    if (NewTestLibraryForm.categoryId === 0) {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        categoryDropdownError: true,
        categoryDropdownMSG: "Please select a category",
      }));
      isValidForm = false;
    } else {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        categoryDropdownError: false,
        categoryDropdownMSG: null,
      }));
    }

    // Example: Validate testModeId
    if (NewTestLibraryForm.testModeId === 0) {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        testModeError: true,
        testModeErrorMSG: "Please select a test level",
      }));
      isValidForm = false;
    } else {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        testModeError: false,
        testModeErrorMSG: null,
      }));
    }

    if (NewTestLibraryForm.testTypeId === 0) {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        testTypeError: true,
        testTypeErrorMSG: "Please select a test type",
      }));
      isValidForm = false;
    } else {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        testTypeError: false,
        testTypeErrorMSG: null,
      }));
    }

    // Example: Validate testModeId
    if (NewTestLibraryForm.testModeTypeId === 0) {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        testModeTypeError: true,
        testModeTypeErrorMSG: "Please select a test mode",
      }));
      isValidForm = false;
    } else {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        testModeTypeError: false,
        testModeTypeErrorMSG: null,
      }));
    }
    // Add similar validation checks for other fields
    // Example: Validate uploadFiles
    if (!NewTestLibraryForm.uploadFiles.get("file")) {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        uploadFilesError: true,
        uploadFilesErrorMSG: "Please upload a file",
      }));
      isValidForm = false;
    } else {
      setNewFormsError((prevErrors) => ({
        ...prevErrors,
        uploadFilesError: false,
        uploadFilesErrorMSG: null,
      }));
    }

    return isValidForm;
  };

  const handleNewTestLibraryUpload = () => {
    try {
      if (validateForm()) setIsloading(true);
      UploadNewTestLibrary(NewTestLibraryForm)
        .then((res) => {
          setIsloading(false);
          if (res.status === 200) {
            toast.success("Test Library Created Successfully");
            handleClose();
            setNewTestLibraryForm({
              subCategoryName: "",
              categoryId: 0,
              categoryName: "",
              testTypeId: 0,
              testTypeName: "",
              testModeId: 0,
              testModeName: "",
              testModeTypeName: "",
              testModeTypeId: 0,
              uploadFiles: new FormData(),
            });
            setNewFormsError({
              categoryNameError: false,
              categoryNameErrorMSG: null,
              categoryDropdownError: false,
              categoryDropdownMSG: null,
              testTypeError: false,
              testTypeErrorMSG: null,
              testModeError: false,
              testModeErrorMSG: null,
              testModeTypeError: false,
              testModeTypeErrorMSG: null,
              uploadFilesError: false,
              uploadFilesErrorMSG: null,
            });
            setRefetch((prev) => prev + 1);
          }
        })
        .catch((err) => {
          setIsloading(false);
          toast.error(err.response.data.status);
        });
    } catch (error) {
      setIsloading(false);
      toast.error(error.response.data.status);
    }
  };

  const ExistingValidateForm = () => {
    let isValidForm = true;

    // Example: Validate categoryId
    if (ExistingLibraryForm.categoryId === 0) {
      setExistingFormsError((prevErrors) => ({
        ...prevErrors,
        categoryDropdownError: true,
        categoryDropdownMSG: "Please select a category",
      }));
      isValidForm = false;
    } else {
      setExistingFormsError((prevErrors) => ({
        ...prevErrors,
        categoryDropdownError: false,
        categoryDropdownMSG: null,
      }));
    }

    if (ExistingLibraryForm.testTypeId === 0) {
      setExistingFormsError((prevErrors) => ({
        ...prevErrors,
        testTypeError: true,
        testTypeErrorMSG: "Please select a test type",
      }));
      isValidForm = false;
    } else {
      setExistingFormsError((prevErrors) => ({
        ...prevErrors,
        testTypeError: false,
        testTypeErrorMSG: null,
      }));
    }

    // Add similar validation checks for other fields
    // Example: Validate uploadFiles
    if (!ExistingLibraryForm.uploadFiles.get("file")) {
      setExistingFormsError((prevErrors) => ({
        ...prevErrors,
        uploadFilesError: true,
        uploadFilesErrorMSG: "Please upload a file",
      }));
      isValidForm = false;
    } else {
      setExistingFormsError((prevErrors) => ({
        ...prevErrors,
        uploadFilesError: false,
        uploadFilesErrorMSG: null,
      }));
    }

    return isValidForm;
  };
  const handleExistingTestLibraryUpload = () => {
    try {
      if (ExistingValidateForm()) {
        setIsloading(true);
        UpdateExistingTestLibrary(ExistingLibraryForm)
          .then((res) => {
            setIsloading(false);
            if (res.status === 200) {
              toast.success("Test Library Updated Successfully");
              handleClose();
              setExistingLibraryForm({
                categoryId: 0,
                categoryName: "",
                testTypeId: 0,
                testTypeName: "",
                testModeId: 0,
                testModeName: "",
                testModeTypeName: "",
                testModeTypeId: 0,
                uploadFiles: new FormData(),
              });
              setExistingFormsError({
                categoryDropdownError: false,
                categoryDropdownMSG: null,
                testTypeError: false,
                testTypeErrorMSG: null,
                // testModeError: false,
                // testModeErrorMSG: null,
                uploadFilesError: false,
                uploadFilesErrorMSG: null,
              });
              setRefetch((prev) => prev + 1);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.status);
            setIsloading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const auth = JSON.parse(sessionStorage.getItem("auth"));

  // Set up the headers
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${auth?.token}`);
  const handleTemplateDownload = () => {
    if (uploadLibraryType.existingLibrary) {
      setIsloading(true);
      // Fetch the data
      fetch(
        `${
          window.location.origin ===
          "https://assessment-portaldev.ideassionlive.in"
            ? process.env.React_App_Dev
            : window.location.origin ===
              "https://assessment-portalqa.ideassionlive.in"
            ? process.env.React_App_Qa
            : process.env.React_App_Local
        }/questions/download/excel-template?fileName=existingQuestion`,
        {
          method: "GET", // or 'POST', 'PUT', etc.
          headers: headers,
        }
      )
        .then((response) => {
          setIsloading(false);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          // Use the blob data as needed
          // For example, create a download link and trigger the download
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = "Existing_Question_Template.xlsx"; // Specify the desired filename
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((error) => {
          setIsloading(false);
          console.error("Error fetching file:", error);
        });
    } else {
      setIsloading(true);
      fetch(
        `${
          window.location.origin ===
          "https://assessment-portaldev.ideassionlive.in"
            ? process.env.React_App_Dev
            : window.location.origin ===
              "https://assessment-portalqa.ideassionlive.in"
            ? process.env.React_App_Qa
            : process.env.React_App_Local
        }/questions/download/excel-template?fileName=newQuestion`,
        {
          method: "GET", // or 'POST', 'PUT', etc.
          headers: headers,
        }
      )
        .then((response) => {
          setIsloading(false);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          // Use the blob data as needed
          // For example, create a download link and trigger the download
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = "New_Question_Template.xlsx"; // Specify the desired filename
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((error) => {
          setIsloading(false);
          console.error("Error fetching file:", error);
        });
    }
  };
  return (
    <Box
      component={Stack}
      direction={"column"}
      spacing={2}
      sx={{ width: "100%", height: "100%", overflowY: "auto" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="p" sx={{ fontWeight: 700 }}>
          {" "}
          Upload Questions
        </Typography>
      </Box>
      <Box component={Stack} direction={"row"} spacing={2}>
        <Box
          sx={{
            width: "150px",
            bgcolor: uploadLibraryType.newLibrary ? "#908DB9" : "#C7C7C7",
            borderRadius: "50px",
            height: "30px",
            pt: 0.5,
            textAlign: "center",
            fontSize: "13px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleLibraryType}
        >
          Create New Library
        </Box>
        <Box
          sx={{
            width: "150px",
            bgcolor: uploadLibraryType.existingLibrary ? "#908DB9" : "#C7C7C7",
            borderRadius: "50px",
            height: "30px",
            pt: 0.5,
            textAlign: "center",
            fontSize: "13px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleLibraryType}
        >
          Existing Library
        </Box>
      </Box>
      <Box sx={{ width: "100%" }} mt={3}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
          flexWrap={"wrap"}
        >
          {uploadLibraryType.newLibrary && (
            <>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
                >
                  Library Name<sup style={{ color: "red" }}>*</sup>
                </Typography>
                <FormControl error={NewFormErrors.categoryNameError}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Text"
                    value={NewTestLibraryForm.subCategoryName}
                    sx={{
                      backgroundColor: "white",
                      minWidth: 200,
                    }}
                    error={NewFormErrors.categoryNameError}
                    // helperText={NewFormErrors.categoryNameErrorMSG}
                    onChange={(e) => handleSubCategoryName(e)}
                    size="small"
                    variant="outlined"
                  />
                  {NewFormErrors.categoryNameError && (
                    <FormHelperText>
                      {NewFormErrors.categoryNameErrorMSG}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </>
          )}
          {uploadLibraryType.newLibrary && (
            <Box sx={{ minWidth: 200 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              >
                Categorized As<sup style={{ color: "red" }}>*</sup>
              </Typography>
              <FormControl
                error={NewFormErrors?.categoryDropdownError}
                fullWidth
              >
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewTestLibraryForm.categoryId}
                  size="small"
                  sx={{
                    backgroundColor: "white",
                  }}
                  disabled={SkillCategory?.length === 0}
                  error={NewFormErrors?.categoryDropdownError}
                  placeholder="Select Category"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={0} disabled>
                    Select Categorized
                  </MenuItem>

                  {SkillCategory?.map((option) => {
                    return (
                      <MenuItem
                        onClick={() => handleFilterChange(option)}
                        key={option.id}
                        value={option.id}
                      >
                        {option.skillCategoryName}
                      </MenuItem>
                    );
                  })}
                </Select>
                {NewFormErrors?.categoryDropdownError ? (
                  <FormHelperText>
                    {NewFormErrors?.categoryDropdownMSG}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          )}
          {uploadLibraryType.existingLibrary && (
            <Box sx={{ minWidth: 240 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              >
                Library<sup style={{ color: "red" }}>*</sup>
              </Typography>
              <FormControl
                fullWidth
                error={ExistingFormErrors?.categoryDropdownError}
              >
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ExistingLibraryForm.categoryId}
                  size="small"
                  sx={{
                    backgroundColor: "white",
                  }}
                  disabled={AllTopicSubCategory?.length === 0}
                  placeholder="Select Category"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={0} disabled>
                    Select library
                  </MenuItem>

                  {AllTopicSubCategory?.map((option) => {
                    return (
                      <MenuItem
                        onClick={() => handleExistingFilterChange(option)}
                        key={option.Id}
                        value={option.Id}
                      >
                        {option.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {ExistingFormErrors?.categoryDropdownError && (
                  <FormHelperText>
                    {ExistingFormErrors?.categoryDropdownMSG}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          )}
          <Box>
            {complexityType?.length !== 0 && (
              <Typography
                variant="body2"
                sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              >
                What complexity is this?<sup style={{ color: "red" }}>*</sup>
              </Typography>
            )}
            {uploadLibraryType.existingLibrary ? (
              <>
                <Stack direction={"row"} spacing={1}>
                  {complexityType?.slice(0, 3).map((el) => {
                    return (
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "50px",
                          borderWidth: "3px",
                          borderColor:
                            el.id === ExistingLibraryForm.testTypeId
                              ? "#908DB8"
                              : "#B2ACAC",
                          color:
                            el.id === ExistingLibraryForm.testTypeId
                              ? "#908DB8"
                              : "#B2ACAC",
                          fontSize: "12px",
                          width: "105px",
                          height: "33px",
                        }}
                        onClick={() => handleExistingTestType(el)}
                      >
                        {el.id === ExistingLibraryForm.testTypeId && "\u2714"}{" "}
                        {el?.complexityType}
                      </Button>
                    );
                  })}
                </Stack>
                {ExistingFormErrors?.testTypeError && (
                  <FormControl error={ExistingFormErrors?.testTypeError}>
                    <FormHelperText>
                      {ExistingFormErrors?.testTypeErrorMSG}
                    </FormHelperText>
                  </FormControl>
                )}
              </>
            ) : (
              <>
                <Stack direction={"row"} spacing={1}>
                  {complexityType?.slice(0, 3).map((el) => {
                    return (
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "50px",
                          borderWidth: "3px",
                          borderColor:
                            el.id === NewTestLibraryForm.testTypeId
                              ? "#908DB8"
                              : "#B2ACAC",
                          color:
                            el.id === NewTestLibraryForm.testTypeId
                              ? "#908DB8"
                              : "#B2ACAC",
                          fontSize: "12px",
                          width: "105px",
                          height: "33px",
                        }}
                        onClick={() => handleTestType(el)}
                      >
                        {el.id === NewTestLibraryForm.testTypeId && "\u2714"}{" "}
                        {el?.complexityType}
                      </Button>
                    );
                  })}
                </Stack>
                {NewFormErrors?.testTypeError && (
                  <FormControl error={NewFormErrors?.testTypeError}>
                    <FormHelperText sx={{ mt: 1.2 }}>
                      {NewFormErrors?.testTypeErrorMSG}
                    </FormHelperText>
                  </FormControl>
                )}
              </>
            )}
          </Box>
        </Stack>
      </Box>
      <Box component={Stack} direction={"row"} gap={4}>
        {uploadLibraryType.existingLibrary &&
        ExistingLibraryForm.testModeId !== 0 ? (
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
            >
              Category<sup style={{ color: "red" }}>*</sup>
            </FormLabel>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value={ExistingLibraryForm?.testModeId}
                control={<Radio size="small" checked />}
                label={ExistingLibraryForm?.testModeName}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          uploadLibraryType.newLibrary && (
            <>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
                >
                  Category<sup style={{ color: "red" }}>*</sup>
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={TestTypeValue}
                >
                  {TestType?.map((el) => {
                    return (
                      <FormControlLabel
                        value={el?.id}
                        onChange={() => handleTestMode(el)}
                        control={<Radio size="small" />}
                        label={el?.skillName}
                      />
                    );
                  })}
                </RadioGroup>
                {NewFormErrors?.testModeError && (
                  <FormControl error={NewFormErrors?.testModeError}>
                    <FormHelperText sx={{ mt: 1.2 }}>
                      {NewFormErrors?.testModeErrorMSG}
                    </FormHelperText>
                  </FormControl>
                )}
              </FormControl>
            </>
          )
        )}
        {uploadLibraryType.existingLibrary &&
          ExistingLibraryForm.testModeTypeId !== 0 && (
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              >
                Type<sup style={{ color: "red" }}>*</sup>
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={ExistingLibraryForm?.testModeTypeId}
                  control={<Radio size="small" checked />}
                  label={ExistingLibraryForm?.testModeTypeName}
                />
              </RadioGroup>
            </FormControl>
          )}
        {uploadLibraryType.newLibrary && QuestionType?.length > 0 && (
          <>
            <FormControl error={NewFormErrors?.testModeTypeError}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              >
                Type<sup style={{ color: "red" }}>*</sup>
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {QuestionType?.slice(0, 1)?.map((el) => {
                  return (
                    <FormControlLabel
                      value={el?.id}
                      control={<Radio size="small" />}
                      label={el?.questionType}
                      onChange={() => handleTestModeType(el)}
                    />
                  );
                })}
              </RadioGroup>
              {NewFormErrors?.testModeTypeError && (
                <FormHelperText>
                  {NewFormErrors?.testModeTypeErrorMSG}
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </Box>
      <Box
        sx={{
          bgcolor: "#F8F8FF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          py: 2,
          border:
            NewFormErrors?.uploadFilesError ||
            ExistingFormErrors?.uploadFilesError
              ? "3px red dashed"
              : "3px #384EB74D dashed",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <input
              type="file"
              accept=".xls,.xlsx"
              ref={inputUploadRef}
              className="input-field"
              hidden
              onChange={(e) => {
                e.preventDefault();

                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                if (e.target.files.length !== 0) {
                  if (uploadLibraryType.newLibrary) {
                    setNewTestLibraryForm({
                      ...NewTestLibraryForm,
                      uploadFiles: formData,
                    });
                  } else {
                    setExistingLibraryForm({
                      ...ExistingLibraryForm,
                      uploadFiles: formData,
                    });
                  }
                }
                inputUploadRef.current.value = null;
              }}
            />
            <Box
              sx={{ fontSize: "55px", color: "#908DB8", textAlign: "center" }}
            >
              <CloudUploadOutlined fontSize="inherit" />
            </Box>

            <Typography>
              Drag & Drop Files or{" "}
              <span
                style={{
                  color: "#908DB8",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  inputUploadRef.current.click();
                }}
              >
                {" "}
                Browse{" "}
              </span>
            </Typography>
            <Typography variant="p" sx={{ fontSize: "12px", mt: 1 }}>
              Supported formats: Excel and Sheets
            </Typography>
            {(NewFormErrors?.uploadFilesError ||
              ExistingFormErrors?.uploadFilesError) && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontSize: "12px" }}
              >
                {NewFormErrors?.uploadFilesErrorMSG ||
                  ExistingFormErrors?.uploadFilesErrorMSG}
              </Typography>
            )}
            {uploadLibraryType.newLibrary &&
              NewTestLibraryForm.uploadFiles?.get("file") !== null && (
                <Typography sx={{ textAlign: "center", mt: 1 }}>
                  <Badge
                    size="sm"
                    sx={{ fontSize: "12px", padding: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (uploadLibraryType.newLibrary) {
                        setNewTestLibraryForm({
                          ...NewTestLibraryForm,
                          uploadFiles: new FormData(),
                        });
                      } else {
                        setExistingLibraryForm({
                          ...ExistingLibraryForm,
                          uploadFiles: new FormData(),
                        });
                      }
                    }}
                    badgeContent={
                      <CloseOutlined
                        fontSize="inherit"
                        sx={{ padding: 0, margin: 0 }}
                      />
                    }
                  >
                    <Chip size="sm">
                      <Typography fontSize={"small"}>
                        {NewTestLibraryForm.uploadFiles.get("file")?.name}
                      </Typography>
                    </Chip>
                  </Badge>
                </Typography>
              )}
            {uploadLibraryType.existingLibrary &&
              ExistingLibraryForm.uploadFiles?.get("file") !== null && (
                <Typography sx={{ textAlign: "center", mt: 1 }}>
                  <Badge
                    size="sm"
                    sx={{ fontSize: "12px", padding: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (uploadLibraryType.newLibrary) {
                        setNewTestLibraryForm({
                          ...NewTestLibraryForm,
                          uploadFiles: new FormData(),
                        });
                      } else {
                        setExistingLibraryForm({
                          ...ExistingLibraryForm,
                          uploadFiles: new FormData(),
                        });
                      }
                    }}
                    badgeContent={
                      <CloseOutlined
                        fontSize="inherit"
                        sx={{ padding: 0, margin: 0 }}
                      />
                    }
                  >
                    <Chip size="sm">
                      <Typography fontSize={"small"}>
                        {ExistingLibraryForm.uploadFiles.get("file")?.name}
                      </Typography>
                    </Chip>
                  </Badge>
                </Typography>
              )}
          </Box>
        </Box>
      </Box>
      <Box component={Stack} direction={"row"}>
        <Button
          variant="contained"
          sx={{
            width: "150px",
            height: "40px",
            color: "white",
            bgcolor: "#908DB8",
            borderRadius: "6px",
            alignSelf: "center",
          }}
          // disabled={NewFormErrors.categoryNameError}
          onClick={
            uploadLibraryType.newLibrary
              ? handleNewTestLibraryUpload
              : handleExistingTestLibraryUpload
          }
        >
          Upload
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            width: "150px",
            height: "40px",
            color: "#D10000",
            borderColor: "#D10000",
            borderWidth: "2px",
            borderRadius: "4px",
            ml: 4,
            alignSelf: "center",
          }}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Box
          component={Stack}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ ml: "auto", cursor: "pointer" }}
          onClick={handleTemplateDownload}
        >
          <DownloadIcon />
          <Typography>Download Template</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadQuestionModal;
