import { Box, CssBaseline } from "@mui/material";
import React, { useState } from "react";
import LoginInput from "../../Components/Login-Signup/LoginInput";
import SignupInput from "../../Components/Login-Signup/SignupInput";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "../../Components/Login-Signup/ForgotPassword";
import LoginCarousel from "../../Components/Login-Signup/LoginCarousel";
import ImageSignupAndForgetPasswordPage from "../../Components/Login-Signup/ImageSignupAndForgetPasswordPage";
const { SignupImage, ForgetPasswordImage } = ImageSignupAndForgetPasswordPage(); // eslint-disable-line
// import ImageCarousel from "../../Components/Login-Signup/ImageCarousel";

const AuthLayout = () => {
  const [isLoginType, setIsloginType] = useState({
    login: true,
    signup: false,
    forgotPassword: false,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [signupCompleted, setSignupCompleted] = useState(false); // eslint-disable-line

  return (
    <Box
      sx={{
        display: "flex",
        height: "100svh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#EBECF1",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          bgcolor: "#908DB8",
          width: "60%",
          minHeight: "75vh",
          borderRadius: "15px",
          display: "flex",
          direction: "row",
        }}
      >
        <Box
          sx={{
            bgcolor: "#908DB8",
            width: "50%",
            height: "100%",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // alignContent: "center",
            borderRadius: "15px 0px 0px 15px",
          }}
        >
          {/* <ImageCarousel isLoginType={isLoginType} currentStep={currentStep} /> */}
          {isLoginType.login && <LoginCarousel />}
          {isLoginType.signup && <LoginCarousel />}
          {/* {signupCompleted ? (
            <SignupImage />
          ) : (
            isLoginType.signup && <LoginCarousel />
          )} */}
          {isLoginType.forgotPassword && (
            <ForgetPasswordImage currentStep={currentStep} />
          )}
        </Box>
        <Box
          sx={{
            bgcolor: "#FFFFFF",
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            borderRadius: "0px 15px 15px 0px",
            py: 2,
          }}
        >
          {isLoginType?.login && (
            <LoginInput
              handleLoginType={() =>
                setIsloginType({
                  login: false,
                  signup: true,
                  forgotPassword: false,
                })
              }
              handleForgotPasswordType={() =>
                setIsloginType({
                  login: false,
                  signup: false,
                  forgotPassword: true,
                })
              }
            />
          )}
          {isLoginType?.signup && (
            <SignupInput
              handleLoginType={() =>
                setIsloginType({
                  login: true,
                  signup: false,
                  forgotPassword: false,
                })
              }
              setSignupCompleted={setSignupCompleted}
            />
          )}
          {isLoginType?.forgotPassword && (
            <ForgotPassword
              handleLoginType={() =>
                setIsloginType({
                  login: true,
                  signup: false,
                  forgotPassword: false,
                })
              }
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          )}
        </Box>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
      />
    </Box>
  );
};

export default AuthLayout;
