import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Button,
  IconButton,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Typography } from "@mui/material";
import AllReport from "./AllReport";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import GetSavedAssessmentTable from "../API/GetSavedAssessmentTable";
import { red, blue, green, yellow, purple, orange } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux"; // eslint-disable-line
import { updateActiveStep } from "../Redux/Stepper";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import GetLibraryCount from "../API/GetLibraryCount";
import {
  // setSelectedType,
  // setSelectedMode,
  // setSelectedLevel,
  // setUnSaveAssessment,
  // setSaveAssessment,
  setDirectAssessment,
  setAssessmentData,
} from "../Redux/FirstStep";

// import { removeProduct } from "../Redux/SecondStep";
// import Get_Unregister_results from "../API/Get_Unregister_results";
// import Chart from "react-google-charts";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const lastBox = [
//   {
//     name: "Saved Assesment",
//     value: 10,
//     background: "#d9fdd6",
//   },
//   {
//     name: "Live",
//     value: 27,
//     background: "#fdd6d6",
//   },
// ];

// const cardVAlue = [
//   {
//     name: "Saved Assesment",
//     value: 10,
//     background: "#d5deff",
//   },
//   {
//     name: "Saved Assesment",
//     value: 10,
//     background: "#dbf6fd",
//   },
//   {
//     name: "Live",
//     value: 27,
//     background: "#c8f7dc",
//   },
//   {
//     name: "Live",
//     value: 27,
//     background: "#e9e7fd",
//   },
//   {
//     name: "Live",
//     value: 27,
//     background: "#ffd3e2",
//   },
//   {
//     name: "Live",
//     value: 27,
//     background: "#fee4cb",
//   },
// ];

// const colors = ["#1e3bb3", "#5ecdf7"];

const donoughtColors = ["#1e3bb3", "#f8cfc7", "#5ecdf7"];

export const data = [
  ["Task", "Hours per Day"],
  ["Complete", 11],
  ["On Going", 12],
  ["In Complete", 18],
];

export const options = {
  // title: "My Daily Activities",
  pieHole: 0.4,
  is3D: false,
  legend: {
    position: "bottom",
  },
  colors: donoughtColors,
};

// options = {
//   width: "100%",
//   height: 150,
//   legend: {
//     position: 'top',
//     alignment: 'end',
//     display: true,
//   }
// },

const columns = [
  {
    field: "NameofAssessment",
    headerName: "NAME",
  },
  {
    field: "testDate",
    headerName: "DATE",
  },
  {
    field: "AssessmentUsage",
    headerName: "USAGE",
  },
  {
    field: "Time",
    headerName: "DURATION",
  },
];

export default function Dashboard() {
  const [mainTableData, setMainTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [questionCount, setQuestionCount] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  // const [open, setOpen] = React.useState(false);
  // const handleClick = () => {
  //   setOpen(true);
  //   navigator.clipboard.writeText("http/www.google.com");
  // };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    GetLibraryCount()
      .then((res) => {
        setQuestionCount(res?.data?.body);
      })
      .catch((err) => err);
    GetSavedAssessmentTable()
      .then((res) => setMainTableData(res.data))
      .catch((err) => err);
  }, []);

  // useEffect(() => {
  //   if (ResultMode.register) {
  //   }

  //   if (ResultMode.unregister) {
  //     Get_Unregister_results()
  //       .then((res) => setMainTableData(res.data))
  //       .catch((err) => err);
  //   }
  // }, [ResultMode]);

  useEffect(() => {
    setTableData(mainTableData);
  }, [mainTableData]);

  const classes = {
    tableRow: {
      height: "5px",
      padding: "0px",
    },
    tableCell: {
      padding: "5px",
      fontFamily: "manrope",
      fontWeight: 600,
      fontSize: "12px",
    },
  };

  // Array of Material-UI color palettes
  const colorPalettes = [red, blue, green, yellow, purple, orange];

  // Function to generate a random color from the color palettes
  const getRandomColor = () => {
    const randomPalette =
      colorPalettes[Math.floor(Math.random() * colorPalettes.length)];
    const shades = Object.keys(randomPalette).filter(
      (key) => typeof randomPalette[key] === "string" && key.includes("700")
    );
    const randomShade = shades[Math.floor(Math.random() * shades.length)];
    return randomPalette[randomShade];
  };

  const findTestComlexityName = (id) => {
    if (id === 1) {
      return "Easy";
    } else if (id === 2) {
      return "Medium";
    } else if (id === 3) {
      return "Hard";
    } else if (id === 4) {
      return "Custom";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-based
    const year = date.getFullYear();

    // const hours = date.getHours().toString().padStart(2, "0");
    // const minutes = date.getMinutes().toString().padStart(2, "0");
    // const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
    // return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleSearchName = (e) => {
    const value = e.target.value;
    if (value && value?.length >= 1) {
      const _searchData = mainTableData.filter(
        (item) =>
          item.assessmentName &&
          item.assessmentName
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
      );

      setTableData(_searchData);
    } else {
      setTableData(mainTableData);
    }
  };

  const handleDirectAssessment = async (row) => {
    dispatch(setDirectAssessment(true));
    dispatch(setAssessmentData(row));
    dispatch(updateActiveStep(4));
    navigate("/testsetupprocess");
  };

  const handleDirectQuestions = () => {
    navigate("/testlibrary", { state: { isOpen: true } });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "90vh",
        width: "100%",
        // mt: "0.8%",
        backgroundColor: "rgb(235 236 241)",
      }}
    >
      <Box sx={{ flexGrow: 1, width: "100%", p: 3, px: 5 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7} xxl={7}>
            <Item sx={{ height: "100%", borderRadius: "15px", p: 3, pb: 0 }}>
              <Stack direction={"column"} alignItems={"center"} width="100%">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                  }}
                  // gap={6}
                  width="100%"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      paddingY: 0.2,
                      color: "#212121",
                      fontWeight: 800,
                      fontFamily: "Manrope",
                    }}
                  >
                    Saved Assessment
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mt: "12px !important",
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "center",
                    }}
                  >
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        boxShadow: "none",
                        border: "1px solid #ddd",
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1, fontSize: "12px" }}
                        placeholder="Search Assessment"
                        onChange={handleSearchName}
                        inputProps={{ "aria-label": "search name" }}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: "6px" }}
                        aria-label="search"
                        disabled
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Box>
                  <Box>
                    <Link
                      to="/createassessment"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Button
                        sx={{
                          color: "#FFF",
                          backgroundColor: "#908DB8",
                          textAlign: "center",
                          fontFamily: "Manrope",
                          fontSize: "10px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                          lineHeight: "normal",
                          borderRadius: "4px",
                          padding: "10px 20px",
                          letterSpacing: "1px",
                        }}
                        variant="contained"
                        startIcon={<AddIcon />}
                      >
                        New Assessment
                      </Button>
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{ width: "100%", height: "100%", mt: "8px !important" }}
                >
                  <TableContainer
                    sx={{
                      maxHeight: 400,
                      bgcolor: "white",
                      // px: 3,
                      // py: 2,
                      borderRadius: "15px",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead sx={{ textAlign: "center" }}>
                        <TableRow>
                          {columns.map((column) => {
                            return (
                              <TableCell
                                sx={{
                                  fontFamily: "manrope",
                                  fontWeight: 700,
                                  textAlign:
                                    column.headerName === "DURATION" && "right",
                                  padding: "8px !important",
                                  fontSize: "12px",
                                }}
                                key={column.id}
                              >
                                {column.headerName}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.length !== 0 &&
                          tableData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow
                                  hover
                                  sx={{
                                    fontFamily: "manrope",
                                    cursor: "pointer",
                                  }}
                                  key={row.code}
                                  onClick={() => handleDirectAssessment(row)}
                                >
                                  <TableCell sx={classes.tableCell} width={400}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          bgcolor: getRandomColor,
                                          mr: 2,
                                          fontFamily: "manrope",
                                          width: "30px",
                                          height: "30px",
                                          fontSize: "14px",
                                        }}
                                        alt={row?.assessmentName?.toUpperCase()}
                                        src="/broken-image.jpg"
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            fontWeight: 700,
                                            fontFamily: "manrope",
                                            textTransform: "capitalize",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {row?.assessmentName === ""
                                            ? "Unnamed Assessment"
                                            : row?.assessmentName}
                                        </Box>
                                        <Box
                                          sx={{
                                            fontSize: "10px",
                                            color: "#8d8d8d",
                                          }}
                                        >
                                          {findTestComlexityName(
                                            row?.complexityId
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell sx={classes.tableCell} width={300}>
                                    {formatDate(row?.date)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "5px",
                                      fontFamily: "manrope",
                                      fontWeight: 600,
                                      pl: 2,
                                      color:
                                        row?.Usage === "Low"
                                          ? "#FF4A4A"
                                          : row?.Usage === "Highly"
                                          ? "#2DD42A"
                                          : "#5ECDF7",
                                      fontSize: "12px",
                                    }}
                                    width={300}
                                  >
                                    {row?.Usage}
                                  </TableCell>
                                  <TableCell
                                    // sx={classes.tableCell}
                                    sx={{
                                      padding: "5px",
                                      fontFamily: "manrope",
                                      fontWeight: 600,
                                      textAlign: "right",
                                      pr: 1,
                                      fontSize: "12px",
                                    }}
                                    width={100}
                                  >
                                    {row?.assessmentTime > 1
                                      ? `${row?.assessmentTime} Mins`
                                      : `${row?.assessmentTime} Min`}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                    {tableData?.length === 0 && (
                      <Typography
                        sx={{
                          fontFamily: "manrope",
                          marginX: "auto",
                          width: "100%",
                          textAlign: "center",
                          my: 3,
                          fontWeight: 700,
                        }}
                      >
                        Data not available
                      </Typography>
                    )}
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    // sx={{ fontSize: "10px" }}
                  />
                </Box>
              </Stack>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
            <Item sx={{ height: "100%", borderRadius: "15px", p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#212121",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 800,
                    lineHeight: "normal",
                  }}
                >
                  Libraries
                </Typography>
                <Button
                  sx={{
                    color: "#FFF",
                    backgroundColor: "#908DB8",
                    textAlign: "center",
                    fontFamily: "Manrope",
                    fontSize: "10px",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    lineHeight: "normal",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    letterSpacing: "1px",
                  }}
                  variant="contained"
                  onClick={handleDirectQuestions}
                  startIcon={<UploadIcon />}
                >
                  Question
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  alignItems: "center",
                  // justifyContent: "space-evenly",
                  // height: "85%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    border: "2px solid #908DB8",
                    borderRadius: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#A3A3A3",
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    Total Libraries
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Manrope",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {questionCount.totalLibraries || 0}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    border: "2px solid #908DB8",
                    borderRadius: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#A3A3A3",
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    Total Questions
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Manrope",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {questionCount.totalQuestions || 0}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    border: "2px solid #908DB8",
                    borderRadius: "15px",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      width: "33%",
                      height: "100%",
                      borderRight: "2px solid #908DB8",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#A3A3A3",
                        fontFamily: "Manrope",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                      }}
                    >
                      Easy
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Manrope",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                      }}
                    >
                      {questionCount.easy || 0}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      width: "34%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#A3A3A3",
                        fontFamily: "Manrope",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                      }}
                    >
                      Medium
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Manrope",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                      }}
                    >
                      {questionCount.medium || 0}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      width: "33%",
                      borderLeft: "2px solid #908DB8",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#A3A3A3",
                        fontFamily: "Manrope",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                      }}
                    >
                      Hard
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Manrope",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                      }}
                    >
                      {questionCount.hard || 0}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item sx={{ p: 3, borderRadius: "15px", pb: 0 }}>
              <AllReport isDashboard={true} />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
