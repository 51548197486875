import { axiosInstance } from "./Api";

const TestResultReportFilter = (type, date) => {
  return axiosInstance.get(
    `testResult/report-filter?userType=${type}&date=${date}`
  );
};

export default TestResultReportFilter;

// http://localhost:8080/testResult/report-filter?userType=unregistered&date=2024-02-01
