import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import AppBarNav from "./routes/AppBarNav";
import CreateAssessment from "./Containers/CreateAssessment";
import AllReport from "./Containers/AllReport";
import Ireport from "./Containers/Ireport";
import Dashboard from "./Containers/Dashboard";
import RegistrationForm from "./Components/RegistrationForm";
import StepperTestSetupForm from "./Components/StepperTestSetupForm";
import UserTest from "./Containers/UserTest/UserTest";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import UserTestLogin from "./Containers/UserTest/UserTestLogin";
import UserTestStartPage from "./Containers/UserTest/UserTestStartPage";
import CandidateAssesmentReport from "./Components/CandidateAssesmentReport";
import UserAssesmentReport from "./Components/UserAssesmentReport";
import TestLibrary from "./Containers/TestLibrary/TestLibrary";
import AuthLayout from "./Containers/Login-Signup/AuthLayout";
import ProtectedRoutes from "./Containers/ProtectedRoutes/ProtectedRoutes";
import Configuration from "./Containers/Configuration/Configuration";
import { setAuthToken } from "./API/Api";

// AAD CHANGES START //
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../src/AzureActiveDirectory/authConfig";
// AAD CHANGES //
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    // console.log(event);
    msalInstance.setActiveAccount(event.payload.account);
  }
});
// AAD CHANGES END //

const auth = JSON.parse(sessionStorage.getItem("auth"));
if (auth?.token) {
  setAuthToken(auth?.token);
} else {
  setAuthToken(false);
}

// // Function to generate route configurations for UserTestLogin component with dynamic codeId parameters
// function generateUserTestLoginRoutes() {
//   const routes = [];
//   for (let i = 1; i <= 60; i++) {
//     let path = `/UserTestLogin/:id/:code`;
//     for (let j = 1; j <= i; j++) {
//       path += `/:codeId${j}`;
//     }
//     routes.push({
//       path: path,
//       element: <UserTestLogin />,
//     });
//   }
//   return routes;
// }

// // Create route configurations
// const userTestLoginRoutes = generateUserTestLoginRoutes();

// Define other route configurations
// const otherRoutes = [
//   // Define your other routes here
//   {
//     path: "/UserTestStartPage",
//     element: <UserTestStartPage />,
//   },
//   {
//     path: "/CandidateAssesmentReport",
//     element: <CandidateAssesmentReport />,
//   },
//   {
//     path: "/UserAssesmentReport",
//     element: <UserAssesmentReport />,
//   },
//   // {
//   //   path: "/UserAssesmentReport/:id",
//   //   element: <UserAssesmentReport />,
//   // },
// ];
//
//// Combine all routes
const allRoutes = [
  {
    path: "/",
    element: <AppBarNav />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoutes notAllowed={"User"}>
            <Dashboard />
          </ProtectedRoutes>
        ),
      },
      {
        path: "allreport",
        element: (
          <ProtectedRoutes notAllowed={"User"}>
            <AllReport />
          </ProtectedRoutes>
        ),
      },
      {
        path: "createassessment",
        element: (
          <ProtectedRoutes>
            <CreateAssessment notAllowed={"none"} />
          </ProtectedRoutes>
        ),
      },
      {
        path: "ireport",
        element: (
          <ProtectedRoutes notAllowed={"User"}>
            <Ireport />
          </ProtectedRoutes>
        ),
      },
      {
        path: "createnewform",
        element: (
          <ProtectedRoutes notAllowed={"User"}>
            <RegistrationForm />
          </ProtectedRoutes>
        ),
      },
      {
        path: "testsetupprocess",
        element: (
          <ProtectedRoutes notAllowed={"none"}>
            <StepperTestSetupForm />
          </ProtectedRoutes>
        ),
      },
      {
        path: "testlibrary",
        element: (
          <ProtectedRoutes notAllowed={"User"}>
            <TestLibrary />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/Configuration",
        element: (
          <ProtectedRoutes notAllowed={"User"}>
            <Configuration />,
          </ProtectedRoutes>
        ),
      },
    ],
  },
  {
    path: "/welcome",
    element: <AuthLayout />,
  },
  {
    path: "/CandidateAssesmentReport",
    element: <CandidateAssesmentReport />,
  },
  {
    path: "/UserTestLogin/:id",
    element: <UserTestLogin />,
  },
  {
    path: "/UserTestStartPage",
    element: <UserTestStartPage />,
  },
  {
    path: "/usertest",
    element: <UserTest />,
  },
  {
    path: "/UserAssesmentReport",
    element: <UserAssesmentReport />,
  },
  // {
  //   path: "/UserAssesmentReport/:id",
  //   element: <UserAssesmentReport />,
  // },
  //// Add other top-level routes here
  // ...userTestLoginRoutes, // Include generated UserTestLogin routes
  // ...otherRoutes, // Include other routes
];

// Create browser router
const router = createBrowserRouter(allRoutes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // AAD CHANGES START //
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
