import { Box, Stack, Typography } from "@mui/joy";
import { Paper } from "@mui/material";
import React from "react";
import addIcon from "../Assets/addIcon.png";
import EditIcon from "../Assets/editIconTest.png";
import { Link, useNavigate } from "react-router-dom";

const CreateAssessment = () => {
  const navigate = useNavigate();
  const handleSkipToTest = () => {
    navigate("/testsetupprocess", { state: { skipReg: true } });
  };
  const storedAuth = JSON.parse(sessionStorage.getItem("auth"));

  // console.log("store", storedAuth);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#EBECF1",
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        marginTop: "0.5%",
        alignItems: "center",
        justifyContent: "center",
        overFlow: "auto",
      }}
    >
      <Box sx={{ marginLeft: "auto", marginRight: "auto", maxWidth: "50%" }}>
        <Stack
          direction={"column"}
          spacing={5}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack direction={"row"}>
            <Typography
              variant="body2"
              sx={{ color: "#333333", fontWeight: 800, fontSize: "18px" }}
            >
              Welcome, &nbsp;
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#333333", fontWeight: 300, fontSize: "18px" }}
            >
              How would you like to get started?
            </Typography>
          </Stack>
          <Box>
            <Stack direction={"row"} spacing={4}>
              <Paper
                elevation={3}
                sx={{
                  paddingY: 17,
                  width: "340px",
                  height: "230px",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: storedAuth?.role === "User" ? 0.5 : 1, // Set opacity to make it look grayed out
                  pointerEvents: storedAuth?.role === "User" ? "none" : "auto",
                }}
              >
                <Stack
                  component={storedAuth?.role === "User" ? Box : Link}
                  to={"/createnewform"}
                  sx={{ textDecoration: "none" }}
                  direction={"column"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <img alt="logos" src={addIcon} width={"34px"} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#344054",
                      fontWeight: 500,
                      fontSize: "16.5px",
                    }}
                  >
                    Register & Create Assessment
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#0B41AD",
                      fontWeight: 500,
                      fontSize: "16.5px",
                    }}
                  >
                    FROM LIBRARY
                  </Typography>
                </Stack>
              </Paper>
              <Typography
                variant="body2"
                sx={{
                  color: "#333333",
                  fontWeight: 400,
                  fontSize: "17.5px",
                  alignSelf: "center",
                }}
              >
                Or
              </Typography>
              <Paper
                elevation={3}
                sx={{
                  paddingY: 17,
                  width: "340px",
                  borderRadius: 3,
                  height: "230px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/*<Stack  component={Link} to={"/testsetupprocess"} sx={{textDecoration:"none"}} direction={"column"} spacing={2} alignItems={"center"}>*/}
                <Stack
                  onClick={handleSkipToTest}
                  component={Link}
                  sx={{ textDecoration: "none" }}
                  direction={"column"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <img alt="logos" src={EditIcon} width={"34px"} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#344054",
                      fontWeight: 500,
                      fontSize: "16.5px",
                    }}
                  >
                    Skip & Create Direct Assessment
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#0B41AD",
                      fontWeight: 500,
                      fontSize: "16.5px",
                    }}
                  >
                    FROM LIBRARY
                  </Typography>
                </Stack>
              </Paper>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default CreateAssessment;
