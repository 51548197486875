import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Collapse,
  FormHelperText,
  Backdrop,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
// import axios from "axios";
import { Box, Stack, Typography } from "@mui/joy";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SuccessAnimation from "./SuccessAnimation";
import GetSkillCategory from "../API/GetSkillCategory";
import { FindSkillCategorybyName } from "../API/FindSkillCategorybyName";
import RegisterCandidate from "../API/RegisterCandidate";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RegistrationForm = () => {
  const [skills, setSkills] = useState([]);
  const [response, setResponse] = useState([]);
  const [selected, setSelected] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    qualification: "",
    yearsOfExperience: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (!skills.length) {
      GetSkillCategory().then((response) => {
        setSkills(response.data);
      });
    }
  }, []); // eslint-disable-line

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    let emparray = specialization
      .map((name) => `subcatagoryIds=${name.id}&`)
      .join("");

    const apidata = {
      name: formData.name,
      email: formData.email,
      phone: Number(formData.phone),
      address: formData.address,
      qualification: formData.qualification,
      yearOfExperience: Number(formData.yearsOfExperience),
    };

    const navStateVal = { formData: formData, skipReg: true };

    try {
      setIsLoading(true);

      RegisterCandidate(emparray.substring(0, emparray.length - 1), apidata)
        .then((res) => {
          if (res.status === 200) {
            setIsSuccess(true);
            setTimeout(() => {
              navigate("/testsetupprocess", {
                state: { navStateVal: navStateVal, regUserId: res?.data?.id },
              });
            }, 3500);
            setIsLoading(false);
          } else {
            toast.error("Failed");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.data === "Your email id already exists") {
            toast.error("Mail ID Exists");
          } else {
            toast.error("Some Error Occured");
          }
        });
    } catch (error) {
      setIsLoading(false);
      if (error.response.data === "Your email id already exists") {
        toast.error("Mail ID Exists");
      } else {
        toast.error("Some Error Occured");
      }
    }
  };

  const formHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const specializationHandleChange = (event) => {
    formHandleChange(event);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSpecialization([]);
      return;
    }
    setSpecialization(value);
  };

  const handleChangeSkillSelect = (event) => {
    formHandleChange(event);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === skills.length ? [] : skills);
      return;
    }
    setSelected(value);
    let getID = value?.map((dt) => `topicSubCategoryId=${dt.id}&`);

    if (getID.length !== 0) {
      FindSkillCategorybyName(getID.join("")).then((response) =>
        setResponse(response.data)
      );
    } else {
      setResponse([]);
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name) {
      isValid = false;
      errors["name"] = "Please enter your name";
    }

    if (!formData.email) {
      isValid = false;
      errors["email"] = "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors["email"] = "Please enter a valid email address";
    }

    if (!formData.phone) {
      isValid = false;
      errors["phone"] = "Please enter your phone number";
      // } else if (!/^\d+$/.test(formData.phone)) {
    } else if (
      // eslint-disable-next-line
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        formData.phone
      )
    ) {
      isValid = false;
      errors["phone"] = "Please enter a valid phone number";
    }

    if (!formData.qualification) {
      isValid = false;
      errors["qualification"] = "Please enter your qualification";
    }

    if (!formData.yearsOfExperience) {
      isValid = false;
      errors["yearsOfExperience"] = "Please enter your years of experience";
    } else if (!/^\d+$/.test(formData.yearsOfExperience)) {
      isValid = false;
      errors["yearsOfExperience"] = "Please enter a valid number";
    }

    if (!formData.address) {
      isValid = false;
      errors["address"] = "Please enter your address";
    }
    if (!formData.selected) {
      isValid = false;
      errors["selected"] = "Select atleast one ";
    } else if ((formData?.selected).length === 0) {
      isValid = false;
      errors["selected"] = "Select atleast one";
    }

    if (!formData.specialization) {
      isValid = false;
      errors["specialization"] = "Select atleast one ";
    } else if ((formData?.specialization).length === 0) {
      isValid = false;
      errors["specialization"] = "Select atleast one";
    }
    // if (!formData.specialization) {
    //   isValid = false;
    //   errors["specialization"] = "Select atleast one";
    // }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "91vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#EBECF1",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isSuccess && <SuccessAnimation color="green" />}
      <Collapse in={!isSuccess} timeout={500}>
        <Box
          paddingY={4}
          sx={{ width: "60vw", backgroundColor: "white", borderRadius: 14 }}
        >
          <form action="">
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
                fontSize: "20px",
                fontFamily: "manrope",
              }}
            >
              Registration
            </Typography>

            <Stack
              direction={"row"}
              spacing={8}
              paddingX={10}
              paddingY={3}
              sx={{ minWidth: "100%" }}
            >
              <TextField
                placeholder="Full Name"
                label="Name"
                name="name"
                value={formData.name}
                onChange={formHandleChange}
                variant="outlined"
                fullWidth
                error={formErrors["name"]}
                helperText={formErrors["name"]}
              />

              <TextField
                type="email"
                placeholder="organize@gmail.com"
                label="Email"
                name="email"
                onChange={formHandleChange}
                value={formData.email}
                variant="outlined"
                fullWidth
                required
                error={formErrors["email"]}
                helperText={formErrors["email"]}
              />
            </Stack>
            <Stack
              direction={"row"}
              spacing={8}
              paddingBottom={3}
              paddingX={10}
              sx={{ minWidth: "100%" }}
            >
              <TextField
                placeholder="0967889990"
                label="Mobile Number"
                name="phone"
                onChange={formHandleChange}
                value={formData.mobileNumber}
                variant="outlined"
                fullWidth
                required
                error={formErrors["phone"]}
                helperText={formErrors["phone"]}
              />

              <TextField
                placeholder="BCA"
                label="Qualification"
                onChange={formHandleChange}
                value={formData.qualification}
                name="qualification"
                variant="outlined"
                fullWidth
                required
                error={formErrors["qualification"]}
                helperText={formErrors["qualification"]}
              />
            </Stack>
            <Stack
              direction={"row"}
              spacing={8}
              paddingX={10}
              sx={{ width: "100%" }}
            >
              <Stack
                direction={"column"}
                spacing={3}
                sx={{ minWidth: "46%", maxWidth: "46%" }}
              >
                <TextField
                  placeholder="2"
                  label="Years of Experience"
                  onChange={formHandleChange}
                  value={formData.yearsOfExperience}
                  name="yearsOfExperience"
                  variant="outlined"
                  fullWidth
                  required
                  error={formErrors["yearsOfExperience"]}
                  helperText={formErrors["yearsOfExperience"]}
                />

                <FormControl
                  sx={{ textOverflow: "hidden" }}
                  error={formErrors["selected"]}
                >
                  <InputLabel id="mutiple-select-label">
                    Skill Category
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={selected}
                    name="selected"
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "pre",
                    }}
                    onChange={(e) => handleChangeSkillSelect(e)}
                    input={<OutlinedInput label="Skill Category" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.skillCategoryName).join(", ")
                    }
                    MenuProps={MenuProps}
                    // error={formErrors["selected"]}
                    helperText={formErrors["selected"]}
                  >
                    {skills.map((option) => (
                      <MenuItem key={option.id} value={option}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.skillCategoryName} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formErrors["selected"]}</FormHelperText>
                </FormControl>

                <FormControl
                  fullWidth
                  error={formErrors["specialization"]}
                  disabled={response.length === 0}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Specialization
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={specialization}
                    name="specialization"
                    onChange={specializationHandleChange}
                    input={<OutlinedInput label="Specialization" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.topicName).join(", ")
                    }
                    MenuProps={MenuProps}
                    error={formErrors["specialization"]}
                    helperText={formErrors["specialization"]}
                  >
                    {response.map((name) => (
                      <MenuItem key={name.topicName} value={name}>
                        <Checkbox checked={specialization.indexOf(name) > -1} />
                        <ListItemText primary={name.topicName} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formErrors["specialization"]}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Box sx={{ width: "100%" }}>
                <TextField
                  label="Address"
                  value={formData.address}
                  multiline
                  rows={8}
                  placeholder="address"
                  variant="outlined"
                  onChange={formHandleChange}
                  name="address"
                  fullWidth
                  required
                  error={formErrors["address"]}
                  helperText={formErrors["address"]}
                />
              </Box>
            </Stack>

            <Box
              paddingTop={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "50px",
              }}
            >
              <Button
                style={{
                  borderWidth: 2,
                  color: "red",
                  borderColor: "red",
                  fontFamily: "manrope",
                }}
                variant="outlined"
                color="error"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              {isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                <Button
                  variant="contained"
                  style={{ background: "#908db8", fontFamily: "manrope" }}
                  onClick={handleSubmit}
                >
                  Register
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Collapse>
    </Box>
  );
};

export default RegistrationForm;
