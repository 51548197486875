import { axiosInstance } from "./Api";

const SubmitUserTest = (
  questionIds,
  answerIds,
  userName,
  userEmailId,
  filterTopicId,
  totalTime,
  testId
) => {
  return axiosInstance.post(
    `testResult/submit-result?${questionIds}${answerIds}studentName=${userName}&emailId=${userEmailId}&timeSpent=${totalTime}${filterTopicId}&testResultId=${testId}`
  );
};

export default SubmitUserTest;
