import { axiosInstance } from "./Api";

const CandidateResultPage = (encryptedId) => {
  return axiosInstance.get(
    `userScoreTracking/candidate-result-page?encryptedText=${encodeURIComponent(
      encryptedId
    )}`
  );
};

export default CandidateResultPage;
