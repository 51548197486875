import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@material-ui/core";

import "./Configuration.css";
import React, { useEffect, useState } from "react";

import UserConfiguration from "../../API/UserConfiguration";
import UserConfigurationRole from "../../API/UserConfigurationRole";
import UserConfigurationRoleLevel from "../../API/UserConfigurationRoleLevel";
import UserConfigurationRoleLevelEdit from "../../API/UserConfigurationRoleLevelEdit";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  headerAlign: "center",
  color: theme.palette.text.secondary,
}));

// const mockData = [
//   {
//     username: "Imthyaz Sheriff C",
//     id: 1000,
//     userRole: "Talent Head",
//     userLevel: "Level 1",
//   },
//   {
//     username: "GugaPriya",
//     id: 1001,
//     userRole: "Talent Head",
//     userLevel: "Level 2",
//   },
//   {
//     username: "Subhashini Sithivinayagam",
//     id: 1002,
//     userRole: "Admin",
//     userLevel: "Level 1",
//   },
//   // Add more data items as needed
// ];

function Configuration({ isDashboard }) {
  const [mainTableData, setMainTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [levels, setLevels] = useState([]);
  const [keyForForceRender, setKeyForForceRender] = useState(0);

  useEffect(() => {
    const storedAuth = JSON.parse(sessionStorage.getItem("auth"));
    const userId = storedAuth?.userId;

    if (userId) {
      Promise.all([
        UserConfiguration(userId),
        UserConfigurationRole(),
        UserConfigurationRoleLevel(),
      ])
        .then(([userConfigResponse, roleResponse, roleLevelResponse]) => {
          const flatData = [].concat(...userConfigResponse.data);
          const customSort = (a, b) => {
            const roleOrder = { "Talent Head": 1, Admin: 2, User: 3 };
            const roleComparison = roleOrder[a.role] - roleOrder[b.role];
            return roleComparison !== 0
              ? roleComparison
              : a.roleLevel - b.roleLevel;
          };
          const sortedData = flatData.sort(customSort);
          setMainTableData(sortedData);
          setTableData(sortedData);
          setRoles(roleResponse.data);
          setLevels(roleLevelResponse.data);
        })
        .catch((error) => console.log("Error fetching data:", error));
    } else {
      console.error("User ID not found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    // Update table data when mainTableData changes
    setTableData(mainTableData); // Create a copy of mainTableData
  }, [mainTableData]);

  const handleSearchName = (e) => {
    const value = e.target.value;
    if (value && value?.length >= 1) {
      const _searchData = mainTableData.filter(
        (item) =>
          (item.name &&
            item.name
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())) ||
          (item.id &&
            item.id
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase()))
      );
      setTableData(_searchData);
    } else {
      setTableData(mainTableData);
    }
  };

  const SearchInput = (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid #ddd",
        borderRadius: "6px",
        boxShadow: "none",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="search" disabled>
        <SearchIcon sx={{ color: "#908db8" }} />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: "14px" }}
        placeholder="Search ( Name | #Id )"
        onChange={handleSearchName}
        inputProps={{ "aria-label": "search name" }}
      />
    </Paper>
  );

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //     },
  //   },
  // };

  const columns = [
    {
      field: "username",
      headerName: "NAMES",
      // minWidth: 150,
    },
    {
      field: "userRole",
      headerName: "ROLE",
      // minWidth: 150,
    },
    {
      field: "userLevel",
      headerName: "LEVEL",
      // minWidth: 150,
    },
    {
      field: "progress",
      headerName: "",
      // minWidth: 10,
    },
    {
      field: "progress",
      headerName: "",
      // minWidth: 10,
    },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const useStyles = makeStyles({
    tableRow: {
      height: "10px",
      padding: "0px",
    },
    tableCell: {
      padding: "8px !important",
    },
  });

  const classes = useStyles();

  // const handleRoleChange = (id, value) => {
  //   setTableData((prevData) =>
  //     prevData.map((row) =>
  //       row.id === id
  //         ? { ...row, role: value, disableLevelDropdown: value === "User" }
  //         : row
  //     )
  //   );
  // };

  const handleRoleChange = (id, value) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id
          ? {
              ...row,
              role: value,
              roleLevel: value === "User" ? 1 : row.roleLevel, // Set default level to 1 if role is "User"
              disableLevelDropdown: value === "User",
            }
          : row
      )
    );
  };

  const handleLevelChange = (id, value) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, roleLevel: value } : row
      )
    );
  };

  // const handleEditClick = (id) => {
  //   setTableData((prevData) =>
  //     prevData.map((row) =>
  //       row.id === id
  //         ? {
  //             ...row,
  //             isEditing: true,
  //             originalData: { role: row.role, level: row.roleLevel },
  //             disableLevelDropdown: row.role === "User",
  //             defaultLevel: row.role === "User" ? 1 : row.roleLevel,
  //           }
  //         : row
  //     )
  //   );
  // };

  const handleEditClick = (id) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id
          ? {
              ...row,
              isEditing: true,
              originalData: { role: row.role, level: row.roleLevel },
              disableLevelDropdown: row.role === "User",
            }
          : row
      )
    );
  };

  const handleSaveClick = async (id) => {
    const updatedRow = tableData.find((row) => row.id === id);

    // Prepare the data to be sent to the API
    const requestBody = [
      {
        userId: updatedRow.id,
        // roleId: updatedRow.roleId,
        roleId:
          updatedRow.role === roles[0].roleName
            ? 1
            : updatedRow.role === roles[1].roleName
            ? 2
            : 3,
        levelId: updatedRow.roleLevel, // Assuming roleLevel is a valid levelId
      },
    ];

    // Call the API function
    try {
      const response = await UserConfigurationRoleLevelEdit(requestBody); // eslint-disable-line

      // Update the state or perform any other actions if needed

      // Update the local state to reflect that the editing is done
      setTableData((prevData) =>
        prevData.map((row) =>
          row.id === id ? { ...row, isEditing: false, originalData: null } : row
        )
      );
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error, e.g., show an error message to the user
    }

    const storedAuth = JSON.parse(sessionStorage.getItem("auth"));
    const userId = storedAuth?.userId;
    UserConfiguration(userId)
      .then((response) => {
        const flatData = [].concat(...response.data);
        const customSort = (a, b) => {
          const roleOrder = { "Talent Head": 1, Admin: 2, User: 3 };
          const roleComparison = roleOrder[a.role] - roleOrder[b.role];
          return roleComparison !== 0
            ? roleComparison
            : a.roleLevel - b.roleLevel;
        };
        const sortedData = flatData.sort(customSort);
        setMainTableData(sortedData);
        setTableData(sortedData);
      })
      .catch((error) => console.log(error));

    setKeyForForceRender((prevKey) => prevKey + 1);
  };

  const handleCancelClick = (id) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id
          ? {
              ...row,
              isEditing: false,
              role: row.originalData.role,
              roleLevel: row.originalData.level,
            }
          : row
      )
    );
    setKeyForForceRender((prevKey) => prevKey + 1);
  };

  return (
    <div className="card" key={keyForForceRender}>
      <Typography sx={{ mb: -4, width: "40%" }}>{SearchInput}</Typography>
      <Stack
        spacing={2}
        sx={{
          height: "100vh",
          position: "relative",
        }}
      >
        <Item
          sx={{
            borderRadius: "10px",
            boxShadow: "none",
            p: 0,
            // height: "100%",

            // border: "1px solid #ddd",
          }}
        >
          <Box
            sx={{
              width: "100%",
              // height: "550px",
            }}
          >
            <TableContainer
              sx={{
                // mb: 4,
                // maxHeight: isDashboard ? 400 : 600,
                maxHeight: 380,
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          sx={{
                            color: "#A3A3A3",
                            fontFamily: "Manrope",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            textTransform: "uppercase",
                            // pl: columns.pl,
                          }}
                          key={column.id}
                        >
                          {column.headerName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "::-webkit-scrollbar-track": {
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                      borderRadius: "10px",
                      backgroundColor: "rgb(179, 177, 177)",
                    },

                    "::-webkit-scrollbar": {
                      width: "5px",
                      backgroundColor: "#FFFFFF",
                    },

                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "10px",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                      backgroundColor: "rgb(136, 136, 136)",
                    },
                  }}
                >
                  {tableData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          className={classes.tableRow}
                          key={row.id}
                        >
                          <TableCell
                            className={classes.tableCell}
                            style={{ width: 250 }}
                          >
                            <div
                              style={{
                                // width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                gap: 20,
                                // justifyContent: "space-between",
                              }}
                            >
                              <Avatar
                                sx={{ bgcolor: "grey" }}
                                alt={row.name}
                                src="/broken-image.jpg"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#000",
                                    fontFamily: "Manrope",
                                    fontSize: "13px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                  }}
                                >
                                  {row.name}
                                </div>
                                <div
                                  style={{
                                    color: "#8D8D8D",
                                    fontFamily: "Manrope",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                  }}
                                >
                                  # {row.id}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ width: 100 }}
                          >
                            <FormControl fullWidth size="small">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={row.role}
                                size="small"
                                sx={{
                                  fontSize: "12px",
                                  width: 200,
                                  fontWeight: "800",
                                  fontFamily: "Manrope",
                                }}
                                onChange={(e) =>
                                  handleRoleChange(row.id, e.target.value)
                                }
                                disabled={!row.isEditing}
                              >
                                {roles.map((role) => (
                                  <MenuItem
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: "800",
                                      fontFamily: "Manrope",
                                    }}
                                    key={role.id}
                                    value={role.roleName}
                                  >
                                    {role.roleName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ width: 100 }}
                          >
                            <FormControl fullWidth size="small">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={row.roleLevel}
                                size="small"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "800",
                                  width: 200,
                                  fontFamily: "Manrope",
                                }}
                                onChange={(e) =>
                                  handleLevelChange(row.id, e.target.value)
                                }
                                disabled={
                                  !row.isEditing ||
                                  row.disableLevelDropdown ||
                                  true
                                }
                              >
                                {levels.map((level) => (
                                  <MenuItem
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: "800",
                                      fontFamily: "Manrope",
                                    }}
                                    key={level.roleLevelId}
                                    value={level.roleLevel}
                                  >
                                    Level {level.roleLevel}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {/* {row.userLevel} */}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ width: 30 }}
                          >
                            {row.isEditing ? (
                              <Box sx={{ display: "flex" }}>
                                <IconButton
                                  aria-label="save"
                                  size="small"
                                  onClick={() => handleSaveClick(row.id)}
                                >
                                  <SaveIcon
                                    fontSize="small"
                                    sx={{ color: "#908db8" }}
                                  />
                                </IconButton>
                                <IconButton
                                  aria-label="cancel"
                                  size="small"
                                  onClick={() => handleCancelClick(row.id)}
                                >
                                  <CloseIcon
                                    fontSize="small"
                                    sx={{ color: "#f50000" }}
                                  />
                                </IconButton>
                              </Box>
                            ) : (
                              <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => handleEditClick(row.id)}
                              >
                                <EditIcon
                                  fontSize="small"
                                  sx={{ color: "#908db8" }}
                                />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ width: 30 }}
                          ></TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {tableData?.length > 0 && (
                    <TableRow
                      style={{
                        height: 50 * tableData?.length,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
              {tableData?.length === 0 || tableData === undefined ? (
                <Typography sx={{ mt: 10, mb: 5 }} variant="h5">
                  No Data Found
                </Typography>
              ) : (
                ""
              )}
            </TableContainer>
            <TablePagination
              sx={{
                paddingTop: "10px !important",
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
              rowsPerPageOptions={[5, 10, 100]}
              component="div"
              count={tableData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Item>
      </Stack>
    </div>
  );
}

export default Configuration;
