import axios from "axios";

// Create an instance of Axios with default configuration
export const axiosInstance = axios.create({
  baseURL:
    window.location.origin === "https://assessment-portaldev.ideassionlive.in"
      ? process.env.React_App_Dev
      : window.location.origin ===
        "https://assessment-portalqa.ideassionlive.in"
      ? process.env.React_App_Qa
      : process.env.React_App_Local, // Set your API base URL here
  timeout: 50000, // Set a timeout for requests (optional)
});

export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// // Add a response interceptor to handle 403 errors globally
// axiosInstance.interceptors.response.use(
//   (response) => {
//     // If the request was successful, return the response
//     return response;
//   },
//   async (error) => {
//     // If the request resulted in a 403 error, handle it here
//     if (error.response && error.response.status === 401) {
//       // Exclude the /refresh endpoint from the interceptor
//       if (error.config.url !== "/refresh") {
//         console.error("403 error:", error.message);
//         try {
//           // Your logic to refresh the token (assuming setAuthToken is a function that sets the new token)
//           const res = await axiosInstance.post("/refresh");
//           setAuthToken(res.data.refreshToken);
//           // Retry the original request after token refresh
//           return axiosInstance(error.config);
//         } catch (refreshError) {
//           console.error("Error refreshing token:", refreshError.message);
//           // Handle refresh error, e.g., redirect to login page
//         }
//       }
//     }

//     // If it's not a 403 error or not the /refresh endpoint, pass the error along
//     return Promise.reject(error);
//   }
// );

// // Function to make a GET request
// export const getApiData = (endpoint, params) => {
//   return axiosInstance.get(endpoint, { params });
// };

// // Function to make a POST request
// export const postApiData = (endpoint, data) => {
//   return axiosInstance.post(endpoint, data);
// };

// // Function to make a GET request
// export const putApiData = (endpoint, data) => {
//   return axiosInstance.put(endpoint, data);
// };

// // Function to make a POST request
// export const deleteApiData = (endpoint, data) => {
//   return axiosInstance.delete(endpoint, data);
// };
