import * as React from "react";
import "./App.css";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import smallLogo from "../Assets/image-removebg-box.png";
import bigLogo from "../Assets/image-removebg.png";
import HomeIcon from "../Assets/sideHome.png";
import GroupIcon from "../Assets/sideGroup.png";
import AddAssess from "../Assets/sideAddAssess.png";
// import IconTest from "../Assets/sideIconTest.svg";
import TestIcon from "../Assets/sideTest.png";
import SuperAdmin from "../Assets/superadmin-icon.png";
import Configuration from "../Assets/configuration-icon.png";
import LogOut from "../Assets/logout-icon.png";
import expiredImage from "../Assets/timeoutexpired.png";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { toggleExpiry } from "../Redux/Stepper";
import { setAuthToken } from "../API/Api";
import LogoutApi from "../API/LogoutApi";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../AzureActiveDirectory/authConfig";

// import SearchIcon from "@mui/icons-material/Search";
// import settingsIcon from "../Assets/sideGear.png";
// import ArrowIcon from "../Assets/sideArrow.png";
// import HelpIcon from "../Assets/sideHelp.png";
// import RecordIcon from "../Assets/sideRecord.png";

// import { InputAdornment, TextField } from "@mui/material";
// import { useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,

    duration: theme.transitions.duration.enteringScreen,
  }),

  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,

    duration: theme.transitions.duration.leavingScreen,
  }),

  overflowX: "hidden",

  width: `calc(${theme.spacing(7)} + 10px)`,

  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  justifyContent: "flex-end",

  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar

  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,

    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: drawerWidth,

    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,

      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,

  whiteSpace: "nowrap",

  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),

    "& .MuiDrawer-paper": openedMixin(theme),
  }),

  ...(!open && {
    ...closedMixin(theme),

    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AppBarNav() {
  const { instance } = useMsal();
  const location = useLocation();
  const timeoutOpen = useSelector((state) => state.stepper.expired);
  let hideSideBar = location.pathname === "/ireport" ? "none" : "flex";
  React.useEffect(() => {
    if (location.pathname === "/ireport") {
      setOpen(false);
    }
  }, [location.pathname]);
  const dispatch = useDispatch();
  // const [searchTerm, setSearchTerm] = useState("");

  // const handleChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  const storedAuth = JSON.parse(sessionStorage.getItem("auth"));
  const [open, setOpen] = React.useState(false);

  const handleClearSession = () => {
    dispatch(toggleExpiry(false));
    handleLogOut();
    //
    // const storedAuth = JSON.parse(sessionStorage.getItem("auth"));
    // const loginUserId = storedAuth?.userId;
    // LogoutApi(loginUserId).catch((err) => console.log(err));
    // sessionStorage.removeItem("auth");
    // setAuthToken(false);
    // dispatch(toggleExpiry(false));
    // navigate("/welcome");
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();

  const iconSize = "21px";

  const navList = [
    {
      pathName: "/",
      iconTag: <img alt="logos" src={HomeIcon} width={iconSize} />,
      text: "Dashboard",
      userAllowed: false,
      adminAllowed: true,
    },

    {
      pathName: "/testlibrary",
      iconTag: <img alt="logos" src={TestIcon} width={iconSize} />,
      text: "Libraries",
      userAllowed: false,
      adminAllowed: true,
    },

    {
      pathName: "/allreport",
      iconTag: <img alt="logos" src={GroupIcon} width={iconSize} />,
      text: "Results",
      userAllowed: false,
      adminAllowed: true,
      separator: <Divider />,
    },

    {
      pathName: "/createassessment",
      iconTag: <img alt="logos" src={AddAssess} width={iconSize} />,
      text: "New Assessment",
      userAllowed: true,
      adminAllowed: true,
    },
    // {
    //   pathName: "",
    //   iconTag: <img alt="logos" src={RecordIcon} width={iconSize} />,
    //   text: "Saved Assessment",
    // },
    // {
    //   pathName: "",
    //   iconTag: <img alt="logos" src={ArrowIcon} width={iconSize} />,
    //   text: "Ongoing Assessment",
    //   separator: <Divider />,
    // },
    // {
    //   pathName: "",
    //   iconTag: <img alt="logos" src={settingsIcon} width={iconSize} />,
    //   text: "Settings",
    // },
  ];

  // PROFILE MENU START
  const [anchorElProfileMenu, setAnchorElProfileMenu] = React.useState(null);
  const openProfileMenu = Boolean(anchorElProfileMenu);
  const handleClickProfileMenu = (event) => {
    setAnchorElProfileMenu(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setAnchorElProfileMenu(null);
  };
  // PROFILE MENU END

  function handleLogOut() {
    const storedAuth = JSON.parse(sessionStorage.getItem("auth"));
    const loginUserId = storedAuth?.userId;
    const loginMethod = storedAuth?.loginType;

    if (loginMethod === "Microsoft Login") {
      instance.logoutPopup(loginRequest).catch((e) => {
        console.log(e);
      });
      LogoutApi(loginUserId).catch((err) => console.log(err));
      sessionStorage.removeItem("auth");
      setAuthToken(false);
      navigate("/welcome");
      // Clear the session storage
      sessionStorage.clear();
    } else {
      LogoutApi(loginUserId).catch((err) => console.log(err));
      sessionStorage.removeItem("auth");
      setAuthToken(false);
      navigate("/welcome");
      // Clear the session storage
      sessionStorage.clear();
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "#F7F7F7", height: "10vh", zIndex: 900 }}
      >
        <Toolbar sx={{ mb: 2 }}>
          {/* <img src={smallLogo} width={"40px"} style={{...(open && { display: "none" })}}/> */}

          {/* <TextField
            id="search"
            type="search"
            // label="Search"
            placeholder="Search"
            value={searchTerm}
            onChange={handleChange}
            size="small"
            sx={{
              minWidth: "10%",
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            {/* <IconButton>
              <img alt="logos" src={HelpIcon} />
            </IconButton>
            <IconButton>
              <img alt="logos" src={HelpIcon} />
            </IconButton> */}

            {/* <Box sx={{ borderLeft: "1px solid #C5C5C5" }}>
              <IconButton sx={{ marginLeft: "6px" }}>
                <img alt="logos" src={IconTest} />
              </IconButton>
            </Box> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                borderLeft: "1px solid #C5C5C5",
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClickProfileMenu}
                  size="small"
                  sx={{ marginLeft: "6px" }}
                  aria-controls={openProfileMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfileMenu ? "true" : undefined}
                >
                  <Avatar
                    sx={{
                      bgcolor: "grey",
                      // width: isDashboard && "30px",
                      // height: isDashboard && "30px",
                      // fontSize: isDashboard && "16px",
                    }}
                    // alt={row.username}
                    src="/broken-image.jpg"
                  />
                </IconButton>
              </Tooltip>
            </Box>

            <Menu
              anchorEl={anchorElProfileMenu}
              id="account-menu"
              open={openProfileMenu}
              onClose={handleCloseProfileMenu}
              onClick={handleCloseProfileMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,

                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={handleCloseProfileMenu}
                sx={{
                  fontWeight: 600,
                  fontFamily: "Manrope",
                  fontSize: "14px",
                }}
              >
                <ListItemIcon>
                  <img src={SuperAdmin} alt="" width={20} height={20} />
                </ListItemIcon>
                {storedAuth?.role}
              </MenuItem>

              {storedAuth?.role !== "User" && (
                <Link
                  to="/Configuration"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <MenuItem
                    sx={{
                      fontWeight: 600,
                      fontFamily: "Manrope",
                      fontSize: "14px",
                    }}
                  >
                    <ListItemIcon>
                      <img src={Configuration} alt="" width={20} height={20} />
                    </ListItemIcon>
                    Configuration
                  </MenuItem>
                </Link>
              )}

              <MenuItem
                onClick={handleLogOut}
                sx={{
                  fontWeight: 600,
                  fontFamily: "Manrope",
                  fontSize: "14px",
                }}
              >
                <ListItemIcon>
                  <img src={LogOut} alt="" width={20} height={20} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Stack>

          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
        </Toolbar>
      </AppBar>

      <Drawer
        className="boxShadowDrawer"
        sx={{
          "& .MuiDrawer-paper": {
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
            backgroundColor: "#F7F7F7",
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            overflowY: "hidden",
            "&::-webkit-scrollbar": { display: "none" },
          },
          overflowY: "hidden",
          display: hideSideBar,
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader sx={{ justifyContent: "center" }}>
          <img
            alt="logos"
            src={bigLogo}
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              ...(open === false && { display: "none" }),
            }}
            width={"180px"}
          />
          <img
            alt="logos"
            src={smallLogo}
            width={"60px"}
            style={{
              marginTop: "10px",
              marginBottom: "4px",
              ...(open && { display: "none" }),
            }}
          />
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
              mb: 3,
            }}
            disableRipple
          >
            <IconButton
              sx={{
                position: "fixed",
                top: "12.5vh",
                left: !open ? "3.5vw" : "220px",
                zIndex: 10,
                color: "black",
                alignSelf: "flex-end",
                borderRadius: "50%",
                backgroundColor: "white",
                fontFamily: "Inter",
                boxShadow: "0px 4px 4px 0px #00000040",
                transition: "left 0.3s ease",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={handleDrawerOpen}
            >
              {!open ? (
                <ChevronRightIcon color="inherit" />
              ) : (
                <ChevronLeftIcon color="inherit" />
              )}
            </IconButton>
          </ListItemButton>
          {navList.map((data, index) => {
            const isActive = location.pathname === data.pathName;
            return (
              <div key={index + 1}>
                {/* <NavLink to={data.pathName} style={{textDecoration:"none"}}> */}
                <ListItem
                  component={NavLink}
                  to={data.pathName}
                  disablePadding={!open ? true : false}
                  key={index + 1}
                  sx={{
                    marginTop: "7px",
                    textDecoration: "none",
                    color: "#908DB8",
                    display:
                      storedAuth?.role === "User"
                        ? data?.userAllowed
                          ? "block"
                          : "none"
                        : storedAuth?.role === "Talent Head" ||
                          ("Admin" && "Block"),
                    backgroundColor: isActive
                      ? "rgba(144, 141, 184, 0.2)"
                      : "transparent",
                    "&:hover": { backgroundColor: "rgba(144, 141, 184, 0.2)" },
                  }}
                >
                  <ListItemButton
                    disableRipple
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                  >
                    <ListItemIcon sx={{ color: "black" }}>
                      {data.iconTag}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13px",
                        fontWeight: "700",
                      }}
                      primary={data.text}
                    />
                  </ListItemButton>
                </ListItem>
                {/* </NavLink> */}
                {storedAuth?.role !== "User" && data.separator}
              </div>
            );
          })}
          {/* <ListItem
            disablePadding={!open ? true : false}
            sx={{ marginTop: "55%" }}
          >
            <ListItemButton
              // onClick={() => {
              //   instance.logoutPopup(loginRequest).catch((e) => {
              //     console.log(e);
              //   });
              //   sessionStorage.removeItem("auth");
              //   setAuthToken(false);
              //   navigate("/welcome");
              // }}
              onClick={handleLogOut}
            >
              <ListItemIcon sx={{ color: "black" }}>
                <img alt="logos" src={LogOut} />
              </ListItemIcon>
              <ListItemText
                primary={"Logout"}
                primaryTypographyProps={{ color: "red", fontWeight: 600 }}
              />
            </ListItemButton>
          </ListItem> */}
        </List>
        {/* <Divider /> */}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />

        <ToastContainer />
        <Outlet />
        <Dialog
          open={timeoutOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                py: 8,
                alignContent: "center",
                alignItems: "center",
                width: "550px",
                height: "450px",
              }}
            >
              <img width={"150px"} src={expiredImage} alt="expiredImaged" />
              <Typography
                sx={{
                  fontFamily: "manrope",
                  fontWeight: 800,
                  fontSize: "25px",
                  color: "#494d52",
                }}
              >
                Your session has expired
              </Typography>
              <Typography
                sx={{
                  fontFamily: "inter",
                  fontWeight: 500,
                  fontSize: "18px",
                  textAlign: "center",
                  color: "#777b80",
                  px: 5,
                }}
              >
                Please log in again to access the application and continue your
                progress.
              </Typography>
              <Button
                sx={{
                  borderRadius: "50px",
                  color: "white",
                  fontSize: "12px",
                  bgcolor: "#1764E8",
                  ":hover": {
                    bgcolor: "#427adb",
                  },
                  fontFamily: "inter",
                }}
                variant="contained"
                onClick={handleClearSession}
              >
                Back to Login
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}
