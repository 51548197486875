import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterDropdown: [],
  filterValue: "",
  dropDownData: [],
  product: [],
};

const secondStepSlice = createSlice({
  name: "secondStepForm",
  initialState,
  reducers: {
    setFilterDropdown: (state, action) => {
      state.filterDropdown = action.payload;
    },
    setFilterValue: (state, action) => {
      state.filterValue = action.payload;
    },
    setDropDownData: (state, action) => {
      state.dropDownData = action.payload;
    },
    addProduct: (state, action) => {
      const updatedProduct = {
        ...action.payload,
        count: 0,
        count1: 0,
        count2: 0,
        timer: 0,
      };
      state.product = [...state.product, updatedProduct];
    },
    removeProduct: (state, action) => {
      if (action.payload?.length === 0) {
        state.product = [];
      } else {
        const filtered = state.product.filter(
          (val) => val.Name !== action.payload.Name
        );
        state.product = filtered;
      }
    },
    onCountChange: (state, action) => {
      state.product = state.product.map((selectedProduct) =>
        selectedProduct.Name === action.payload.product.Name
          ? action.payload.updatedProduct
          : selectedProduct
      );
    },
    onTestTypeChange: (state, action) => {
      state.product = state.product.map((selectedProduct) =>
        selectedProduct.Name === action.payload.product.Name
          ? action.payload.updatedProduct
          : selectedProduct
      );
    },
  },
});

export default secondStepSlice.reducer;
export const {
  setFilterDropdown,
  setFilterValue,
  setDropDownData,
  addProduct,
  removeProduct,
  onCountChange,
  onTestTypeChange,
} = secondStepSlice.actions;
