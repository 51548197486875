import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UserLeftButtons from "../../Components/UserTest/UserLeftButtons";
import LinearProgress from "@mui/joy/LinearProgress";
import TimerIcon from "@mui/icons-material/Timer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import completedIcon from "../../Assets/completed-icon.svg";
import timerOutIcon from "../../Assets/timeout-icon.svg";
import bigLogo from "../../Assets/image-removebg.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useLocation } from "react-router-dom";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import "./UserTest.css";
import { FetchTestQuestionsforUser } from "../../API/FetchTestQuestionsforUser";
import SubmitUserTest from "../../API/SubmitUserTest";
import AddUserTestScore from "../../API/AddUserTestScore";
import SubmitScoreGradebyUserTest from "../../API/SubmitScoreGradebyUserTest";
import { useTimer } from "./TimerComponent";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// import { HelpOutline, TimelapseOutlined } from "@material-ui/icons";
// import SuccessAnimation from "../../Components/SuccessAnimation";

const drawerWidth = 240;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function UserTest() {
  const [currQuestion, setCurrQuestion] = useState(0);
  const [currQuestionId, setCurrQuestionId] = useState(0);
  const [currTopicIndex, setCurrTopicIndex] = useState(0);
  const [currQuesIndex, setCurrQuesIndex] = useState(0);
  // const [radioVal, setRadioVal] = useState("");
  const [questionData, setQuestionData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [submitResult, setSubmitResult] = useState(null);
  const [submitScore, setSubmitScore] = useState(null);
  const [timeOutModel, setTimeoutModel] = useState(false);
  // const [timeOutCompleteMsg, setTimeOutCompleteMsg] = useState(false);
  // const [totalTime, setTotalTime] = useState(null);
  const location = useLocation();
  const resDetail = location.state.resDetail;
  const loginDetail = location.state.loginDetail;
  const [onSubmitModal, setOnSubmitModal] = useState(false);
  const [questionStatus, setQuestionStatus] = useState(
    questionData?.map((dt) => Array(dt?.questions?.length)?.fill("pending"))
  );
  const [timeExpired, setTimeExpired] = useState(false); // eslint-disable-line
  const [userSubmiited, setuserSubmiited] = useState(false);
  // const [LastQsn, setLastQsn] = useState(false);
  const storageTime = JSON.parse(sessionStorage.getItem("time"));
  // const [milliSeconds, setMilliSeconds] = useState(storageTime?.AssessmentTime);
  // const [options, setOptions] = useState({
  //   event: "click",
  //   placement: "bottom",
  //   offset: 10,
  //   disableBeacon: false,
  //   disableCloseOnEsc: false,
  //   // Add other options here
  // });

  const questionIdsRef = useRef("");
  const answerIdsRef = useRef("");
  const filterTopicIdRef = useRef("");
  const secondQuestionIdsRef = useRef("");
  const secondAnswerIdsRef = useRef("");

  const [submitConfirm, setSubmitConfirm] = React.useState(false);

  const handleClickOpen = () => {
    setSubmitConfirm(true);
  };

  const handleClose = () => {
    setSubmitConfirm(false);
  };

  ////// START: disabling the browser's back and forward buttons and disabling the context menu
  useEffect(() => {
    const disableBackAndForward = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    };

    const disableContextMenu = (event) => {
      event.preventDefault(); // Prevent the default context menu behavior
    };

    const disableReload = (event) => {
      event.preventDefault(); // Prevent the default behavior of the reload button
      event.returnValue = ""; // For older browsers
    };

    const handleKeyDown = (event) => {
      // Prevent default behavior for specific keys (example: F5 for refresh)
      if (event.keyCode === 116) {
        // F5 key
        event.preventDefault();
      }

      // Check if the key combination matches Ctrl+Shift+I or F12
      if (
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
        event.keyCode === 123
      ) {
        event.preventDefault(); // Prevent default action (opening developer tools)
      }
    };

    // Attach event listeners
    document.addEventListener("contextmenu", disableContextMenu);
    window.addEventListener("popstate", disableBackAndForward); // Use popstate event instead of beforeunload for back and forward buttons
    window.addEventListener("beforeunload", disableReload);
    document.addEventListener("keydown", handleKeyDown);

    // Call functions to disable back and forward buttons
    disableBackAndForward();

    // Cleanup function
    return () => {
      // Remove event listeners
      document.removeEventListener("contextmenu", disableContextMenu);
      window.removeEventListener("popstate", disableBackAndForward);
      window.removeEventListener("beforeunload", disableReload);
      document.removeEventListener("keydown", handleKeyDown);
      // Reset the onpopstate handler
      window.onpopstate = null;
    };
  }, []); // This effect runs only once after component mounts
  //////// END: disabling the browser's back and forward buttons and disabling the context menu

  // ...
  // Function to get the filterTopicId string
  const getFilterTopicId = (questionData) => {
    return questionData?.map((dt) => `&categories=${dt.topicId}`).join("");
  };

  // Function to get the questionIds string
  const getQuestionIds = (questionData) => {
    return questionData
      ?.map((data) =>
        data.questions.map((dt) => `questionsIds=${dt.id}&`).join("")
      )
      .join("");
  };

  // Function to get the answerIds string
  const getAnswerIds = (questions) => {
    return questions
      ?.map(
        (data) => `answerIds=${data.correctAnswer ? data.correctAnswer : 0}&`
      )
      .join("");
  };

  // Function to get the secondQuestionIds string
  const getSecondQuestionIds = (questionData) => {
    return questionData
      ?.map((data) => data.questions.map((dt) => dt.id).join(","))
      .join(",");
  };

  // Function to get the secondAnswerIds string
  const getSecondAnswerIds = (questions) => {
    return questions
      ?.map((data) => (data.correctAnswer ? data.correctAnswer : 0))
      .join(",");
  };

  useEffect(() => {
    questionIdsRef.current = getQuestionIds(questionData);
    answerIdsRef.current = getAnswerIds(questions);
    filterTopicIdRef.current = getFilterTopicId(questionData);
    secondQuestionIdsRef.current = getSecondQuestionIds(questionData);
    secondAnswerIdsRef.current = getSecondAnswerIds(questions);
  }, [currQuestionId, questionStatus, questionData, questions]);

  const codeId = location.state.codeId;
  const userDetails = location.state.userDetails;
  const eLink = {
    encryptedText: codeId,
  };

  const { startTimer, remainingTime } = useTimer(storageTime?.AssessmentTime);
  useEffect(() => {
    if (resDetail?.AssessmentTime !== 0) {
      startTimer();
    }
  }, []); // eslint-disable-line

  const fetchQuestions = async () => {
    await FetchTestQuestionsforUser(loginDetail?.testResultId, eLink)
      .then((res) => {
        setQuestionData(res.data);
        let newArr = res.data.map((dt) =>
          Array(dt.questions.length).fill("pending")
        );
        setQuestionStatus(newArr);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setCurrQuestionId(questions[currQuestion]?.id);
  }, [questions]); // eslint-disable-line

  useEffect(() => {
    if (questionData && questionData?.length > 0) {
      const iniArr = [];
      questionData.forEach((data) => {
        data.questions.forEach((dt) => {
          iniArr.push(dt);
        });
      });
      setQuestions(iniArr);
    }
  }, [questionData]);

  const [timerRunning, setTimerRunning] = useState(false);

  // function millisecondsToMinutesAndSeconds(milliseconds) {
  //   const totalSeconds = Math.floor(milliseconds / 1000);
  //   const minutes = Math.floor(totalSeconds / 60);
  //   const seconds = totalSeconds % 60;
  //   return { minutes, seconds };
  // }

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (resDetail?.AssessmentTime !== 0) {
        if (storageTime?.AssessmentTime === 0) {
          if (!userSubmiited) {
            handleSubmitConfirm();
            clearInterval(countdownInterval);
            setTimeExpired(true);
            setTimeoutModel(true);

            setCurrQuestion(questions.length);
            setTimerRunning(true);
          }
        }
      }
    }, 1000);

    if (resDetail?.AssessmentTime === 0) {
      clearInterval(countdownInterval);
    }

    // Cleanup the interval when the component unmounts
    return () => clearInterval(countdownInterval);
  }, [storageTime?.AssessmentTime]); // eslint-disable-line

  // useEffect(() => {
  //   // const newTimeinMins = minutes * 60 + seconds;
  //   sessionStorage.setItem(
  //     "time",
  //     JSON.stringify({
  //       AssessmentTime: milliSeconds || 0,
  //     })
  //   );
  // }, [milliSeconds]);

  useEffect(() => {
    if (!timerRunning) {
      fetchQuestions();
    }
  }, []); // eslint-disable-line

  // Clear the timeout if needed (e.g., when a button is clicked)

  // const { minutes, seconds } = millisecondsToMinutesAndSeconds(milliSeconds);

  const [openSubmitResultModel, setOpenSubmitResultModel] = useState({
    success: false,
    fail: false,
  });

  // useEffect(() => {
  //   if (submitResult?.res === "success") {
  //     SubmitScoreGradebyUserTest(submitResult?.data?.id);
  //   }
  // }, [submitResult]);

  // useEffect(() => {
  //   if (submitScore?.res === "success") {
  //     SubmitScoreGradebyUserTest(submitResult?.data?.id);
  //   }
  // }, [submitScore]);

  const handleDirectSwitch = (index, optionIndex, valId) => {
    let currQuestionVal = questions.findIndex(
      (question) => question.id === valId
    );

    setCurrQuestionId(valId);
    const objectIndex = questionData.findIndex((data) =>
      data.questions.some((q) => q.id === questions[currQuestionVal]?.id)
    );
    const questionIndex = questionData[objectIndex].questions.findIndex(
      (question) => question.id === questions[currQuestionVal]?.id
    );
    setCurrTopicIndex(optionIndex);
    setCurrQuesIndex(questionIndex);
    // if (questionIndex === questionData[objectIndex]?.questions?.length - 1) {
    //   setCurrTopicIndex(objectIndex + 1);
    //   setCurrQuesIndex(0);
    // } else {
    //   setCurrTopicIndex(objectIndex);
    //   setCurrQuesIndex(questionIndex + 1);
    // }

    // if (questionIndex === questionData[objectIndex]?.questions?.length - 1) {
    //   if (objectIndex === 0) {
    //     setCurrTopicIndex(0);
    //   } else {
    //     setCurrTopicIndex(objectIndex - 1);
    //   }

    //   setCurrQuesIndex(0);
    // } else {
    //   setCurrTopicIndex(objectIndex);
    //   if (questionIndex === 0) {
    //     setCurrQuesIndex(0);
    //   } else {
    //     setCurrQuesIndex(questionIndex - 1);
    //   }
    // }
    setCurrQuestion(currQuestionVal);
  };

  const handleNext = () => {
    setCurrQuestionId(questions[currQuestion + 1]?.id);
    const objectIndex = questionData.findIndex((data) =>
      data.questions.some((q) => q.id === questions[currQuestion]?.id)
    );
    const questionIndex = questionData[objectIndex].questions.findIndex(
      (question) => question.id === questions[currQuestion]?.id
    );

    if (questionIndex === questionData[objectIndex]?.questions?.length - 1) {
      setCurrTopicIndex(objectIndex + 1);
      setCurrQuesIndex(0);
    } else {
      setCurrTopicIndex(objectIndex);
      setCurrQuesIndex(questionIndex + 1);
    }

    if (currQuestion < questions?.length) {
      if (currQuestion + 1 === questions.length) {
        setCurrQuestion(currQuestion);
      } else {
        setCurrQuestion((pre) => pre + 1);
      }
    }
    // if (currQuestion + 1 === questions.length) {
    //   setLastQsn(true);
    // }
    // setRadioVal("");
  };

  const handleSkip = () => {
    setCurrQuestionId(questions[currQuestion + 1]?.id);
    const findCurrentQuestionId = questions[currQuestion]?.id;
    let datas = questions?.map((data) => {
      if (data.id === findCurrentQuestionId) {
        return { ...data, correctAnswer: 0 };
      }
      return data;
    });

    setQuestions(datas);
    const objectIndex = questionData.findIndex((data) =>
      data.questions.some((q) => q.id === findCurrentQuestionId)
    );
    const questionIndex = questionData[objectIndex].questions.findIndex(
      (question) => question.id === findCurrentQuestionId
    );
    if (questionIndex === questionData[objectIndex]?.questions?.length - 1) {
      setCurrTopicIndex(objectIndex + 1);
      setCurrQuesIndex(0);
    } else {
      setCurrTopicIndex(objectIndex);
      setCurrQuesIndex(questionIndex + 1);
    }

    setQuestionStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[objectIndex][questionIndex] = "skipped";
      return newStatus;
    });

    const dataSet = questionData.map((data) => {
      const quesData = data.questions.map((dt) => {
        if (dt.id === findCurrentQuestionId) {
          return { ...dt, correctAnswer: 0 };
        } else return dt;
      });
      return { ...data, questions: quesData, topic: data.topic };
    });

    setQuestionData(dataSet);

    if (currQuestion + 1 === questions.length) {
      setCurrQuestion(currQuestion);
    } else {
      setCurrQuestion((pre) => pre + 1);
    }
    // if (currQuestion + 1 === questions.length) {
    //   setLastQsn(true);
    // }
  };

  const handleFinish = () => {
    handleClickOpen();
    // setuserSubmiited(true);
    // submitQuestionAns();
  };

  const handleSubmitConfirm = () => {
    handleClose();
    setuserSubmiited(true);
    submitQuestionAns();
  };

  // const handleCloseTimeOutModel = () => {
  //   setTimeoutModel(false);
  // };

  const handlePrevious = () => {
    const findCurrentQuestionId = questions[currQuestion]?.id;
    // let datas = questions?.map((data) => {
    //   if (data.id === findCurrentQuestionId) {
    //     return { ...data, correctAnswer: null };
    //   }
    //   return data;
    // });

    // setQuestions(datas);

    // const dataSet = questionData.map((data) => {
    //   const quesData = data.questions.map((dt) => {
    //     if (dt.id === findCurrentQuestionId) {
    //       return { ...dt, correctAnswer: null };
    //     } else return dt;
    //   });
    //   return { ...data, questions: quesData, topic: data.topic };
    // });

    // setQuestionData(dataSet);
    const objectIndex = questionData.findIndex((data) =>
      data.questions.some((q) => q.id === findCurrentQuestionId)
    );

    const questionIndex = questionData[objectIndex].questions.findIndex(
      (question) => question.id === findCurrentQuestionId
    );
    if (questionIndex === questionData[objectIndex]?.questions?.length - 1) {
      if (objectIndex === 0) {
        setCurrTopicIndex(0);
      } else {
        setCurrTopicIndex(objectIndex - 1);
      }

      setCurrQuesIndex(0);
    } else {
      setCurrTopicIndex(objectIndex);
      if (questionIndex === 0) {
        setCurrQuesIndex(0);
      } else {
        setCurrQuesIndex(questionIndex - 1);
      }
    }

    setCurrQuestionId(questions[currQuestion - 1]?.id);
    if (currQuestion > 0) {
      setCurrQuestion((pre) => pre - 1);
    }
  };

  // Calculate the total count of questions
  const totalCount = questionData?.reduce(
    (acc, topic) => acc + topic.questions.length,
    0
  );

  const onValueChange = (val) => {
    let datas = questions?.map((data) => {
      if (data.id === val && val.questionId) {
        return { ...data, correctAnswer: val.id };
      } else return data;
    });

    const dataSet = questionData.map((data) => {
      const quesData = data.questions.map((dt) => {
        if (dt.id === val?.questionId) {
          return { ...dt, correctAnswer: val.id };
        } else return dt;
      });
      return { ...data, questions: quesData, topic: data.topic };
    });

    const objectIndex = questionData.findIndex((data) =>
      data.questions.some((q) => q.id === val.questionId)
    );

    setCurrTopicIndex(objectIndex);
    const questionIndex = questionData[objectIndex].questions.findIndex(
      (question) => question.id === val.questionId
    );
    setCurrQuesIndex(questionIndex);
    setQuestionStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[objectIndex][questionIndex] = "selected";
      return newStatus;
    });

    setQuestions(datas);
    setQuestionData(dataSet);
    // setRadioVal(val);
  };

  // const handleErrorBoxClose = () => {
  //   setSubmitResult({ ...submitResult, res: null });
  //   setSubmitScore({ ...submitScore, res: null });
  // };

  const attentQuestionCount = questions.filter(
    (data) => data.correctAnswer !== null
  ).length;
  const percentageValue = (attentQuestionCount / questions.length) * 100;
  const submitQuestionAns = async () => {
    setOnSubmitModal(true);
    const currentTime = new Date();
    // Calculate total minutes

    const diffMilliseconds = currentTime - location.state.startTime;
    const totalTime = (diffMilliseconds / (1000 * 60)).toFixed(1);
    let resId = null;

    setTimerRunning(true);
    await SubmitUserTest(
      questionIdsRef.current,
      answerIdsRef.current,
      userDetails.userName,
      userDetails.userEmailId,
      filterTopicIdRef.current,
      totalTime,
      loginDetail?.testResultId
    )
      .then((response) => {
        setSubmitResult({ data: response.data, res: "success" });
        resId = response?.data?.id;

        setOpenSubmitResultModel({ ...openSubmitResultModel, success: true });
        // SubmitScoreGradebyUserTest(response?.data?.id).catch((e) => e);
      })
      .catch((err) => {
        setSubmitResult({ data: err, res: "fail" });
        setOpenSubmitResultModel({ ...openSubmitResultModel, fail: true });
      });

    await AddUserTestScore(
      secondQuestionIdsRef.current,
      secondAnswerIdsRef.current,
      userDetails.userEmailId
    )
      .then((response) => {
        setSubmitScore({ data: response.data, res: "success" });
        SubmitScoreGradebyUserTest(resId).catch((e) => e);
        // setOpenAddUserScoreModel({ ...openAddUserScoreModel, success: true });
      })
      .catch((err) => {
        setSubmitScore({ data: err, res: "fail" });
        // setOpenAddUserScoreModel({ ...openAddUserScoreModel, fail: true });
      });
  };

  const confirmClose = () => {
    const confirmation = window.confirm(
      "Are you sure you want to close this tab?"
    );
    if (confirmation) {
      // Note: This will only work in environments where window.close() is allowed, such as browser extensions.
      window.close();
    }
  };

  const checkAllSelected = (arrays) => {
    const allContainSelected = arrays.every((subArray) =>
      subArray.every((item) => item === "selected")
    );
    if (allContainSelected) {
      return true;
    } else {
      return false;
    }
  };

  // console.log(questionStatus, "questionStatus");

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* <Joyride
        steps={steps}
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
      /> */}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: "#F7F7F7",
          color: "#908DB8",
          width: "100%",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            // justify-content: space-between;
          }}
        >
          <img
            alt="logos"
            src={bigLogo}
            style={
              {
                // marginTop: "20px",
                // marginLeft: "14px",
                // ...(open === false && { display: "none" }),
              }
            }
            width={"10%"}
          />
          {/* <Typography sx={{ width: "30%" }} variant="h6" noWrap component="div">
            User Test Module
          </Typography> */}
          {!submitResult && !submitScore && (
            <Box
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
              }}
            >
              <LinearProgress
                determinate
                variant="soft"
                color="#908db8"
                size="sm"
                thickness={25}
                value={percentageValue}
                sx={{
                  width: "100%",
                  backgroundColor: "#cfd1e1",
                  // '--LinearProgress-radius': '0px',
                  "--LinearProgress-progressThickness": "24px",
                  boxShadow: "sm",
                  px: 2,
                  fontFamily: "Manrope",
                }}
              >
                <Typography
                  level="body3"
                  fontWeight="md"
                  color="white"
                  sx={{ mr: "auto", zIndex: 555, fontFamily: "Manrope" }}
                >
                  {currQuestion + 1}/{totalCount}
                </Typography>
                {storageTime?.AssessmentTime !== 0 && (
                  <Typography
                    level="body3"
                    fontWeight="md"
                    color="white"
                    sx={{ mixBlendMode: "difference", fontFamily: "Manrope" }}
                  >
                    {remainingTime.minutes}:
                    {remainingTime.seconds < 10
                      ? `0${remainingTime.seconds}`
                      : remainingTime.seconds}
                  </Typography>
                )}
              </LinearProgress>
              <TimerIcon sx={{ alignSelf: "center", height: 30, width: 30 }} />
            </Box>
          )}
          <HtmlTooltip
            title={
              <React.Fragment>
                {/* <Typography color="inherit">Info</Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    fontFamily: "Manrope !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "10px",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "12px",
                        backgroundColor: "#908db8",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <p>Current Question</p>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "12px",
                        backgroundColor: "#d0cfdf",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <p>Pending Question</p>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "12px",
                        backgroundColor: "#43ad41",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <p>Answered Question</p>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "12px",
                        backgroundColor: "#ffd91c",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <p>Skipped Question</p>
                  </Box>
                </Box>
              </React.Fragment>
            }
          >
            <IconButton aria-label="info" size="large">
              <InfoOutlinedIcon />
            </IconButton>
          </HtmlTooltip>
          {/* <Typography
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Typography className="box">
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p id="second">{hours}</p>
                <p id="second">-{minutes}-</p>
                <p id="second">{seconds}</p>
              </Typography>
            </Typography>
            <Typography>
              <TimerIcon sx={{ mt: 2, height: 30, width: 30 }} />
            </Typography>
          </Typography> */}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            bgcolor: "#F7F7F7",
            boxSizing: "border-box",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <UserLeftButtons
            currQuestion={currQuestion}
            questionData={questionData}
            currQuestionId={currQuestionId}
            questionStatus={questionStatus}
            handleDirectSwitch={handleDirectSwitch}
          />
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "#EBECF1",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Toolbar />

        <Card
          sx={{
            minHeight: "45%",
            maxHeight: "70%",
            width: "100%",
            alignSelf: "center",
            marginTop: "100px",
            fontSize: "10px",
          }}
        >
          <CardContent sx={{ height: "100%" }}>
            {currQuestion == questions.length ? ( // eslint-disable-line
              <Typography
                sx={{
                  width: "100%",
                  color: submitResult && submitScore ? "green" : "black",
                  fontWeight: "bold",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Manrope",
                }}
              >
                {submitResult && submitScore
                  ? "Your Assessment Has Been Submitted Successfully!"
                  : "Please Submit Your Assesment"}
              </Typography>
            ) : (
              <Stack direction="row" spacing={10} sx={{ marginTop: "1%" }}>
                <Box sx={{ minWidth: "20vw", maxWidth: "35vw", py: 3 }}>
                  <TextField
                    value={questions[currQuestion]?.question}
                    fullWidth
                    multiline
                    sx={{
                      outline: "none",
                      border: "0px",
                      overflowY: "auto",
                      fontFamily: "Manrope",
                    }}
                    minRows={8}
                    maxRows={10}
                  />
                </Box>
                <Box>
                  <Typography sx={{ mb: "2%", fontFamily: "Manrope" }}>
                    Select only one
                  </Typography>
                  <Stack
                    sx={{
                      overflowY: "auto",
                      "::-webkit-scrollbar-track": {
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                        borderRadius: "10px",
                        backgroundColor: "rgb(179, 177, 177)",
                      },

                      "::-webkit-scrollbar": {
                        width: "5px",
                        backgroundColor: "#FFFFFF",
                      },

                      "::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                        backgroundColor: "rgb(136, 136, 136)",
                      },
                    }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      {questions[currQuestion]?.options.map((val, index) => {
                        return (
                          <Box
                            key={index + 1}
                            sx={{
                              border: "2px solid lightgrey",
                              width: "100%",
                              whiteSpace: "pre-wrap",
                              mb: "6%",
                              flexGrow: 1,
                              minWidth: "30vw",
                              fontFamily: "Manrope",
                            }}
                          >
                            <FormControlLabel
                              value={
                                val.optionContent ? val.optionContent : null
                              }
                              checked={
                                val.id === questions[currQuestion].correctAnswer
                              }
                              control={<Radio />}
                              label={val.optionContent.trim()}
                              sx={{ ml: "1%", fontFamily: "Manrope" }}
                              onChange={() => {
                                onValueChange(val);
                              }}
                            />
                          </Box>
                        );
                      })}
                    </RadioGroup>
                  </Stack>
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>

        {!submitResult && !submitScore && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: "3%",
              fontFamily: "Manrope",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {/* <Button
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{ color: "#908DB8" }}
              >
                <HelpOutline color="inherit" />
              </Button> */}
              {/* <Typography
                    paddingY={1}
                    paddingX={1}
                    variant="body2"
                    sx={{
                      bgcolor: "#FDF2D6",
                      textAlign: "center",
                      fontWeight: 500,
                      fontSize: "11px",
                      width: "100%",

                      textTransform: "capitalize",
                      color: "#99834B",
                    }}
                  >
                    {remainingTime.minutes === 1
                      ? "You're running out of time, hurry up!"
                      : questionStatus.some((innerArray) =>
                          innerArray.includes("skipped")
                        )
                      ? "You have skipped some answers, Answer it without fail!"
                      : "Once you move next to the last question, you can't go to previous questions"}
                  </Typography> */}
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#908DB8",
                  mr: 4,
                  border: "1px solid #908DB8",
                  fontFamily: "Manrope",
                  "&:hover": { border: "1px solid #908DB8" },
                }}
                disabled={currQuestion === 0}
                onClick={handlePrevious}
              >
                Previous
              </Button>
              {currQuestion + 1 === totalCount ? (
                <Button
                  variant="contained"
                  disabled={submitResult && submitScore}
                  sx={{
                    backgroundColor: "#908DB8",
                    fontFamily: "Manrope",
                    "&:hover": { backgroundColor: "#908DB8" },
                  }}
                  onClick={handleFinish}
                >
                  {"Submit"}
                </Button>
              ) : questionStatus?.[currTopicIndex]?.[currQuesIndex] ===
                "selected" ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#908DB8",
                    fontFamily: "Manrope",
                    "&:hover": { backgroundColor: "#908DB8" },
                  }}
                  onClick={handleNext}
                >
                  {"Next"}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#908DB8",
                    fontFamily: "Manrope",
                    "&:hover": { backgroundColor: "#908DB8" },
                  }}
                  onClick={handleSkip}
                >
                  {"Next"}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Dialog
        open={submitConfirm}
        // onClose={handleCloseTimeOutModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "200px" }}
      >
        <Box
          sx={{
            p: "36px 36px 36px 36px",
            width: "550px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              textAlign: "center",
              fontFamily: "Manrope",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 800,
              lineHeight: "normal",
            }}
          >
            <p style={{ margin: 0 }}>
              {checkAllSelected(questionStatus)
                ? "Do you want to submit?"
                : "You have some unanswered questions!"}
            </p>
            {!checkAllSelected(questionStatus) && (
              <p
                style={{
                  margin: "30px 0 20px",
                  color: "rgba(0, 0, 0, 0.87)",
                  textAlign: "center",
                  fontFamily: "Manrope",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22.72px",
                  // marginButtom: "20px !important",
                }}
              >
                Do you still want to submit?
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                marginTop: checkAllSelected(questionStatus) && "35px",
                gap: "20px",
              }}
            >
              <button
                style={{
                  borderRadius: "10px",
                  border: "1px solid #908DB8",
                  background: "#908DB9",
                  color: "#fff",
                  width: "30%",
                  height: "52px",
                  cursor: "pointer",
                  fontFamily: "Manrope",
                }}
                onClick={handleSubmitConfirm}
              >
                YES
              </button>
              <button
                style={{
                  borderRadius: "10px",
                  border: "1px solid #908DB8",
                  background: "#908DB9",
                  color: "#fff",
                  width: "30%",
                  height: "52px",
                  cursor: "pointer",
                  fontFamily: "Manrope",
                }}
                onClick={handleClose}
              >
                NO
              </button>
            </div>
            {openSubmitResultModel?.fail && "Oops!"}
          </DialogTitle>
        </Box>
      </Dialog>

      {timeOutModel ? (
        <Dialog
          open={timeOutModel}
          // onClose={handleCloseTimeOutModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "200px" }}
        >
          <Box
            sx={{
              p: "85px 36px 0px 36px",
              width: "550px",
              height: "530px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <TimelapseOutlined /> */}
            <img
              src={timerOutIcon}
              alt="timerOut-icon"
              width={140}
              height={140}
              style={{ margin: "0 auto" }}
            />

            <DialogTitle
              id="alert-dialog-title"
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "normal",
              }}
            >
              <p style={{ margin: 0 }}>
                Your assessment time has
                <br />
                expired.
              </p>
              <p
                style={{
                  margin: "30px 0 20px",
                  color: "rgba(0, 0, 0, 0.87)",
                  textAlign: "center",
                  fontFamily: "Manrope",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "22.72px",
                }}
              >
                Only answered questions will be validated, and the result will
                be sent through email.
              </p>
              <button
                style={{
                  borderRadius: "10px",
                  border: "1px solid #908DB8",
                  background: "#908DB9",
                  color: "#fff",
                  width: "100%",
                  height: "52px",
                  cursor: "pointer",
                  fontFamily: "Manrope",
                }}
                onClick={confirmClose}
              >
                CLOSE
              </button>
              {openSubmitResultModel?.fail && "Oops!"}
            </DialogTitle>
          </Box>
        </Dialog>
      ) : (
        <Dialog
          open={onSubmitModal}
          // onClose={handleCloseTimeOutModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "15px" }}
        >
          {openSubmitResultModel?.success && (
            <Box
              sx={{
                p: "85px 36px 0px 36px",
                width: "514px",
                height: "530px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <SuccessAnimation color="green" /> */}
              {/* <img src="../../Assets/completed-icon.svg" alt="completed-icon" /> */}
              <img
                src={completedIcon}
                alt="completed-icon"
                width={140}
                height={140}
                style={{ margin: "0 auto" }}
              />
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  textAlign: "center",
                  fontFamily: "Manrope",
                  fontSize: "32px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                }}
              >
                <p style={{ margin: 0 }}>
                  Assessment completed
                  <br />
                  successfully.
                </p>
                <p
                  style={{
                    margin: "30px 0 20px",
                    color: "rgba(0, 0, 0, 0.87)",
                    textAlign: "center",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "22.72px",
                  }}
                >
                  The result will be informed to you once it's validated through
                  email
                </p>
                <button
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #908DB8",
                    background: "#908DB9",
                    color: "#fff",
                    width: "100%",
                    height: "52px",
                    cursor: "pointer",
                    fontFamily: "Manrope",
                  }}
                  onClick={confirmClose}
                >
                  CLOSE
                </button>
                {openSubmitResultModel?.fail && "Oops!"}
              </DialogTitle>
            </Box>
          )}
          <DialogContent id="alert-dialog-title" sx={{ p: 0 }}>
            {!openSubmitResultModel?.success &&
              !openSubmitResultModel?.fail && (
                <CircularProgress size={30} color="warning" />
              )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
