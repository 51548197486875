import {
  Box,
  Button,
  Card,
  CardContent,
  Icon,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import TimerIcon from "@mui/icons-material/Timer";
const SecondStepFormCard = ({
  cardData,
  checkType,
  overAlltime,
  isSelected,
  timer,
  onAdd,
  onRemove,
  testCount,
  testCountOnly,
  testMode,
  setTestCount,
  setTestMode,
  selectedType,
  testCount1,
  testCount2,
}) => {
  return (
    <Card
      sx={{
        width: "100%",
        height: "90%",
        marginY: 1.5,
        marginX: 0.1,
        flexGrow: 1,
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Stack direction={"column"}>
          <Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14.2px",
                  color: "black",
                  fontWeight: 800,
                  fontFamily: "Manrope",
                }}
              >
                {cardData?.Name}
              </Typography>
              <Box
                component={Stack}
                direction={"row"}
                alignItems={"center"}
                sx={{
                  border: "1px solid #908db8",
                  borderRadius: "20px",
                  px: 1,
                  py: 0.5,
                  pt: 0.2,
                }}
              >
                <Icon fontSize="12px" sx={{ color: "#908DB8" }}>
                  <TimerIcon fontSize="inherit" />
                </Icon>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "11px",
                    color: "black",
                    fontWeight: 400,
                    fontFamily: "Manrope",
                    mt: "2.5px",
                    ml: "3px",
                  }}
                >
                  {timer === 0
                    ? 0
                    : (timer / 60).toFixed(1).endsWith(".0")
                    ? (timer / 60).toFixed(0)
                    : (timer / 60).toFixed(1)}{" "}
                  {timer / 60 > 1 ? "mins" : "min"}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Stack
              direction={"row"}
              paddingY={4}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
              whiteSpace={"pre-wrap"}
              flexWrap={"wrap"}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  color: "black",
                  overflowY: "hidden",
                  whiteSpace: "pre-wrap",
                  overflowX: "hidden",
                  fontWeight: 400,
                  height: "92px",
                  fontFamily: "Manrope",
                }}
              >
                {cardData?.Description}
              </Typography>
            </Stack>
          </Box>
          <Box
            // component={Stack}
            // direction={"row"}
            // justifyContent={"space-between"}
            // spacing={1}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              rowGap: "8px",
              columnGap: "4px",
              justifyItems: "center",
              alignItems: "end",
            }}
          >
            {checkType === "visible" ? (
              ""
            ) : (
              <Box sx={{}}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "10px",
                    textAlign: "center",
                    paddingY: 1,
                    fontWeight: "bold",
                    color: "#212121",
                    fontFamily: "Manrope",
                  }}
                >
                  Ques. Count
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  value={testCountOnly}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       /{cardData?.Count[selectedType]}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  sx={{
                    // width: "65px",
                    "& .MuiInputBase-root": {
                      height: 32,
                      fontSize: "10px !important",
                    },
                    fontSize: "10px !important",
                    fontFamily: "Manrope",
                    "& input::placeholder": {
                      fontSize: "10px !important",
                      fontFamily: "Manrope",
                    },
                  }}
                  name="count"
                  placeholder="Count"
                  onChange={setTestCount}
                  disabled={!isSelected}
                />
              </Box>
            )}
            {/* {checkType === "visible" && (
              <Box sx={{ width: 70 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingY: 1,
                    color: "#212121",
                    fontFamily: "Manrope",
                  }}
                >
                  Complexity
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={testMode}
                    size="small"
                    sx={{ width: 70, fontSize: "10px" }}
                    placeholder="Select Type"
                    onChange={setTestMode}
                    disabled={!isSelected}
                  >
                    <MenuItem
                      value={1}
                      sx={{ fontFamily: "Manrope", fontSize: "10px" }}
                    >
                      Easy
                    </MenuItem>
                    <MenuItem
                      value={2}
                      sx={{ fontFamily: "Manrope", fontSize: "10px" }}
                    >
                      Medium
                    </MenuItem>
                    <MenuItem
                      value={3}
                      sx={{ fontFamily: "Manrope", fontSize: "10px" }}
                    >
                      Hard
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )} */}
            {checkType === "visible" && (
              <>
                <Box sx={{ width: checkType === "visible" ? "70px" : "60px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "10px",
                      textAlign: "center",
                      paddingY: 1,
                      fontWeight: "bold",
                      color: "#212121",
                      fontFamily: "Manrope",
                    }}
                  >
                    Easy
                  </Typography>

                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={testCount}
                    name="count"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       /{cardData?.ComplexityCounts["easy"]}
                    //     </InputAdornment>
                    //   ),
                    // }}
                    sx={{
                      width: checkType === "visible" ? "70px" : "60px",
                      "& .MuiInputBase-root": {
                        height: 32,
                        fontSize: "10px !important",
                      },
                      fontSize: "10px !important",
                      fontFamily: "Manrope",
                      "& input::placeholder": {
                        fontSize: "10px !important",
                        fontFamily: "Manrope",
                      },
                    }}
                    placeholder="Count"
                    onChange={setTestCount}
                    disabled={
                      !isSelected || cardData.ComplexityCounts["easy"] === 0
                    }
                  />
                </Box>
                <Box sx={{ width: checkType === "visible" ? "70px" : "60px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "10px",
                      textAlign: "center",
                      paddingY: 1,
                      fontWeight: "bold",
                      color: "#212121",
                      fontFamily: "Manrope",
                    }}
                  >
                    Medium
                  </Typography>

                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={testCount1}
                    name="count1"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       /{cardData?.Count[selectedType]}
                    //     </InputAdornment>
                    //   ),
                    // }}
                    sx={{
                      width: checkType === "visible" ? "70px" : "60px",
                      "& .MuiInputBase-root": {
                        height: 32,
                        fontSize: "10px !important",
                      },
                      fontSize: "10px !important",
                      fontFamily: "Manrope",
                      "& input::placeholder": {
                        fontSize: "10px !important",
                        fontFamily: "Manrope",
                      },
                    }}
                    placeholder="Count"
                    onChange={setTestCount}
                    disabled={
                      !isSelected || cardData.ComplexityCounts["medium"] === 0
                    }
                  />
                </Box>
                <Box sx={{ width: checkType === "visible" ? "70px" : "60px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "10px",
                      textAlign: "center",
                      paddingY: 1,
                      fontWeight: "bold",
                      color: "#212121",
                      fontFamily: "Manrope",
                    }}
                  >
                    Hard
                  </Typography>

                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={testCount2}
                    name="count2"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       /{cardData?.Count[selectedType]}
                    //     </InputAdornment>
                    //   ),
                    // }}
                    sx={{
                      width: checkType === "visible" ? "70px" : "60px",
                      "& .MuiInputBase-root": {
                        height: 32,
                        fontSize: "10px !important",
                      },
                      fontSize: "10px !important",
                      fontFamily: "Manrope",
                      "& input::placeholder": {
                        fontSize: "10px !important",
                        fontFamily: "Manrope",
                      },
                    }}
                    placeholder="Count"
                    onChange={setTestCount}
                    disabled={
                      !isSelected || cardData.ComplexityCounts["hard"] === 0
                    }
                  />
                </Box>{" "}
              </>
            )}
            <Box
              sx={{
                gridRow: checkType === "visible" && "2",
                gridColumn: checkType === "visible" ? "1/4" : "3/4",
                width: "100%",
              }}
            >
              {isSelected ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={onRemove}
                  sx={{
                    bgcolor: "#A94646",
                    "&:hover": {
                      backgroundColor: "#A94646",
                    },
                    height: "35px",
                    fontSize: "10px",
                    alignSelf: "flex-end",
                    fontFamily: "Manrope",
                    minWidth: "100%",
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={onAdd}
                  size="small"
                  sx={{
                    bgcolor: "#46A997",
                    "&:hover": {
                      backgroundColor: "#46A997",
                    },
                    height: "35px",
                    fontSize: "10px",
                    alignSelf: "flex-end",
                    fontFamily: "Manrope",
                    minWidth: "100%",
                  }}
                  disabled={overAlltime}
                >
                  Add
                </Button>
              )}
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SecondStepFormCard;
