import { axiosInstance } from "./Api";

const RefreshTokenApi = () => {
  return axiosInstance.post("/refresh", {
    refreshToken:
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJvcmlvbkBnbWFpbC5jb20iLCJpYXQiOjE3MDQ3ODUyNTQsImV4cCI6MTcwNDc4NzA1NH0.WmHz5x6Jkr41HsfaqEgFS674hcOfelfxwG7nhmInJ_8",
  });
};

export default RefreshTokenApi;
