import { CloudUploadOutlined } from "@material-ui/icons";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TrashIcon from "../../Assets/trash.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import GetSkillCategory from "../../API/GetSkillCategory";
import FindSubCategorybyTopicID from "../../API/FindSubCategorybyTopicID";
import UploadQuestionModal from "../../Components/TestLibrary/UploadQuestionModal";
import CardViewTestLibrary from "../../Components/TestLibrary/CardActions/CardViewTestLibrary";
import CardEditTestLibrary from "../../Components/TestLibrary/CardActions/CardEditTestLibrary";
import TestPreviewModal from "../../Components/TestLibrary/CardActions/TestPreview/TestPreviewModal";
import NewQuestionCreationModal from "../../Components/TestLibrary/NewQuestionCreationModal";
import DeleteTestTopicbyId from "../../API/DeleteTestTopicbyId";
import { toast } from "react-toastify";
import GetTopicSubCategoryWithoutSkillid from "../../API/GetTopicSubCategoryWithoutSkillid";
import { useLocation } from "react-router-dom";

// import { axiosInstance } from "../../API/Api";
// import GetAllTopicSubCategory from "../../API/GetAllTopicSubCategory";
// import { setFilterValue } from "../../Redux/SecondStep";
// import { useDispatch } from "react-redux";

const TestLibrary = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "#EBECF1",
    borderRadius: "4px",
    // border: "2px solid #000",
    boxShadow: 24,
    minHeight: "87%",
    width: "60%",
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const [CardViewModal, setCardViewModal] = useState(false);
  const [CardEditModal, setCardEditModal] = useState(false);
  const [TestPreviewActive, setTestPreviewActive] = useState({
    viewMode: false,
    editMode: false,
  });
  const [AddQsnMode, setAddQsnMode] = useState({
    status: false,
    qsnCount: 0,
  });
  const [refetch, setRefetch] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.isOpen) {
      handleOpen();
    }
  }, [location.state?.isOpen]);

  const handleCardViewModal = () => {
    setCardViewModal(!CardViewModal);
  };

  const handleCardEditModal = () => {
    setCardEditModal(!CardEditModal);
  };

  const handleTestPreviewModal = (mode) => {
    if (mode === "viewMode") {
      setTestPreviewActive({
        viewMode: true,
        editMode: false,
      });
    } else if (mode === "editMode") {
      setTestPreviewActive({
        viewMode: false,
        editMode: true,
      });
    } else if (mode === "closeMode") {
      setTestPreviewActive({
        viewMode: false,
        editMode: false,
      });
    }
  };
  const [categoryData, setCategoryData] = useState([]);
  const [SkillCategory, setSkillCategory] = useState([]);
  const [FilteredCategoryData, setFilteredCategoryData] = useState([]);
  const [SelectedCardData, setSelectedCardData] = useState([]);
  const [SelectedQsnData, setSelectedQsnData] = useState(null);

  useEffect(() => {
    if (categoryData?.length === 0) {
      GetTopicSubCategoryWithoutSkillid({
        SkillCategoryId: 0,
        questionTypeId: 0,
      })
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((e) => e);
    }
    if (SkillCategory?.length === 0) {
      GetSkillCategory()
        .then((response) => setSkillCategory(response?.data))
        .catch((e) => e);
    }
  }, []); // eslint-disable-line
  useEffect(() => {
    if (categoryData?.length !== 0) {
      GetTopicSubCategoryWithoutSkillid({
        SkillCategoryId: 0,
        questionTypeId: 0,
      })
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((e) => e);
    }
    if (SkillCategory?.length !== 0) {
      GetSkillCategory()
        .then((response) => setSkillCategory(response?.data))
        .catch((e) => e);
    }
  }, [open, refetch]); // eslint-disable-line

  useEffect(() => {
    setFilteredCategoryData(categoryData);
  }, [categoryData]);

  const handleFilterChange = (option) => {
    const selectedFilter = option.id;
    const selectedSkillID = option.skillId;
    // setFilterValue(selectedFilter);
    // setSubCategoryValue(event.target.value);

    if (selectedFilter) {
      FindSubCategorybyTopicID({
        categoryID: selectedFilter,
        questionTypeId: 0,
        skillId: selectedSkillID,
      })
        .then((response) => {
          setCategoryData(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching subcategories by topic ID:", error);
        });
      // eslint-disable-next-line
    } else if (option == 0) {
      GetTopicSubCategoryWithoutSkillid({
        SkillCategoryId: 0,
        questionTypeId: 0,
      })
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((error) => {
          console.error(
            "Error fetching subcategories without skill ID:",
            error
          );
        });
    } else {
      setCategoryData([]);
    }
  };

  const handleSearchTestsLibrary = (e) => {
    if (e && e.target.value.length >= 1) {
      const filteredData = categoryData.filter((product) => {
        const isMatched = product.Name.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
        return isMatched;
      });
      setFilteredCategoryData(filteredData);
    } else {
      setFilteredCategoryData(categoryData);
    }
  };

  const HandleCardDelete = (id) => {
    DeleteTestTopicbyId(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Test Deleted Successfully`);
          setRefetch(refetch + 1);
        }
      })
      .catch((e) => e);
  };
  return (
    <Box
      sx={{
        backgroundColor: "#EBECF1",
        minHeight: "100vh",

        display: "flex",
        width: "100%",
        // flexDirection: "column",
        marginTop: "0.5%",
        // alignItems: "center",
        // justifyContent: "center",
      }}
    >
      <Box
        sx={{ mt: "3%", ml: "4%", mr: "3%", width: "100%", height: "100%" }}
        component={Stack}
        flexDirection={"column"}
      >
        <Box>
          <Typography
            variant="p"
            sx={{
              color: "#212121",
              fontWeight: 800,
              fontSize: "20px",
              fontFamily: "Manrope",
            }}
          >
            Libraries
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }} mt={3}>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Box sx={{ mr: 8 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "15px",
                  paddingY: 2,
                  color: "#212121",
                  fontFamily: "Manrope",
                }}
              >
                Search
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Enter Library Name"
                // value={nameOfTheTest ? nameOfTheTest : ""}
                sx={{
                  backgroundColor: "white",
                  minWidth: 240,
                  fontFamily: "Manrope",
                  fontSize: "14px",
                  borderRadius: "5px",
                  "& input::placeholder": {
                    fontFamily: "Manrope",
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-root": {
                    fontFamily: "Manrope",
                    fontSize: "14px",
                  },
                }}
                onChange={(e) => handleSearchTestsLibrary(e)}
                size="small"
                variant="outlined"
              />
            </Box>
            <Box sx={{ minWidth: 240 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "15px",
                  paddingY: 2,
                  color: "#212121",
                  fontFamily: "Manrope",
                }}
              >
                Category
              </Typography>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={0}
                  size="small"
                  sx={{
                    backgroundColor: "white",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                  }}
                  disabled={SkillCategory?.length === 0}
                  onChange={(e) => handleFilterChange(e.target.value)}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    // disabled
                    value={0}
                    sx={{ fontFamily: "Manrope", fontSize: "14px" }}
                  >
                    All
                  </MenuItem>
                  {SkillCategory?.map((option) => {
                    return (
                      <MenuItem
                        key={option.id}
                        value={option}
                        sx={{ fontFamily: "Manrope", fontSize: "14px" }}
                      >
                        {option.skillCategoryName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                height: "40px",
                backgroundColor: "#46A997",
                width: "140px",
                borderRadius: "3px",
                alignSelf: "end",
                marginLeft: "auto",
                textAlign: "center",
              }}
            >
              <IconButton
                sx={{
                  fontSize: "16px",
                  color: "white",
                  fontWeight: 500,
                  fontFamily: "Manrope",
                  // fontSize: "10px",
                }}
                onClick={handleOpen}
                disableRipple
              >
                <CloudUploadOutlined style={{ marginRight: 12 }} />
                Questions
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box
          component={Stack}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={3}
          marginTop={3}
          flexGrow={1}
        >
          {FilteredCategoryData?.length === 0 ? (
            <Alert
              severity="warning"
              sx={{ width: "30vw", fontFamily: "Manrope", fontSize: "12px" }}
            >
              <AlertTitle sx={{ fontFamily: "Manrope", fontSize: "14px" }}>
                No Data Found
              </AlertTitle>
              Check Other Categories!
            </Alert>
          ) : (
            FilteredCategoryData.map((el) => {
              return (
                <Card
                  sx={{
                    minWidth: "18%",
                    width: "18%",
                    maxWidth: "25%",
                    minHeight: "50vh",
                    maxHeight: "50vh",
                    marginY: 2,
                    flexGrow: 1,
                    marginX: 0.1,
                    borderRadius: "15px",
                    boxShadow: "0px 6px 7px -4px #8888880F",
                    fontFamily: "Manrope",
                    fontSize: "10px",
                  }}
                >
                  <CardContent sx={{ height: "100%" }}>
                    <Stack
                      direction={"column"}
                      height={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Stack direction={"row"}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "16px",
                              color: "black",
                              fontWeight: 800,
                              textTransform: "capitalize",
                              fontFamily: "Manrope",
                            }}
                          >
                            {el.Name}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          maxHeight: "70%",
                          overflow: "clip",
                          // overflowY: "auto",
                          // "&::-webkit-scrollbar": { display: "none" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            paddingY: 1,
                            color: "black",
                            textOverflow: "ellipsis",
                            fontWeight: 400,
                            overflowWrap: "break-word",
                            textTransform: "capitalize",
                            fontFamily: "Manrope",
                            lineHeight: 1.6,
                          }}
                        >
                          {el.Description}
                        </Typography>
                      </Box>
                      <Box
                        component={Stack}
                        direction={"row"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <IconButton
                          onClick={() => {
                            setSelectedCardData(el.Id);
                            handleCardViewModal();
                          }}
                          sx={{ color: "#908DB8", p: 0 }}
                        >
                          <VisibilityIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                        <IconButton
                          sx={{ color: "#46A997", p: 0 }}
                          onClick={() => {
                            setSelectedCardData(el.Id);
                            handleCardEditModal();
                          }}
                        >
                          <BorderColorOutlinedIcon
                            style={{ fontSize: "20px" }}
                          />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => HandleCardDelete(el.Id)}
                        >
                          <img
                            alt="icon"
                            style={{ width: "16px" }}
                            src={TrashIcon}
                          />
                        </IconButton>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              );
            })
          )}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UploadQuestionModal
            handleClose={handleClose}
            setRefetch={setRefetch}
          />
        </Box>
      </Modal>
      <Modal open={CardViewModal} onClose={handleCardViewModal}>
        <CardViewTestLibrary
          SelectedCardData={SelectedCardData}
          handleTestPreviewModal={handleTestPreviewModal}
        />
      </Modal>
      <Modal open={CardEditModal} onClose={handleCardEditModal}>
        <CardEditTestLibrary
          SelectedCardData={SelectedCardData}
          handleCardEditModal={handleCardEditModal}
          setSelectedQsnData={setSelectedQsnData}
          handleTestPreviewModal={handleTestPreviewModal}
          TestPreviewActive={TestPreviewActive}
          setRefetch={setRefetch}
          AddQsnMode={AddQsnMode}
          setAddQsnMode={setAddQsnMode}
          refetch={refetch}
        />
      </Modal>
      <TestPreviewModal
        TestPreviewActive={TestPreviewActive}
        SelectedCardData={SelectedCardData}
        handleTestPreviewModal={handleTestPreviewModal}
        SelectedQsnData={SelectedQsnData}
      />
      <NewQuestionCreationModal
        SelectedCardData={SelectedCardData}
        AddQsnMode={AddQsnMode}
        setAddQsnMode={setAddQsnMode}
        setRefetch={setRefetch}
      />
    </Box>
  );
};

export default TestLibrary;
