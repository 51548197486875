import {
  AddCircle,
  BorderColorSharp,
  FileCopySharp,
  Visibility,
} from "@material-ui/icons";
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditTestLibrarybyTopicId from "../../../API/EditTestLibrarybyTopicId";
import EditTestCardQuestionsDropDown from "../../../API/EditTestCardQuestionsDropDown";
import ModifyTestLibraryDescription from "../../../API/ModifyTestLibraryDescription";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";

const CardViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "#FFFFFF",
  borderRadius: "15px",
  // border: "2px solid #000",
  boxShadow: 24,
  minHeight: "90%",
  width: "80%",
  p: 4,
};
const CardEditTestLibrary = ({
  SelectedCardData,
  handleCardEditModal,
  setSelectedQsnData,
  handleTestPreviewModal,
  TestPreviewActive,
  setRefetch,
  AddQsnMode,
  setAddQsnMode,
  refetch,
}) => {
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [inputValue, setInputValue] = useState("");

  const [names, setNames] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [QsnByComplexityEasy, setQsnByComplexityEasy] = useState({
    easyQsn: [],
  });
  const [QsnByComplexityMedium, setQsnByComplexityMedium] = useState({
    mediumQsn: [],
  });
  const [QsnByComplexityHard, setQsnByComplexityHard] = useState({
    hardQsn: [],
  });
  const [qsnDescription, setQsnDescription] = useState({
    id: SelectedCardData,
    description: "",
  });

  useEffect(() => {
    EditTestLibrarybyTopicId(SelectedCardData)
      .then((response) => {
        setQsnDescription({
          id: SelectedCardData,
          description: response.data[0]?.TopicDetails["Description"],
        });
        setNames(
          response.data[0]?.TopicDetails["CategorizedAs"]?.category?.map(
            (el) => {
              return {
                text: el?.word,
              };
            }
          )
        );
        setCardData(response.data);
      })

      .catch((err) => console.log(err));
  }, [TestPreviewActive, refetch]); // eslint-disable-line

  // useEffect(() => {
  //   if (cardData) {
  //     if (qsnDescription.description === "") {
  //       setQsnDescription({
  //         id: SelectedCardData,
  //         description: cardData[0]?.TopicDetails["Description"],
  //       });
  //     }
  //   }
  // }, [cardData]);

  useEffect(() => {
    if (QsnByComplexityEasy.easyQsn.length === 0) {
      EditTestCardQuestionsDropDown({
        topicId: SelectedCardData,
        complexityId: 1,
      }).then((response) =>
        setQsnByComplexityEasy({
          ...QsnByComplexityEasy,
          easyQsn: response.data,
        })
      );
    }
    if (QsnByComplexityMedium.mediumQsn.length === 0) {
      EditTestCardQuestionsDropDown({
        topicId: SelectedCardData,
        complexityId: 2,
      }).then((response) => {
        setQsnByComplexityMedium({
          ...QsnByComplexityMedium,
          mediumQsn: response.data,
        });
      });
    }

    if (QsnByComplexityHard.hardQsn.length === 0) {
      EditTestCardQuestionsDropDown({
        topicId: SelectedCardData,
        complexityId: 3,
      }).then((response) => {
        setQsnByComplexityHard({
          ...QsnByComplexityHard,
          hardQsn: response.data,
        });
      });
    }
  }, [cardData, TestPreviewActive]); // eslint-disable-line

  useEffect(() => {
    if (QsnByComplexityEasy.easyQsn.length !== 0) {
      EditTestCardQuestionsDropDown({
        topicId: SelectedCardData,
        complexityId: 1,
      }).then((response) =>
        setQsnByComplexityEasy({
          ...QsnByComplexityEasy,
          easyQsn: response.data,
        })
      );
    }
    if (QsnByComplexityMedium.mediumQsn.length !== 0) {
      EditTestCardQuestionsDropDown({
        topicId: SelectedCardData,
        complexityId: 2,
      }).then((response) => {
        setQsnByComplexityMedium({
          ...QsnByComplexityMedium,
          mediumQsn: response.data,
        });
      });
    }

    if (QsnByComplexityHard.hardQsn.length !== 0) {
      EditTestCardQuestionsDropDown({
        topicId: SelectedCardData,
        complexityId: 3,
      }).then((response) => {
        setQsnByComplexityHard({
          ...QsnByComplexityHard,
          hardQsn: response.data,
        });
      });
    }
  }, [TestPreviewActive, refetch]); // eslint-disable-line

  // const [newName, setNewNames] = useState([]);
  // useEffect(() => {
  //   const onlyText = cardData[0]?.TopicDetails["CategorizedAs"]?.category.map(
  //     (el) => el.word
  //   );
  //   const newArray2 = names.filter(
  //     (element) => !onlyText.includes(element.text)
  //   );
  //   setNewNames(newArray2);
  // }, [names]);

  const handleSaveDescription = () => {
    ModifyTestLibraryDescription(
      {
        ...qsnDescription,
        modifiedBy: auth?.name,
      },
      names
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data Updated");
          setRefetch((prev) => prev + 1);
        }
      })
      .catch((err) => {
        toast.error("Uh-oh..404 Error");
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-based
    const year = date.getFullYear();

    // const hours = date.getHours().toString().padStart(2, "0");
    // const minutes = date.getMinutes().toString().padStart(2, "0");
    // const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
    // return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleInputChange = (property, value) => {
    setQsnDescription({
      ...qsnDescription,
      [property]: value,
    });
  };

  const handleCategoryValue = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddName = () => {
    if (inputValue.trim() !== "") {
      setNames([...names, { text: inputValue.trim() }]);
      setInputValue("");
    }
  };

  const handleRemoveName = (index) => {
    const updatedNames = [...names];
    updatedNames.splice(index, 1);
    setNames(updatedNames);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default Enter key behavior (new line)
      handleAddName();
    }
  };

  const handleEditModeTest = (option, index) => {
    setSelectedQsnData({
      id: option.id,
      index: index,
    });
    handleTestPreviewModal("editMode");
  };

  const handleAddQsn = () => {
    setAddQsnMode({
      ...AddQsnMode,
      qsnCount:
        QsnByComplexityEasy?.easyQsn?.length +
        QsnByComplexityHard?.hardQsn?.length +
        QsnByComplexityMedium?.mediumQsn?.length,
      status: true,
    });
  };

  if (cardData.length === 0) {
    return <Box>Data Loading</Box>;
  }
  return (
    <Box sx={CardViewStyle}>
      <Box>
        <Box sx={{ width: "100%" }} mt={1}>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Box sx={{ mr: 8 }}>
              <TextField
                id="outlined-basic"
                // defaultValue={cardData[0]?.TopicDetails?.TestName}
                label="Library Name"
                value={cardData[0]?.TopicDetails?.TestName}
                sx={{
                  backgroundColor: "white",
                  minWidth: 240,
                }}
                InputProps={{
                  readOnly: true,
                }}
                // onChange={(e) => handleSearchTestsLibrary(e)}
                size="small"
                variant="outlined"
              />
            </Box>
            <Box sx={{ minWidth: 240 }}>
              <FormControl fullWidth disabled>
                <InputLabel size="small" id="demo-simple-select-helper-label">
                  Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  value={0}
                  label="Category"
                  //   onChange={handleChange}

                  MenuProps={MenuProps}
                >
                  <MenuItem value={0}>
                    <em>{cardData[0]?.TopicDetails?.TestMode}</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ textAlign: "center", ml: "auto", mr: 3 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "12.5px",
                  color: "#000000DE",
                }}
              >
                Last Modified By
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 300,
                  fontSize: "14.5px",
                  color: "#908DB8",
                  // letterSpacing: 0.7,
                  mt: 1,
                  textTransform: "capitalize",
                }}
              >
                {cardData[0]?.TopicDetails["LastModified"] === null
                  ? "N/A"
                  : cardData[0]?.TopicDetails["LastModified"]}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mx: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "12.5px",
                  color: "#000000DE",
                }}
              >
                Last Modified
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 300,
                  fontSize: "14.5px",
                  color: "#908DB8",
                  // letterSpacing: 0.7,
                  mt: 1,
                }}
              >
                {cardData[0]?.TopicDetails["LastModified By"] === null
                  ? "N/A"
                  : formatDate(cardData[0]?.TopicDetails["LastModified By"])}
              </Typography>
            </Box>
          </Stack>
          <Box
            component={Stack}
            direction={"row"}
            gap={6}
            sx={{ maxWidth: "85%", mt: 4 }}
          >
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              multiline
              fullWidth
              minRows={5}
              maxRows={5}
              variant="outlined"
              value={qsnDescription.description}
              error={qsnDescription?.description === ""}
              helperText={
                qsnDescription?.description === ""
                  ? "Description can't be blank"
                  : ""
              }
              onChange={(e) => handleInputChange("description", e.target.value)}
              //   onKeyDown={handleKeyDown}
            />
            <TextField
              multiline
              fullWidth
              label="Categorized As"
              variant="outlined"
              placeholder="Enter you tags"
              minRows={5}
              maxRows={5}
              // disabled
              sx={{
                "& .MuiInputBase-root": {
                  flexDirection: "column !important",
                  fontSize: "14px !important",
                  // height: 32,
                },
                "& .MuiInputBase-input": {
                  height: "30px !important",
                  order: "-1",
                },
              }}
              value={inputValue}
              onChange={handleCategoryValue}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <Box
                    sx={{
                      maxHeight: "85px", // Adjust according to your desired max height
                      overflowX: "auto",
                      overflowY: "auto",
                      // minWidth: "10vw",
                    }}
                  >
                    {names.map((name, index) => (
                      <Chip
                        key={index}
                        label={name.text}
                        onDelete={() => handleRemoveName(index)}
                        sx={{
                          margin: "2px",
                        }}
                      />
                    ))}
                  </Box>
                ),
              }}
            />
          </Box>
          <Box component={Stack} direction={"column"}>
            <Box sx={{ ml: "auto" }}>
              <Tooltip title="Add Questions">
                <IconButton sx={{ color: "#908DB8" }} onClick={handleAddQsn}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>
            <Box component={Stack} direction={"column"} spacing={2}>
              <Box
                component={Paper}
                variant="outlined"
                sx={{
                  width: "100%",
                  display: "flex",
                  px: 2,
                  height: "80px",
                  py: 1,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    alignSelf: "flex-end",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                      mb: 0.5,
                    }}
                  >
                    Easy
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsEasy"].Count === 0
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsEasy"].Count +
                        " Questions"}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Date of Uploaded
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsEasy"].DateOfUploaded ===
                    null
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsEasy"].DateOfUploaded}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Last Modifications
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsEasy"].LastModification ===
                    null
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsEasy"].LastModification}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Actions
                  </Typography>
                  <Box>
                    <FormControl sx={{ minWidth: 240 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        value={0}
                        //   onChange={handleChange}
                        disabled={
                          cardData[0]["ComplexityDetailsEasy"].Count === 0
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem disabled value={0}>
                          <em>Select Options</em>
                        </MenuItem>
                        {QsnByComplexityEasy?.easyQsn?.map((option, index) => {
                          return (
                            <MenuItem value={option?.id} key={option?.id}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  direction: "row",
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                              >
                                <IconButton
                                  // onClick={handleEdit}
                                  aria-label="edit"
                                  size="small"
                                  disableRipple
                                  sx={{ color: "#908DB8" }}
                                >
                                  <FileCopySharp fontSize="small" />
                                </IconButton>
                                <span>Question {index + 1}</span>
                                <IconButton
                                  // onClick={handleDelete}

                                  size="small"
                                  sx={{ ml: "auto", color: "#908DB8" }}
                                  onClick={() =>
                                    handleEditModeTest(option, index + 1)
                                  }
                                >
                                  <Visibility fontSize="small" />
                                </IconButton>
                                <IconButton
                                  // onClick={handleEdit}
                                  aria-label="edit"
                                  size="small"
                                  sx={{ color: "#46A997" }}
                                  onClick={() =>
                                    handleEditModeTest(option, index + 1)
                                  }
                                >
                                  <BorderColorSharp fontSize="small" />
                                </IconButton>
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                component={Paper}
                variant="outlined"
                sx={{
                  width: "100%",
                  display: "flex",
                  px: 2,
                  height: "80px",
                  py: 1,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    alignSelf: "flex-end",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                      mb: 0.5,
                    }}
                  >
                    Medium
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsMedium"].Count === 0
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsMedium"].Count +
                        " Questions"}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Date of Uploaded
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsMedium"].DateOfUploaded ===
                    null
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsMedium"].DateOfUploaded}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Last Modifications
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsMedium"].LastModification ===
                    null
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsMedium"].LastModification}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Actions
                  </Typography>
                  <Box>
                    <FormControl sx={{ minWidth: 240 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        value={0}
                        defaultValue={0}
                        //   value={Test Mode}
                        //   onChange={handleChange}
                        disabled={
                          cardData[0]["ComplexityDetailsMedium"].Count === 0
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={0} disabled>
                          <em>Select Options</em>
                        </MenuItem>

                        {QsnByComplexityMedium?.mediumQsn?.map(
                          (option, index) => {
                            return (
                              <MenuItem value={option?.id} key={option?.id}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    direction: "row",
                                    width: "100%",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <IconButton
                                    // onClick={handleEdit}
                                    aria-label="edit"
                                    size="small"
                                    sx={{ color: "#908DB8" }}
                                  >
                                    <FileCopySharp fontSize="small" />
                                  </IconButton>
                                  <span>Question {index + 1}</span>
                                  <IconButton
                                    // onClick={handleDelete}
                                    aria-label="delete"
                                    size="small"
                                    sx={{ ml: "auto", color: "#908DB8" }}
                                    onClick={() =>
                                      handleEditModeTest(option, index + 1)
                                    }
                                  >
                                    <Visibility fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    // onClick={handleEdit}
                                    aria-label="edit"
                                    size="small"
                                    sx={{ color: "#46A997" }}
                                    onClick={() =>
                                      handleEditModeTest(option, index + 1)
                                    }
                                  >
                                    <BorderColorSharp fontSize="small" />
                                  </IconButton>
                                </Box>
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                component={Paper}
                variant="outlined"
                sx={{
                  width: "100%",
                  display: "flex",
                  px: 2,
                  height: "80px",
                  py: 1,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    alignSelf: "flex-end",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                      mb: 0.5,
                    }}
                  >
                    Hard
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsHard"].Count === 0
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsHard"].Count +
                        " Questions"}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Date of Uploaded
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsHard"].DateOfUploaded ===
                    null
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsHard"].DateOfUploaded}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Last Modifications
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    {cardData[0]["ComplexityDetailsHard"].LastModification ===
                    null
                      ? "N/A"
                      : cardData[0]["ComplexityDetailsHard"].LastModification}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#000000DE",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Actions
                  </Typography>
                  <Box>
                    <FormControl sx={{ minWidth: 240 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        value={0}
                        //   value={Test Mode}
                        //   onChange={handleChange}
                        disabled={
                          cardData[0]["ComplexityDetailsHard"].Count === 0
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={0} disabled>
                          <em>Select Options</em>
                        </MenuItem>

                        {QsnByComplexityHard?.hardQsn?.map((option, index) => {
                          return (
                            <MenuItem value={option?.id} key={option?.id}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  direction: "row",
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                              >
                                <IconButton
                                  // onClick={handleEdit}
                                  aria-label="edit"
                                  size="small"
                                  sx={{ color: "#908DB8" }}
                                >
                                  <FileCopySharp fontSize="small" />
                                </IconButton>
                                <span>Question {index + 1}</span>
                                <IconButton
                                  // onClick={handleDelete}
                                  aria-label="delete"
                                  size="small"
                                  sx={{ ml: "auto", color: "#908DB8" }}
                                  onClick={() =>
                                    handleEditModeTest(option, index + 1)
                                  }
                                >
                                  <Visibility fontSize="small" />
                                </IconButton>
                                <IconButton
                                  // onClick={handleEdit}
                                  aria-label="edit"
                                  size="small"
                                  sx={{ color: "#46A997" }}
                                  onClick={() =>
                                    handleEditModeTest(option, index + 1)
                                  }
                                >
                                  <BorderColorSharp fontSize="small" />
                                </IconButton>
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "rgba(209, 0, 0, 1)",
                border: "1px solid rgba(209, 0, 0, 1)",
                "&:hover": { border: "1px solid rgba(209, 0, 0, 1)" },
                textTransform: "capitalize",
                width: "110px",
                mr: 5,
              }}
              onClick={handleCardEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // disabled={submitResult && submitScore}
              sx={{
                backgroundColor: "#908DB8",
                "&:hover": { backgroundColor: "#908DB8" },
                textTransform: "capitalize",
                width: "110px",
              }}
              disabled={qsnDescription?.description === ""}
              onClick={handleSaveDescription}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardEditTestLibrary;
