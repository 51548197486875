import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./UserTestLogin.css";
import { useLocation, useNavigate, useParams } from "react-router-dom"; // eslint-disable-line
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogTitle from "@mui/material/DialogTitle";
import AutheticateUserForTest from "../../API/AutheticateUserForTest";
import FetchUserDetailsbyAuthentication from "../../API/FetchUserDetailsbyAuthentication";
import AssessmentLoginIssues from "../../API/AssessmentLoginIssues";
import bigLogo from "../../Assets/image-removebg.png";
import LoginCarousel from "../../Components/Login-Signup/LoginCarousel";
import { setAuthToken } from "../../API/Api";

const UserTestLogin = () => {
  const [iniParamsArr, setIniParamsArr] = useState([]); // eslint-disable-line
  const [state, setState] = useState({ name: "", email: "" });
  const [data, setData] = useState({});
  const [AuthUser, setAuthUser] = useState(false);
  const [AuthMsg, setAuthMsg] = useState("");
  const [loginUserDetail, setLoginUserDetail] = useState({});
  const [open, setOpen] = useState(false);

  // 💌START - UserAssessmentLoginTrouble State
  const [openProblemGetting, setOpenProblemGetting] = React.useState(false);
  const [greeting, setGreeting] = useState(false);
  const [loginIssues, setLoginIssues] = useState({
    email: "",
    problem: "",
  });
  const [loginIssuesError, setLoginIssuesError] = useState({
    emailError: false,
    problemError: false,
  });
  // 💌END - UserAssessmentLoginTrouble State

  const navigation = useNavigate();
  // const params = useParams();
  // console.log(params, "params");

  // useEffect(() => {
  //   setIniParamsArr(
  //     Object.values(params).map((dt) => (dt == " " ? "/" : `${dt}/`)) // eslint-disable-line
  //   );
  // }, [params]);
  // console.log(iniParamsArr, "iniParamsArr");

  // const allCodeId = iniParamsArr.join("");

  // console.log(allCodeId, "allCodeId");

  const handleLoginChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setState({ ...state, [name]: value });
  };

  const reqBody = {
    userId: 0,
    userName: state.name,
    userEmailId: state.email,
  };

  const handleLoginSubmit = () => {
    if (AuthUser) {
      toast.success("Authentication Success!", {
        position: "top-center",
        autoClose: 1000,
        width: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigation("/UserTestStartPage", {
          state: {
            // id: allCodeId.substring(0, allCodeId.length - 1),
            id: data?.AssessmentLink?.split("id=")[1],
            userDetails: reqBody,
            resDetail: data,
            loginDetail: loginUserDetail,
          },
        });
      }, 1500);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (state.email.includes("com") && state.name.length > 0) {
      AutheticateUserForTest(reqBody)
        .then((res) => {
          sessionStorage.setItem(
            "user.auth",
            JSON.stringify({
              token: res.data?.accessToken,
            })
          );
          setData(res.data);
          if (res.data?.status === "ValidUser") {
            setAuthToken(res.data?.accessToken);
            setAuthUser(true);
          } else {
            setAuthToken(false);
            setAuthUser(false);
          }
        })
        .catch((err) => {
          setAuthMsg(err?.response?.data?.status);
          setAuthUser(false);
        });
    }
  }, [state]); // eslint-disable-line

  useEffect(() => {
    if (data?.AssessmentLink) {
      FetchUserDetailsbyAuthentication(state?.email, data?.AssessmentLink)
        .then((res) => setLoginUserDetail(res.data))
        .catch((err) => console.log(err));
    }
  }, [data]); // eslint-disable-line

  const handleClose = () => {
    setOpen(false);
    setState({ ...state, name: "", email: "" });
  };

  // 💌START - UserAssessmentLoginTrouble Function
  const handleClickOpen = () => {
    setOpenProblemGetting(true);
  };

  const handleClickClose = () => {
    setOpenProblemGetting(false);
  };

  function handleSendProblem() {
    sendProblemValidation();

    if (
      loginIssuesError.emailError === true ||
      loginIssues.email.length === 0 ||
      loginIssues.problem === ""
    )
      return;

    AssessmentLoginIssues(loginIssues)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Success");
          setGreeting(true);
        }
      })
      .catch((err) => {
        toast.error(err);
      });

    setLoginIssues({ email: "", problem: "" });
    setTimeout(() => {
      setGreeting(false);
      handleClickClose();
    }, 6000);
  }

  const sendProblemValidation = () => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;

    // Validate email
    if (!emailRegex.test(loginIssues.email)) {
      setLoginIssuesError((prevErrors) => ({
        ...prevErrors,
        emailError: true,
      }));
    } else {
      setLoginIssuesError({
        ...loginIssuesError,
        emailError: false,
      });
    }

    // Validate password
    if (loginIssues.problem === "") {
      setLoginIssuesError((prevErrors) => ({
        ...prevErrors,
        problemError: true,
      }));
    } else {
      setLoginIssuesError({
        ...loginIssuesError,
        problemError: false,
      });
    }
  };
  // 💌END - UserAssessmentLoginTrouble Function

  return (
    <Box className="container-bg" sx={{ minHeight: "100vh" }}>
      <Typography className="login-box">
        <Box className="left">
          <LoginCarousel />
        </Box>
        <Box className="right">
          <Typography className="align-center">
            <img
              alt="logos"
              src={bigLogo}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                // ...(open === false && { display: "none" }),
              }}
              width={"180px"}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "15px",
                m: 2,
                mb: 4,
                fontFamily: "Manrope",
              }}
            >
              Hi there, Welcome to our Assessment Portal!
            </Typography>

            <TextField
              sx={{
                width: "75%",
                m: 1.5,
                fontSize: "12px",
                fontFamily: "Manrope",
                "& input::placeholder": {
                  fontSize: "12px",
                  fontFamily: "Manrope",
                },
              }}
              id="outlined-required"
              label="Name"
              placeholder="Please Enter Your Name"
              name="name"
              value={state.name ? state.name : ""}
              onChange={handleLoginChange}
              size="medium"
            />
            <TextField
              sx={{
                width: "75%",
                m: 1.5,
                fontSize: "12px",
                fontFamily: "Manrope",
                "& input::placeholder": {
                  fontSize: "12px",
                  fontFamily: "Manrope",
                },
              }}
              id="outlined-required"
              placeholder="Please Enter Your Email-Id"
              name="email"
              value={state.email ? state.email : ""}
              onChange={handleLoginChange}
              label="Email"
              size="medium"
            />
            <Button
              onClick={handleLoginSubmit}
              disabled={!state.name.length > 0 || !state.email.includes("com")}
              variant="contained"
              sx={{
                m: 2,
                backgroundColor: "#908db8",
                color: "white",
                height: "40px",
                width: "75%",
                ":hover": { backgroundColor: "#656a92" },
                fontSize: "14px",
                fontWeight: "600",
                fontFamily: "Manrope",
              }}
            >
              Login
            </Button>
            <Typography
              sx={{
                fontSize: "13px",
                m: 2,
                cursor: "pointer",
                textDecoration: "underline",
                color: "#656a92",
                ":hover": { color: "#4b4f6c" },
              }}
              onClick={handleClickOpen}
            >
              {/* Problem Getting into Test ? */}
              Trouble getting in?
            </Typography>
            <Dialog
              open={openProblemGetting}
              onClose={handleClickClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {!greeting
                  ? "We apologize for the inconvenience, but it seems there's an issue with your login."
                  : "Your login problem has been addressed"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {!greeting ? (
                    <>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ fontSize: "14px", mb: "10px" }}
                      >
                        Please review the following suggestions to troubleshoot
                        and resolve the problem:
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ fontSize: "14px", mb: "10px" }}
                      >
                        1. Check Your Credentials - Ensure that your username
                        and password are entered correctly. - Pay attention to
                        letter case and avoid any spaces.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ fontSize: "14px", mb: "10px" }}
                      >
                        2. Browser Compatibility - Make sure you are using a
                        supported browser (e.g., Chrome, Firefox, Safari). -
                        Clear your browser cache and cookies, then try logging
                        in again.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ fontSize: "14px", mb: "10px" }}
                      >
                        3. Stable Internet Connection - Ensure you have a stable
                        internet connection. Unstable connections may hinder
                        login attempts.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ fontSize: "14px", mb: "10px" }}
                      >
                        4. Try Incognito/Private Browsing - Open an incognito or
                        private browsing window and attempt to log in.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ fontSize: "14px", mb: "10px" }}
                      >
                        If the issue persists, please enter your email id and
                        details about any error messages, this details send to
                        our techinical support team for further assistance.
                      </DialogContentText>
                      <DialogContentText sx={{ fontSize: "14px" }}>
                        Enter Your Email Address
                      </DialogContentText>
                      <TextField
                        sx={{ mb: "20px" }}
                        autoFocus
                        margin="dense"
                        id="name"
                        placeholder="Email Id"
                        type="email"
                        fullWidth
                        value={loginIssues.email}
                        onChange={(e) =>
                          setLoginIssues({
                            ...loginIssues,
                            email: e.target.value,
                          })
                        }
                        error={loginIssuesError?.emailError}
                        helperText={
                          loginIssuesError?.emailError
                            ? "Enter Valid Email-Id"
                            : ""
                        }
                      />
                      <br />
                      <DialogContentText sx={{ fontSize: "14px", mb: "8px" }}>
                        Details about your problem
                      </DialogContentText>
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          sx={{ mb: "10px", position: "relative" }}
                          id="filled-multiline-static"
                          multiline
                          rows={4}
                          placeholder="Details about your problem"
                          fullWidth
                          onChange={(e) =>
                            setLoginIssues({
                              ...loginIssues,
                              problem: e.target.value,
                            })
                          }
                          error={loginIssuesError?.problemError}
                          helperText={
                            loginIssuesError?.problemError
                              ? "Enter Your Problem"
                              : ""
                          }
                        />
                        <Typography
                          sx={{
                            position: "absolute",
                            fontSize: "12px",
                            bottom: 15,
                            right: 10,
                          }}
                        >
                          {loginIssues.problem.length}
                        </Typography>
                      </Box>
                      <br />
                    </>
                  ) : (
                    <DialogContentText
                      id="alert-dialog-description"
                      sx={{ fontSize: "14px" }}
                    >
                      Thank you for your understanding, and we appreciate your
                      patience as we work to resolve this matter promptly. [MCQ
                      - Talent Assessment]
                    </DialogContentText>
                  )}
                </DialogContentText>
              </DialogContent>
              {!greeting && (
                <DialogActions>
                  <Button onClick={handleClickClose}>Close</Button>
                  <Button onClick={handleSendProblem}>Send</Button>
                </DialogActions>
              )}
            </Dialog>
          </Typography>
        </Box>
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red" }}
          alignSelf={"center"}
        >
          {AuthMsg}
        </DialogTitle>
        <DialogActions sx={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#908db8", color: "#fff" }}
            onClick={handleClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Box>
  );
};

export default UserTestLogin;
