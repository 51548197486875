import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complexity: [],
  skill: [],
  questionType: [],
  selectedType: 0,
  selectedMode: 0,
  selectLevel: 0,
  saveAssessment: false,
  directSaveLink: false,
  assessmentData: {},
};
const firstStepSlice = createSlice({
  name: "firstStepForm",
  initialState,
  reducers: {
    complexity: (state, action) => {
      state.complexity = action.payload;
    },
    skill: (state, action) => {
      state.skill = action.payload;
    },
    questionType: (state, action) => {
      state.questionType = action.payload;
    },
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },
    setSelectedMode: (state, action) => {
      state.selectedMode = action.payload;
    },
    setSelectedLevel: (state, action) => {
      state.selectLevel = action.payload;
    },
    setSaveAssessment: (state, action) => {
      state.saveAssessment = action.payload;
    },
    setUnSaveAssessment: (state, action) => {
      state.saveAssessment = action.payload;
    },
    setDirectAssessment: (state, action) => {
      state.directSaveLink = action.payload;
    },
    setAssessmentData: (state, action) => {
      state.assessmentData = action.payload;
    },
  },
});

export default firstStepSlice.reducer;
export const {
  complexity,
  skill,
  questionType,
  setSelectedType,
  setSelectedMode,
  setSelectedLevel,
  setSaveAssessment,
  setUnSaveAssessment,
  setDirectAssessment,
  setAssessmentData,
} = firstStepSlice.actions;
