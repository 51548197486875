import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const UserLeftButtons = (props) => {
  const {
    currQuestion, // eslint-disable-line
    questionData,
    currQuestionId,
    questionStatus,
    handleDirectSwitch,
  } = props;
  return (
    <Box
      sx={{ minHeight: "90vh", overflowY: "auto" }}
      paddingY={2}
      paddingX="36px"
    >
      {questionData?.map((dt, optionIndex) => {
        return (
          <Stack key={optionIndex} direction="column" alignItems={"center"}>
            <Box key={optionIndex} sx={{ p: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14.2px",
                  color: "black",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  fontFamily: "Manrope",
                }}
              >
                {dt.topic}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "auto auto",
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Grid
                container
                sx={{
                  boxSizing: "content-box",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {dt?.questions?.map((val, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LightTooltip
                        title={
                          currQuestionId === val.id
                            ? "Current Question"
                            : questionStatus?.[optionIndex]?.[index] ===
                              "selected"
                            ? "Answered Question"
                            : questionStatus?.[optionIndex]?.[index] ===
                              "pending"
                            ? "Pending Question"
                            : "Skipped Question"
                        }
                        // placement="top"
                        arrow
                      >
                        <button
                          onClick={() =>
                            handleDirectSwitch(index, optionIndex, val.id)
                          }
                          style={{
                            width: "40px",
                            border: "none",
                            cursor: "pointer",
                            backgroundColor:
                              currQuestionId === val.id
                                ? "#908db8"
                                : questionStatus?.[optionIndex]?.[index] ===
                                  "selected"
                                ? "#43ad41"
                                : questionStatus?.[optionIndex]?.[index] ===
                                  "pending"
                                ? "#d0cfdf"
                                : "#ffd91c",
                            borderRadius: "5px",
                            paddingTop: "2px",
                            margin: "5px",
                            paddingBottom: "2px",
                            fontFamily: "Manrope",
                          }}
                        >
                          {index + 1}
                        </button>
                      </LightTooltip>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
        );
      })}
    </Box>
  );
};

export default UserLeftButtons;
