import {
  Box,
  FormControl,
  Stack,
  MenuItem,
  Select,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  ThemeProvider,
  createTheme,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TablePagination,
  TableBody,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedType,
  setSelectedMode,
  setSelectedLevel,
  setUnSaveAssessment,
  setSaveAssessment,
  setDirectAssessment,
  setAssessmentData,
} from "../Redux/FirstStep";
import { useLocation } from "react-router";
import { removeProduct } from "../Redux/SecondStep";
import GetSavedAssessmentTable from "../API/GetSavedAssessmentTable";
import { red, blue, green, yellow, purple, orange } from "@mui/material/colors";
import { updateActiveStep } from "../Redux/Stepper";
// import { makeStyles } from "@material-ui/core";

const columns = [
  {
    field: "NameofAssessment",
    headerName: "Name",
  },
  {
    field: "testDate",
    headerName: "Date",
  },

  {
    field: "Time",
    headerName: "Time",
  },
];
// const useStyles = makeStyles({
//   tableRow: {
//     height: "10px",
//     padding: "0px",
//   },
//   tableCell: {
//     padding: "0px",
//     display: "none",
//   },
// });
const FirstStepForm = ({
  setRegisterUserId,
  fetchQuestionType,
  setIsRegister,
  nameOfTheTest,
  setNameOfTheTest,
  testTypeId,
  setTestTypeId,
  setNavStateVal,
}) => {
  const theme = createTheme({
    palette: {
      figma: {
        main: "#908DB8",
      },
    },
  });
  const location = useLocation();
  const withReg = location?.state?.skipReg;
  const navStateVal = location?.state?.navStateVal;
  const regUserId = location?.state?.regUserId;
  const classes = {
    tableRow: {
      height: "5px",
      padding: "0px",
    },
    tableCell: {
      padding: "5px",
      fontFamily: "manrope",
      fontWeight: 600,
    },
  };

  useEffect(() => {
    dispatch(removeProduct([]));
  }, []); // eslint-disable-line

  useEffect(() => {
    // setIsRegister(withReg);
    if (navStateVal?.skipReg) {
      setIsRegister(navStateVal?.skipReg);
    }
    if (navStateVal) {
      setNavStateVal(navStateVal);
    }
    if (regUserId) {
      setRegisterUserId(regUserId);
    }
  }, [withReg, navStateVal, regUserId]); // eslint-disable-line

  const dispatch = useDispatch();
  const complexity = useSelector((state) => state.firstStepForm.complexity);
  const skill = useSelector((state) => state.firstStepForm.skill);
  const questionType = useSelector((state) => state.firstStepForm.questionType);
  const selectedType = useSelector((state) => state.firstStepForm.selectedType);
  const selectedMode = useSelector((state) => state.firstStepForm.selectedMode);
  const selectLevel = useSelector((state) => state.firstStepForm.selectLevel);
  const saveAssessment = useSelector(
    (state) => state.firstStepForm.saveAssessment
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableData, setTableData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);

  const handleDirectAssessment = (row) => {
    dispatch(setDirectAssessment(true));
    dispatch(setAssessmentData(row));
    dispatch(updateActiveStep(4));
  };

  const findTestComlexityName = (id) => {
    if (id === 1) {
      return "Easy";
    } else if (id === 2) {
      return "Medium";
    } else if (id === 3) {
      return "Hard";
    } else if (id === 4) {
      return "Custom";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-based
    const year = date.getFullYear();

    // const hours = date.getHours().toString().padStart(2, "0");
    // const minutes = date.getMinutes().toString().padStart(2, "0");
    // const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
    // return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    GetSavedAssessmentTable()
      .then((res) => setMainTableData(res.data))
      .catch((err) => err);
  }, []);

  useEffect(() => {
    setTableData(mainTableData);
  }, [mainTableData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchName = (e) => {
    const value = e.target.value;
    if (value && value?.length >= 1) {
      const _searchData = mainTableData.filter(
        (item) =>
          item.assessmentName &&
          item.assessmentName
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
      );

      setTableData(_searchData);
    } else {
      setTableData(mainTableData);
    }
  };

  // Array of Material-UI color palettes
  const colorPalettes = [red, blue, green, yellow, purple, orange];

  // Function to generate a random color from the color palettes
  const getRandomColor = () => {
    const randomPalette =
      colorPalettes[Math.floor(Math.random() * colorPalettes.length)];
    const shades = Object.keys(randomPalette).filter(
      (key) => typeof randomPalette[key] === "string" && key.includes("700")
    );
    const randomShade = shades[Math.floor(Math.random() * shades.length)];
    return randomPalette[randomShade];
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleNameOfTheAsses = (e) => {
    setNameOfTheTest(e.target.value);
  };

  const handleTestTypeChange = (e) => {
    dispatch(setSelectedType(e.target.value));
    setTestTypeId(e.target.value);
  };

  const handleSaveAssessment = () => {
    if (saveAssessment) {
      dispatch(setUnSaveAssessment(false));
    } else {
      dispatch(setSaveAssessment(true));
    }
  };

  return (
    <Box sx={{ width: "70%", fontFamily: "manrope" }}>
      <Stack
        spacing={3}
        direction={"column"}
        alignItems={"center"}
        fontFamily={"inherit"}
      >
        <Stack direction={"row"} spacing={4}>
          <Box fontFamily={"inherit"}>
            <Typography
              variant="body2"
              sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              fontFamily={"inherit"}
              fontWeight={600}
            >
              Name of the Assessment<sup style={{ color: "red" }}>*</sup>
            </Typography>
            <TextField
              id="outlined-basic"
              // label="Name of the Assessment"
              onChange={handleNameOfTheAsses}
              placeholder="Enter Name"
              value={nameOfTheTest ? nameOfTheTest : ""}
              sx={{
                backgroundColor: "white",
              }}
              size="small"
              variant="outlined"
              fontFamily={"inherit"}
              fontWeight={600}
            />
          </Box>
          <Box sx={{ minWidth: 180 }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              fontFamily={"inherit"}
              fontWeight={600}
            >
              Complexity<sup style={{ color: "red" }}>*</sup>
            </Typography>
            <FormControl fontFamily={"inherit"} fontWeight={600} fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedType}
                size="small"
                sx={{
                  backgroundColor: "white",
                }}
                placeholder="Select Type"
                onChange={handleTestTypeChange}
              >
                <MenuItem disabled value={0}>
                  Select Complexity
                </MenuItem>
                {complexity?.map((val) => {
                  return (
                    <MenuItem value={val.id}>{val.complexityType}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 180 }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              fontFamily={"inherit"}
              fontWeight={600}
            >
              Category<sup style={{ color: "red" }}>*</sup>
            </Typography>
            <FormControl fullWidth fontFamily={"inherit"} fontWeight={600}>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedMode}
                size="small"
                sx={{
                  backgroundColor: "white",
                }}
                placeholder="Select Type"
                onChange={(e) => {
                  dispatch(setSelectedMode(e.target.value));
                  fetchQuestionType(e.target.value);
                }}
              >
                <MenuItem disabled value={0}>
                  Select Category
                </MenuItem>
                {skill.map((val) => {
                  return <MenuItem value={val.id}>{val.skillName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </Stack>

        <Stack
          direction={"row"}
          spacing={4}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Box>
            <Typography
              paddingY={3}
              paddingX={2}
              variant="body2"
              sx={{
                bgcolor: "#FDF2D6",
                textAlign: "center",

                fontSize: "11px",
                // textTransform: "capitalize",
                color: "#99834B",
              }}
              fontFamily={"inherit"}
              fontWeight={600}
            >
              {" "}
              Do you want to save this assessment for future test?
            </Typography>
          </Box>

          <Box>
            <ThemeProvider theme={theme}>
              <FormControl fontFamily={"inherit"} fontWeight={600}>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={saveAssessment ? "Yes" : "No"}
                  sx={{ p: 0, m: 0 }}
                  onChange={handleSaveAssessment}
                  fontFamily={"inherit"}
                  fontWeight={600}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="figma" />}
                    label="Yes"
                    labelPlacement="top"
                    sx={{ p: 0, m: 0 }}
                    fontFamily={"inherit"}
                    fontWeight={600}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="figma" />}
                    label="No"
                    sx={{ p: 0, m: 0 }}
                    labelPlacement="top"
                    fontFamily={"inherit"}
                    fontWeight={600}
                  />
                </RadioGroup>
              </FormControl>
            </ThemeProvider>
          </Box>

          <Box sx={{ minWidth: 180 }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "15px", paddingY: 1, color: "#212121" }}
              fontFamily={"inherit"}
              fontWeight={600}
            >
              Type<sup style={{ color: "red" }}>*</sup>
            </Typography>

            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectLevel}
                sx={{
                  backgroundColor: "white",
                }}
                disabled={questionType.length === 0}
                size="small"
                // onClose={(e)=> getSecondStepData(e.target.value)}
                onChange={(e) => dispatch(setSelectedLevel(e.target.value))}
              >
                <MenuItem disabled value={0}>
                  Select Type
                </MenuItem>
                {questionType.map((val) => {
                  return (
                    <MenuItem
                      key={val.id}
                      value={val.id}
                      disabled={
                        val.questionType === "Code" ||
                        val.questionType === "Advance(MCQ&Code)" ||
                        val.questionType === "Contextual" ||
                        val.questionType === "Advance(MCQ&Contextual)"
                      }
                    >
                      {val.questionType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Stack>
      <Divider sx={{ paddingY: 1.5 }} />
      <Stack
        spacing={3}
        direction={"column"}
        alignItems={"center"}
        width="100%"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
          gap={6}
          width="100%"
        >
          <Box fontFamily={"inherit"}>
            <Typography
              variant="body2"
              sx={{ fontSize: "15px", paddingY: 2, color: "#212121" }}
              fontFamily={"inherit"}
              fontWeight={600}
            >
              Saved Assessment
            </Typography>
          </Box>
          <Box
            sx={{
              alignSelf: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              placeholder="Search Assessment"
              size="small"
              sx={{
                backgroundColor: "white",
              }}
              // value={searchValue}
              onChange={handleSearchName}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box sx={{ width: "70%", height: "100%" }}>
          <TableContainer
            sx={{
              maxHeight: 400,
              bgcolor: "white",
              px: 3,
              py: 2,
              borderRadius: "15px",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ textAlign: "center" }}>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell
                        sx={{ fontFamily: "manrope", fontWeight: 700 }}
                        key={column.id}
                      >
                        {column.headerName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.length !== 0 &&
                  tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          sx={{ fontFamily: "manrope", cursor: "pointer" }}
                          key={row.code}
                          onClick={() => handleDirectAssessment(row)}
                        >
                          <TableCell sx={classes.tableCell}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: getRandomColor,
                                  mr: 2,
                                  fontFamily: "manrope",
                                }}
                                alt={
                                  row?.nameOfTheAssessment?.toUpperCase() || "U"
                                }
                                src="/broken-image.jpg"
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    fontWeight: 700,
                                    fontFamily: "manrope",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {row?.assessmentName === ""
                                    ? "Unnamed Assessment"
                                    : row?.assessmentName}
                                </Box>
                                <Box
                                  sx={{ fontSize: "12px", color: "#8d8d8d" }}
                                >
                                  #{findTestComlexityName(row?.complexityId)}
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell sx={classes.tableCell}>
                            {formatDate(row?.date)}
                          </TableCell>

                          <TableCell sx={classes.tableCell}>
                            {row?.assessmentTime > 1
                              ? `${row?.assessmentTime}Mins`
                              : `${row?.assessmentTime}Min`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            {tableData?.length === 0 && (
              <Typography
                sx={{
                  fontFamily: "manrope",
                  marginX: "auto",
                  width: "100%",
                  textAlign: "center",
                  my: 3,
                  fontWeight: 700,
                }}
              >
                Data not available
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default FirstStepForm;
