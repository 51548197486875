import useMediaQuery from "@mui/material/useMediaQuery";
import "../Login-Signup/ImageSignupAndForgetPasswordPage.css";

function ImageSignupAndForgetPasswordPage() {
  function SignupImage() {
    const matches = useMediaQuery("(max-width:1330px)");
    const matchesLargeScreen = useMediaQuery("(max-width:1536px)");
    return (
      <div className="signup">
        <img
          className="signup-img"
          src={require("../../Assets/SignupSuccessfullyRegister.svg").default}
          alt="SignupImage"
          style={{
            width: matches ? "100%" : matchesLargeScreen ? "80%" : "",
          }}
        />
        <h2
          className="signup-header"
          style={{
            fontSize: matches ? "24px" : matchesLargeScreen ? "28px" : "",
          }}
        >
          Successfully Register
        </h2>
        <p
          className="signup-para"
          style={{
            fontSize: matches ? "12px" : matchesLargeScreen ? "14px" : "",
          }}
        >
          We hope you will gain knowledge and skills through our comprehensive
          assessment modules.
        </p>
      </div>
    );
  }

  function ForgetPasswordImage({ currentStep }) {
    const matches = useMediaQuery("(max-width:1330px)");
    const matchesLargeScreen = useMediaQuery("(max-width:1536px)");

    const forgetPasswordSteps = [
      {
        id: 1,
        title: "Forget Your Password ?",
        description:
          "Recover your account quickly and securely with our process.",
        icon: require("../../Assets/Setup1_ForgotPasswordEnterYourMailId.svg"),
        status: true,
      },
      {
        id: 2,
        title: "Check Your OTP",
        description:
          "And enter it here to securely access your account and get back to what matters.",
        icon: require("../../Assets/Setup2_EnterOTP.svg"),
        status: false,
      },
      {
        id: 3,
        title: "OTP Verified",
        description:
          "Regain access to your account with a few secure password reset steps.",
        icon: require("../../Assets/Setup3_ResetPasswordOTPVerified.svg"),
        status: false,
      },
      {
        id: 4,
        title: "New Password Setup",
        description:
          "Enter and Confirm Your Password to Securely Regain Access.",
        icon: require("../../Assets/Setup4_NewPasswordSetup.svg"),
        status: false,
      },
      {
        id: 5,
        title: "Password Reseted",
        description:
          "Please log in with your new password to access your account.",
        icon: require("../../Assets/Setup5_PasswordResetedDone.svg"),
        status: false,
      },
    ];

    const currentSteps = forgetPasswordSteps[currentStep];

    return (
      <div className="signup">
        {currentSteps && (
          <>
            <img
              className="carousel-img"
              src={currentSteps.icon.default}
              alt={currentSteps.title}
              style={{
                width: matches ? "100%" : matchesLargeScreen ? "80%" : "",
              }}
            />
            <h2
              className="signup-header"
              style={{
                fontSize: matches ? "24px" : matchesLargeScreen ? "28px" : "",
              }}
            >
              {currentSteps.title}
            </h2>
            <p
              className="signup-para"
              style={{
                fontSize: matches ? "12px" : matchesLargeScreen ? "14px" : "",
              }}
            >
              {currentSteps.description}
            </p>
          </>
        )}
      </div>
    );
  }
  return { SignupImage, ForgetPasswordImage };
}

export default ImageSignupAndForgetPasswordPage;
