import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 0px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;

  position: relative;
  :before {
    content: "";
    position: absolute;
    background: white;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #908db8;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ colorbg }) => colorbg};

  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ stepCountColor }) => stepCountColor};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// const StepLabel = styled.span`
//   font-size: 19px;
//   color: "#4a154b";
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const ButtonsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin: 0 -15px;
// `;

// const ButtonStyle = styled.button`
//   border-radius: 4px;
//   border: 0;
//   background: #4a154b;
//   color: #ffffff;
//   cursor: pointer;
//   padding: 8px;
//   width: 90px;
//   :active {
//     transform: scale(0.98);
//   }
//   :disabled {
//     background: #f3e7f3;
//     color: #000000;
//     cursor: not-allowed;
//   }
// `;

const CheckMark = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: white;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

// const steps = [
//     {
//       label: 'Address',
//       step: 1,
//     },
//     {
//       label: 'Shipping',
//       step: 2,
//     },
//     {
//       label: 'Payment',
//       step: 3,
//     },
//     {
//       label: 'Summary',
//       step: 4,
//     },

//   ]

const StepperProgress = ({ setActiveStep, nextStep, prevStep }) => {
  const steps = useSelector((state) => state.stepper.stepCount);
  const activeStep = useSelector((state) => state.stepper.activeStep);
  const totalSteps = steps?.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps?.map(({ step, label }) => (
          <StepWrapper key={step}>
            <StepStyle
              colorbg={activeStep >= step ? "#908DB8" : "white"}
              step={activeStep >= step ? "completed" : "incomplete"}
            >
              {activeStep > step ? (
                <CheckMark>L</CheckMark>
              ) : (
                <StepCount
                  stepCountColor={activeStep >= step ? "white" : "black"}
                >
                  {step}
                </StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              {/* <StepLabel key={step}>{label}</StepLabel> */}
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
      {/* <ButtonsContainer>
        <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
          Previous
        </ButtonStyle>
        <ButtonStyle onClick={nextStep} disabled={activeStep === totalSteps}>
          Next
        </ButtonStyle>
      </ButtonsContainer> */}
    </MainContainer>
  );
};

export default StepperProgress;
