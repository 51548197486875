import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { Avatar, Box, Chip, Grid, Typography } from "@mui/material";

import CircularProgress from "@mui/joy/CircularProgress";
// import { CircularProgress } from "@mui/joy";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";

import CandiDateScoreTable from "./CandiDateScoreTable";

import CandidateResultPage from "../API/CandidateResultPage";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

const UserAssesmentReport = () => {
  const initial = { candReportData: {}, id: null };
  const [state, setState] = useState(initial);
  const [scoreData, setScoreData] = useState([]);
  const [fetchDataIsLoading, setfetchDataIsLoading] = useState(false);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get("id");

  const encryptedId = id.replace(/ /g, "+");

  // const encryptedId = id.replace(/\+/g, "%2B");

  useEffect(() => {
    setfetchDataIsLoading(true);
    CandidateResultPage(encryptedId)
      .then((res) => {
        setState({
          ...state,
          id: res.data[0].result.userId,
          candReportData: res.data[0].result,
        });
        setScoreData(res.data[0].Topic);
        setfetchDataIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [encryptedId]); // eslint-disable-line

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    // const hours = date.getHours().toString().padStart(2, "0");
    // const minutes = date.getMinutes().toString().padStart(2, "0");
    // const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  function convertMinutesToTime(timeInMinutes) {
    const hours = Math.floor(timeInMinutes / 60);
    const remainingMinutes = Math.floor(timeInMinutes % 60);
    const seconds = Math.round((timeInMinutes % 1) * 60);

    return {
      hours,
      minutes: remainingMinutes,
      seconds,
    };
  }

  const convertedTime = convertMinutesToTime(state.candReportData.timeSpent);

  // PIE CHART START
  const data = [
    {
      value: state.candReportData.correctAnswers,
      label: "Correct Answer",
      color: "#1CC88A",
    },
    {
      value: state.candReportData.wrongAnswers,
      label: "Incorrect Answer",
      color: "#e74a3b",
    },
    {
      value: state.candReportData.skippedAnswers,
      label: "Skipped Answer",
      color: "#FFD91C",
    },
  ];

  const size = {
    width: 300,
    height: 200,
  };

  const StyledText = styled("text")(({ theme }) => ({
    fill: "#858796",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontFamily: "Manrope",
    fontSize: 40,
    fontWeight: 800,
  }));

  function PieCenterScore({ children }) {
    const { width, height, left, top } = useDrawingArea(); // eslint-disable-line
    return (
      <StyledText x={46 + width / 2} y={top + height / 2.3}>
        {children}
      </StyledText>
    );
  }

  const StyledTextLabel = styled("text")(({ theme }) => ({
    fill: "#858796",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontFamily: "Manrope",
    fontSize: 20,
    fontWeight: 800,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea(); // eslint-disable-line
    return (
      <StyledTextLabel x={45 + width / 2} y={top + height / 1.7}>
        {children}
      </StyledTextLabel>
    );
  }

  // PIE CHART END

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        fontFamily: "manrope",
      }}
    >
      {fetchDataIsLoading ? (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#eee",
          }}
        >
          <CircularProgress size="lg" color="neutral" />
        </Box>
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={1.7}
            xl={1.7}
            sx={{
              height: "fit-content",
              backgroundColor: "#fffff",
              pb: 1,
            }}
          >
            {/* LEFT SIDE */}
            <Box>
              <CandiDateScoreTable scoreData={scoreData} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            lg={10.3}
            xl={10.3}
            sx={{ height: "100vh", backgroundColor: "#ebecf1" }}
          >
            {/* RIGHT SIDE */}
            <Box
              sx={{
                backgroundColor: "#ebecf1",
                padding: "5px 24px 8px 23px",
              }}
            >
              {/* HEADER PART BUTTON / BAR */}
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                sx={{
                  backgroundColor: "#ebecf1",
                }}
              >
                {/* Back Button / Title  */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundColor: "#ebecf1",
                      boxShadow: "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 800,
                        width: "80%",
                        alignSelf: "center",
                        color: "#212121",
                        fontFamily: "Manrope",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        ml: 1,
                      }}
                    >
                      Assessment Report
                    </Typography>
                  </Item>
                </Grid>

                {/* ROW ONE */}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Item
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      borderRadius: "15px",
                    }}
                    elevation={3}
                  >
                    <Box sx={{ px: 3, py: 4, pt: 3, pb: 4.8 }}>
                      <Typography
                        sx={{
                          color: "#212121",
                          fontSize: "16px",
                          fontWeight: 800,
                          fontFamily: "Manrope",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          textAlign: "left",
                          mb: 3,
                        }}
                      >
                        Your Details
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          mb: 2.5,
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Avatar
                            sx={{
                              bgcolor: "grey",
                              height: "44px",
                              width: "44px",
                              borderRadius: "12px",
                            }}
                            alt={state.candReportData.userName}
                            src="/broken-image.jpg"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: 600,
                              textTransform: "capitalize",
                              color: "#000",
                              fontFamily: "Manrope",
                              fontSize: "13px",
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            {state.candReportData.userName}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#8d8d8d",
                              fontFamily: "Manrope",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            #{state.candReportData.userId}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: { md: "1fr" },
                          gap: 1.5,
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "black",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                              width: "max-content",
                            }}
                          >
                            Email Id :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#6f6f6f",
                              textAlign: "right",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              ml: 6,
                            }}
                          >
                            {state.candReportData.emailId}
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "black",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            Qualification :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#6f6f6f",
                              textAlign: "right",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              ml: 3,
                            }}
                          >
                            {state.candReportData.qualification || "N/A"}
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "black",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            Specialization :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#6f6f6f",
                              textAlign: "right",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              ml: 2,
                            }}
                          >
                            {state.candReportData.specialization || "N/A"}
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "black",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            Mobile Number :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#6f6f6f",
                              textAlign: "right",
                              fontFamily: "'Inter', sans-serif",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              ml: 1,
                            }}
                          >
                            {state.candReportData.mobileNumber || "N/A"}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Item>
                </Grid>

                {/* ROW ONE */}
                {/* BOX TWO */}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Item
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: "15px",
                      position: "relative",
                    }}
                    elevation={3}
                  >
                    <Box sx={{ px: 1, py: 1 }}>
                      <Typography
                        sx={{
                          height: "5%",
                          px: 3,
                          py: 2,
                          color: "#908db8",
                          fontSize: "16px",
                          fontWeight: 800,
                          fontFamily: "Manrope",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          textAlign: "left",
                        }}
                      >
                        <span
                          style={{
                            color: "#212121",
                            fontSize: "16px",
                            fontWeight: 800,
                            fontFamily: "Manrope",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            textAlign: "left",
                            mb: 3,
                          }}
                        >
                          {" "}
                          Total Questions{" "}
                        </span>
                        : {state.candReportData.totalQuestions}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <PieChart
                          series={[{ data, innerRadius: 75, cx: 140, cy: 90 }]}
                          {...size}
                          slotProps={{
                            legend: { hidden: true },
                          }}
                        >
                          <PieCenterScore>
                            {state.candReportData.totalQuestions < 10
                              ? ` 0${state.candReportData.correctAnswers}/0${state.candReportData.totalQuestions}`
                              : `${state.candReportData.correctAnswers}/${state.candReportData.totalQuestions}`}
                          </PieCenterScore>
                          <PieCenterLabel>Total Score</PieCenterLabel>
                        </PieChart>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "75%",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "90%",
                              height: "30px",
                              display: "flex",
                              overflow: "hidden",
                              borderRadius: "7px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                // width: "17%",
                                color: "#fff",
                                fontSize: "14px",
                                borderRadius: "7px 0px 0px 7px",
                                backgroundColor: "#389674",
                                px: 1.5,
                                py: 1,
                              }}
                            >
                              {state.candReportData.correctAnswers}
                            </Typography>
                            <Typography
                              sx={{
                                width: "65%",
                                color: "#fff",
                                fontSize: "10px",
                                backgroundColor: "#1cc88a",
                                borderRadius: "0px 7px 7px 0px",
                                p: 1,
                              }}
                            >
                              Correct Answer
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "90%",
                              height: "30px",
                              display: "flex",
                              overflow: "hidden",
                              borderRadius: "7px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                // width: "17%",
                                color: "#fff",
                                fontSize: "14px",
                                borderRadius: "7px 0px 0px 7px",
                                backgroundColor: "#c53f32",
                                px: 1.5,
                                py: 1,
                              }}
                            >
                              {state.candReportData.wrongAnswers}
                            </Typography>
                            <Typography
                              sx={{
                                width: "65%",
                                color: "#fff",
                                fontSize: "10px",
                                backgroundColor: "#e74a3b",
                                borderRadius: "0px 7px 7px 0px",
                                p: 1,
                              }}
                            >
                              Incorrect Answer
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "90%",
                              height: "30px",
                              display: "flex",
                              overflow: "hidden",
                              borderRadius: "7px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                // width: "17%",
                                color: "#fff",
                                fontSize: "14px",
                                borderRadius: "7px 0px 0px 7px",
                                backgroundColor: "#968638",
                                px: 1.5,
                                py: 1,
                              }}
                            >
                              {state.candReportData.skippedAnswers}
                            </Typography>
                            <Typography
                              sx={{
                                width: "65%",
                                color: "#fff",
                                fontSize: "10px",
                                backgroundColor: "#ffd91c",
                                borderRadius: "0px 7px 7px 0px",
                                p: 1,
                              }}
                            >
                              Skipped Answer
                            </Typography>
                          </Box>
                        </Box>
                      </Typography>
                    </Box>
                  </Item>
                </Grid>

                {/* ROW ONE */}
                {/* BOX THREE */}
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Item
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      borderRadius: "15px",
                    }}
                    elevation={3}
                  >
                    <Box sx={{ px: 3, py: 3 }}>
                      <Typography
                        sx={{
                          color: "#212121",
                          fontSize: "16px",
                          fontWeight: 800,
                          fontFamily: "Manrope",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          textAlign: "left",
                          mb: 8,
                        }}
                      >
                        {/* Total Time Taken by This Candidate */}
                        Total Time Taken
                      </Typography>
                      <Typography
                        sx={{
                          width: "100%",
                          height: "90%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          mb: 5,
                        }}
                      >
                        <CircularProgress
                          sx={{
                            color: "red",
                            backgroundColor: "white",
                            "--CircularProgress-size": "95px",
                            "--CircularProgress-trackThickness": "14px",
                            "--CircularProgress-progressThickness": "14px",
                          }}
                          variant="soft"
                          size="lg"
                          color="primary"
                          determinate
                          value={(convertedTime?.hours / 60) * 100}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#858796",
                                fontSize: "25px",
                                height: "28px",
                                lineHeight: "normal",
                              }}
                            >
                              {convertedTime?.hours}
                            </div>
                            <div style={{ color: "#858796", fontSize: "12px" }}>
                              Hours
                            </div>
                          </div>
                        </CircularProgress>
                        <CircularProgress
                          sx={{
                            color: "red",
                            backgroundColor: "white",
                            "--CircularProgress-size": "95px",
                            "--CircularProgress-trackThickness": "14px",
                            "--CircularProgress-progressThickness": "14px",
                          }}
                          variant="soft"
                          size="lg"
                          color="success"
                          determinate
                          value={(convertedTime?.minutes / 60) * 100}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#858796",
                                fontSize: "25px",
                                height: "28px",
                                lineHeight: "normal",
                              }}
                            >
                              {convertedTime?.minutes}
                            </div>
                            <div style={{ color: "#858796", fontSize: "12px" }}>
                              Minutes
                            </div>
                          </div>
                        </CircularProgress>
                        <CircularProgress
                          sx={{
                            color: "red",
                            backgroundColor: "white",
                            "--CircularProgress-size": "95px",
                            "--CircularProgress-trackThickness": "14px",
                            "--CircularProgress-progressThickness": "14px",
                          }}
                          variant="soft"
                          size="lg"
                          color="danger"
                          determinate
                          value={(convertedTime?.seconds / 60) * 100}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#858796",
                                fontSize: "25px",
                                height: "28px",
                                lineHeight: "normal",
                              }}
                            >
                              {convertedTime?.seconds}
                            </div>
                            <div style={{ color: "#858796", fontSize: "12px" }}>
                              Seconds
                            </div>
                          </div>
                        </CircularProgress>
                      </Typography>
                    </Box>
                  </Item>
                </Grid>

                {/* ROW TWO */}
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Item
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      borderRadius: "15px",
                      // mb: "20px",
                    }}
                    elevation={3}
                  >
                    <Box sx={{ px: 3, py: 4, pb: "24px" }}>
                      <Typography
                        sx={{
                          color: "#212121",
                          fontSize: "16px",
                          fontWeight: 800,
                          fontFamily: "Manrope",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          textAlign: "left",
                          mb: 3,
                        }}
                      >
                        Assessment Details
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 1,
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            gap: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              gap: 3,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "'Inter',sans-serif",
                                fontWeight: 600,
                                color: "black",
                                textTransform: "capitalize",
                                fontSize: "14px",
                              }}
                            >
                              Name :
                            </Typography>
                            <Typography
                              sx={{
                                p: 0,
                                textTransform: "capitalize",
                                fontSize: "14px",
                              }}
                            >
                              {state.candReportData.nameOfTheTest}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              gap: 4,
                              fontSize: "14px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bolder",
                                color: "black",
                                p: 0,
                                fontSize: "14px",
                              }}
                            >
                              Type :
                            </Typography>
                            <Typography
                              sx={{
                                p: 0,
                                textTransform: "capitalize",
                                fontSize: "14px",
                              }}
                            >
                              {state.candReportData.complexityName}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              gap: 2.4,
                              fontSize: "14px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bolder",
                                color: "black",
                                p: 0,
                                fontSize: "14px",
                              }}
                            >
                              Libraries :
                            </Typography>
                            <Typography sx={{ p: 0, fontSize: "14px" }}>
                              {state.candReportData.numberOfTests}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              gap: 3,
                              fontSize: "14px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bolder",
                                color: "black",
                                p: 0,
                                fontSize: "14px",
                              }}
                            >
                              Total Time :
                            </Typography>
                            <Typography sx={{ p: 0, fontSize: "14px" }}>
                              {state.candReportData.totalTime} Min
                            </Typography>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              gap: 4,
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bolder",
                                color: "black",
                                p: 0,
                                fontSize: "14px",
                              }}
                            >
                              Date Of Creation :
                            </Typography>
                            <Typography sx={{ p: 0, fontSize: "14px" }}>
                              {formatDate(state.candReportData.dateOfCreation)}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              gap: 11,
                              fontSize: "14px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bolder",
                                color: "black",
                                p: 0,
                                alignSelf: "center",
                                fontSize: "14px",
                              }}
                            >
                              Status :
                            </Typography>
                            <Typography
                              sx={{
                                p: 0,
                                textTransform: "capitalize",
                                fontSize: "14px",
                              }}
                            >
                              {/* {formatDate(state.candReportData.dateOfCreation)} */}
                              <Chip
                                color={
                                  state.candReportData.status === "Rejected"
                                    ? "error"
                                    : state.candReportData.status === "Selected"
                                    ? "success"
                                    : "warning"
                                }
                                sx={{ width: "100px", fontSize: "14px" }}
                                label={
                                  state.candReportData.status === null
                                    ? "Pending"
                                    : state.candReportData.status
                                }
                              />
                              {/* {state.candReportData.status} */}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default UserAssesmentReport;
